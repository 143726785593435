// .designer-gallery {
//     img {
//         max-width: 100%;
//     }

//     .container {
//         padding: 0 16px;
//         .row {
//             margin: 0 -16px;
//             margin-bottom: 60px;
//             .row {
//                 margin-bottom: 0;
//                 .col-6 {
//                     &:first-child {
//                         margin-bottom: 32px;
//                     }
//                 }
//             }
//         }

//         .col-12 {
//             padding: 0 16px;
//         }
//     }
// }

.designer-info {
    margin-top: 70px;
    @media(max-width: 767px) {
        margin-top: 45px;
    }
    .heading-inner {
        p {
            font-weight: 700;
            text-transform: uppercase;
            color: $c-green;
        }
        h2 {
            margin-bottom: 20px;
        }
    }
}

.designer-slides {
    overflow: hidden;
    margin-bottom: 50px;
    .col-12 {
        position: static;
    }
    .container-fluid {
        position: relative;
    }
    .heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        p {
            margin: 0;
        }
    }
    .next-pre-slider {
        margin: 0 auto;
        width: 845px;
        display: block;
        @media(max-width: 992px) {
            width: 600px;
        }
        .owl-item.active::after {
            display: none;
        }


    }
}


.more-designers {
    .owl-carousel .owl-nav.disabled {
        display: block;
    }
    h4 {
        margin-bottom: 15px;
    }
    .owl-item {
        text-align: center;     
        figure {
            margin: 0 0 8px 0;
            img {
                max-width: 100%;
            }
        }
    }

    .team-listing {

        .name {
            font-size: 20px;
            margin-bottom: 5px;
            width: 100%;
        }

        figure {
            text-align: center;
            img {
                display: inline-block;
            }
        }

        small {
            a {
                color: #656565;
            }
        }
    }
   
}


@media(max-width: 1199px) {
    .owl-nav {
        button {
            &.owl-prev {
                left: -30px;
            }
        
            &.owl-next {
                right: -30px;
            }
        }
    }
}

@media(max-width: 767px) {
    .profile-info {
        .inner {
            float: left;
            display: block;
            padding-bottom: 40px;
        }
        .left {
            width: 100%;
            text-align: center;
            margin-bottom: 26px;
        }

        .right{
            width: 100%;
            padding-left: 0;
        }

        .info-bottom {
            margin-top: 50px;
            
        }
    }

    .designer-slides {
        .heading {
            display: block;
            p {
                text-transform: uppercase;
                font-weight: 700;
                margin-top: 45px;
                margin-bottom: 25px;
            }
        }

        .container-fluid {
            padding: 0;
            .row {
                margin : 0;
                .col-12 {
                    padding: 0;
                }
            }     
        }
        .next-pre-slider {
            width: 100%;
           
        }
    }
    // .designer-gallery {
    //     img {
    //         max-width: none;
    //         width: 100%;
    //     }

    //     .flex-center {
    //         justify-content: center;
    //     }

    //     .container {
    //         .row{
    //             margin-left: -8px;
    //             margin-right: -8px;
    //             margin-bottom: 34px; 
    //             .row {
    //                 margin-bottom: 0;
    //             }
    //             &:last-child{
    //                 margin-bottom: 0;
    //             }
    //         }
    //         .col-12, .col-6 {
    //             padding: 0 8px;
    //             margin-bottom: 16px;
    //         }
    //     }
    // }

    .more-designers {
        overflow: hidden;

        .team-listing {
            margin: 0;
        }
        
        .owl-nav {
            display: none;
        }

        .owl-dots {
            padding-top: 22px;
            width: calc(100% - 40px);
        }
        hr {
            margin-left: 0;
            width: calc(100% - 40px);
        }
        .container {
            padding: 0 20px;
            width: calc(100% + 40px);
        }
    }
}
