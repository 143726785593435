.partners-info {
    padding-top: 70px;
    padding-bottom: 45px;
    text-align: center;
    .inner {
        display: inline-block;
        max-width: 940px;
    }
    h4 {        
        margin-bottom: 15px;
    }
    p {        
        margin-bottom: 20px;
    }
    small {
        text-align: left;
    }
}

@media(max-width: 767px) {
    .partners-info {
        padding-top: 45px;
        h4 {
            br {
                display: none;
            }
        }
    }

}