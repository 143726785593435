footer {
    background: #f0f0f0;
	padding: 45px 0px 56px 0;
	margin-top: 0px;
	position: relative;	
	&:before {
		top: -20px;
		left: 0;
		content: "";		
		width: 100%;
		background: url("../images/footer-layer.png")repeat-x;
		position: absolute;
		height: 20px;
		@media(max-width: 767px) {
			background-size: 1000px;
			height: 10px;
			top: -10px;
		}
		@media(min-width: 1401px) {
			background-size: auto;
			background-repeat: repeat;
		}
	}
	.footer-col-1 {
		ul {
			padding: 0;
			margin: 0;
			li {
				list-style:none;
				margin-bottom: 20px;
				a {
					font-size:20px;
					text-transform: uppercase;
					color: $body-color;
					text-decoration: none;
					font-weight:700;
					&:hover {
						
					}
				}
			}
		}
	}
	
	.footer-common-listing {
		ul {
			padding: 0;
			margin: 0;
			li {
				list-style:none;
				margin-bottom: 12px;
				line-height: 22px;
				a {
					font-size:16px;
					color: $body-color;
					text-decoration: none;
					font-weight:700;
					 
				}
			}
		}
	}
	
	.footer-bottom {
		border-top: 2px solid #dbdbdb;
		margin-top: 56px;
		padding-top: 40px;
		ul {
			padding: 0;
			margin: 0;
			li {
				list-style:none;
				margin-bottom: 4px;
				display: inline-block;
				a {
					font-size:13px;
					color: $body-color;
					text-decoration: none;
					&:hover {
						
					}
				}
			}
		}
		
		.footer-bt-row {
			ul {
				li {
					position: relative;
					margin: 0 16px 0 0;
					&:first-child {
						margin-left:0;
					}
					
					&:last-child {
						&::after {
							opacity:0;
						}	
					}
					
					&::after {
						content: "";
						width: 1px;
						height: 12px;
						background: #000;
						position: absolute;
						top: 7px;
						right: -9px;
					}
					
					a {
						text-decoration: underline;
						text-decoration-color: #000000;
					}
				}
			}
		}
		
		p {
			font-size:13px;	 
			text-align: right;
			color: #656565;
			a {
				color: #656565;
			}
		}
		
		.footer-bt-row2 {
			margin-top: 11px;
			ul {
				li {
					margin: 0 24px 0 0;
					&:first-child {
						margin-left:0;
					}
					a {
						color: #656565;
					}
				}
			}

			.right-cookie-btn {
				text-align: right;
			}
		}
	}
	
	
	.langauage-block {
		text-align: right;
		select {
			border: none;
			background: transparent;
			text-transform: uppercase;
			font-size: 13px;
			width: 40px;
		}
	}
	
	
}


.social-block {
    text-align: right;
	ul {
			padding: 0;
			margin: 0;
			li {
				list-style:none;
				margin-bottom: 4px;
				display: inline-block;
				margin: 0 16px;
				&:last-child {
					margin-right: 0;
				}
				a {
					font-size:24px;
					color: $body-color;
					text-decoration: none;
					&:hover {
						
					}
					.fa-instagram {
						font-size: 28px;
					}
					.fa-twitter {
						font-size: 27px;
					}
				}

				
			}
		}
}


.footer-descp-block {
	margin: 0 -10px;
	.title-20 {
		font-size: 20px;
		margin-bottom: 12px;
	}
	p {
		margin-bottom: 20px;		 
	}
}


footer {
	.select2-container--default .select2-selection--single {
		background-color: transparent;
	}
}

footer {
	.lang-dropdown-with-flag {
		.flag-icon {
			left: 9px;
		}
	}
	.langauage-block {
		position: relative;
		
	}
}
@media (max-width:767px) {
  .footer-col-1 {
    margin-bottom: 14px;
   }
   
   .footer-descp-block {
		margin-top: 18px;
	}
	
	footer {
		padding: 48px 0px 48px;		
		position: relative;
	
		.social-block {
			text-align: left;
			margin-top: 32px;
			ul {
				li {
					&:first-child{
						margin-left:0;
					}

					
				}
			}

			
		}


		
		.footer-bt-row {
			p {
			   text-align: right;
			   margin-bottom: 3px;
			}
		}

		.footer-bottom {
			margin-top: 32px;
			padding-top: 40px;
			
			.footer-bt-row ul li {
				a {
					line-height: 13px;
					display: inline-block;
				}
			}
			
			p {
				padding-left: 0;
				margin-top: 20px;
				line-height: 18px;
				margin-bottom: 0;
				a {
					text-decoration-color: #656565;
				}
				br {
					display: none;
				}
			}
			
			.footer-bt-row2 ul li {
				margin: 3px 0px;
				display: block;
				a {
					color:#656565;
				}
			}
		}
		
		.langauage-block {
			text-align: left;
			margin-top: 18px;
		}
	}

	footer .footer-bt-row p {
		text-align: left;
		margin-bottom: 3px;
	}
}

footer .langauage-block {
	text-align: left; 
	float: right;
}

@media(max-width: 1199px) {
	footer {
		.footer-bottom {
			p {
				br {
				 
				}
			}
		}
	}
}


@media (min-width:768px) and (max-width:991px) {
	footer {
		.footer-col-1 ul li a {
			font-size: 16px;
		}	
	}

	.social-block ul li {
		margin: 0 5px;
	}

}

@media (max-width:767px) {
	footer {
		padding-bottom: 40px;
		.col-xs-12,
		.container {
			padding: 0 20px;
		}

		.row {
			margin: 0 -20px;
		}

		.footer-descp-block {
			margin: 0;
		}

		.footer-col-1 {
			ul {
				li {
					margin-bottom: 16px;
				}
			}
		}
		.footer-common-listing {
			ul {
				margin-top: 24px;
				li {
					margin-bottom: 12px;
				}
			}
		}

		.social-block {
			margin-top: 25px;
		}

		.footer-bottom {
			margin-top: 27px;
			padding-top : 24px;

			ul {
				margin: 8px 0 30px 0;
			}

			.listing-block ul {
			    margin-bottom: 18px;
			}

			.footer-bt-row2 {
				margin-top: 2px;
				ul {

					li {
						margin: 0 0 10px 0;
					}
				}

				.right-cookie-btn {
					text-align: left;
				}
			}
		}

		.footer-descp-block {
	    	margin-bottom: 5px;
	    	p {
	    		margin-bottom: 20px;
	    	}
	    }	
	}

	.footer-descp-block {
		.title-20 {
			margin-top: 20px;
			margin-bottom: 10px;
		}
	}


}