.impact-prioritize  {
    margin-bottom: 64px;
    padding-left: 4px;
    padding-right: 4px;
    h6 {
      text-transform: uppercase;
      margin-top: 36px;
      margin-bottom: 12px;
      color: $n-blue;
    }
  
    p {
      a {
        text-decoration: underline;
        color: $n-blue;
        font-family: 700;
      }
    }
    small {
      color: $n-blue;
      a {
        text-decoration: underline;
        color: $n-blue;
      }
    }
  }
  
  @media(max-width: 767px) {
    .impact-prioritize {
      margin-bottom: 52px;
      h6 {
        margin-bottom: 20px;
        margin-top: 32px;
      }
    }
  }