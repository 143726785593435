@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');


p,body {
  color: #000;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Gotham A', Gotham B; 
  font-weight: 400;
}

// p {
//   color: #000;
//   font-size: 16px;
//   line-height: 24px;
//   font-family: $gotham-book-font; 
//   font-weight: 400;
// }

// .regular-font {
//     font-family: $gotham-book-font;
//     font-weight: 400;
// }

/*without top-bar */
.mobile-header + .savers-page {
    //padding-top: 236px;
}

.no-top-bar + .savers-page {
    //padding-top: 197px;
}

.thrift-mobile-header + .savers-page {
    //padding-top: 309px;
}

.thrift-mobile-header.no-top-bar + .savers-page {
    //padding-top: 269px;
}

@media(max-width: 992px) { 
    .mobile-header + .savers-page {
        //padding-top: 212px;
    }

    .no-top-bar + .savers-page {
        //padding-top: 173px;
    }

    .thrift-mobile-header + .savers-page {
        //padding-top: 274px;
    }

    .thrift-mobile-header.no-top-bar + .savers-page {
       // padding-top: 234px;
    }
}

@media(max-width: 767px) {

}
@media(max-width: 475px) {
 
 .mobile-header.thrift-mobile-header + .savers-page {
    //padding-top: 296px;
 } 

 .mobile-header + .savers-page {
    //padding-top: 236px;
 }

 .mobile-header.no-top-bar + .savers-page {
    //padding-top: 173px;
 }
 
 .mobile-header.thrift-mobile-header + .savers-page {
    //padding-top: 296px;
 } 

 .thrift-mobile-header.no-top-bar + .savers-page {
     //padding-top: 233px;
 }
}
 
/* 
@font-face {
    font-family: 'gothambold';
    src: url('../fonts/gothambold-webfont.eot');
    src: url('../fonts/gothambold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gothambold-webfont.woff2') format('woff2'),
         url('../fonts/gothambold-webfont.woff') format('woff'),
         url('../fonts/gothambold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gotham_bold';
    src: url('../fonts/gotham_bold_regular-webfont.eot');
    src: url('../fonts/gotham_bold_regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gotham_bold_regular-webfont.woff2') format('woff2'),
         url('../fonts/gotham_bold_regular-webfont.woff') format('woff'),
         url('../fonts/gotham_bold_regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'gothamthin';
    src: url('../fonts/gotham-thin-webfont.eot');
    src: url('../fonts/gotham-thin-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gotham-thin-webfont.woff2') format('woff2'),
         url('../fonts/gotham-thin-webfont.woff') format('woff'),
         url('../fonts/gotham-thin-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'gothammedium';
    src: url('../fonts/gothammedium-webfont.eot');
    src: url('../fonts/gothammedium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gothammedium-webfont.woff2') format('woff2'),
         url('../fonts/gothammedium-webfont.woff') format('woff'),
         url('../fonts/gothammedium-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




  @font-face {font-family: "Gotham Black";
    src: url("../fonts/0f9c349da923afea31fae4db7b8094f0.eot");
    src: url("../fonts/0f9c349da923afea31fae4db7b8094f0.eot?#iefix") format("embedded-opentype"),
    url("../fonts/0f9c349da923afea31fae4db7b8094f0.woff2") format("woff2"),
    url("../fonts/0f9c349da923afea31fae4db7b8094f0.woff") format("woff"),
    url("../fonts/0f9c349da923afea31fae4db7b8094f0.ttf") format("truetype"), 
    url("../fonts/0f9c349da923afea31fae4db7b8094f0.svg#Gotham Black") format("svg"); 
  }
*/
 
@font-face {font-family: "Gotham-Book";
    src: url("../fonts/gotham-book.eot");  
    src: url("../fonts/gotham-book.eot?#iefix") format("embedded-opentype"),
    url("../fonts/gotham-book.woff2") format("woff2"),
    url("../fonts/gotham-book.woff") format("woff"),
    url("../fonts/gotham-book.ttf") format("truetype"), 
    url("../fonts/gotham-book.svg#IXOXQV Gotham-Book") format("svg"); 
  }
h1 {
    font-size: 56px;   
    font-weight: 700;
    @media(max-width: 767px) {
        font-size: 40px;
    }
}
h2 {	 
    font-size: 48px;
    font-weight: 700;
    @media(max-width: 767px) {
        font-size: 38px;
    }
}
h3 {
    font-size: 40px;
    font-weight: 700;
    @media(max-width: 767px) {
        font-size: 32px;
    }
}
h4 {	 
    font-size: 32px;
    font-weight: 700;
    @media(max-width: 767px) {
        font-size: 26px;
    }
}
h5 {	 
    font-size: 24px;
    font-weight: 700;
    @media(max-width: 767px) {
        font-size: 20px;
    }
}
h6 {	 
    font-size: 20px;
    font-weight: 700;
    @media(max-width: 767px) {
        font-size: 18px;
    }
}


.body1 {
  font-size:20px;
  @media(max-width: 767px) {
        font-size: 18px;
    }
}

.body2 {
  font-size:16px;
  line-height: 24px;
}
small,
.small-text {
  font-size:13px;
  line-height:18px;
  display: block;
  a {
	text-decoration:underline;
	color:#656565;
  }
}

.gray-color {
    color:#656565;
}

sub, sup { 
    font-size: 50%;    
}

sup {
    top: -.7em;
}

small sup {
    font-size: 8px;
}
b,
strong {
    font-weight: 700;
}
p {    
    
    a {
        color: #000;
        text-decoration: underline;
    }
}