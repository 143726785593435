.two-col-with-content {
    text-align: center;
    padding: 60px 0;
    .row {
        align-items: center;
    }

    .col-md-6 {
        &:first-child{
            position: relative;
            &:after {
                position: absolute;
                right: -1px;
                width: 2px;
                height: 100%;
                content: "";
                top: 0;
                background:#f0f0f0;
            }
        }
        &:last-child{
            position: relative;
            &:after {
                position: absolute;
                left: -1px;
                width: 2px;
                height: 100%;
                content: "";
                top: 0;
                background:#f0f0f0;
            }
        }
    }
    .inner {
        display: inline-block;
        max-width: 380px;
        padding: 20px 0;
        h3 {
            margin-bottom: 15px;
        }
    }

    .btn {
        margin-top: 15px;
    }
}

@media(max-width: 767px) {
    .two-col-with-content {
        padding-top: 40px;
        padding-bottom: 30px;
        .col-md-6 {
            &:first-child::after {
                display: none;
            }
            &:last-child{
                .inner {
                    margin-top: 30px;
                }
                &:after {
                    left: 0;
                    width: 100%;
                    height: 2px;
                    top: 15px;
                }
            }
        }

        .btn {
            margin-top: 10px;
        }
    }
}