.gallery  {
    ul {       
        padding: 0;
        list-style-type: none;       
    }

    &.partners {
        small {
            text-align: center;
        }
    }
}
.gallery.four-col {
    clear: both;
    ul {
        display: flex;
        justify-content: center;       
        flex-wrap: wrap;
        margin: 0 -16px;
        li {
            margin: 0 16px 30px 16px;
            width: calc(25% - 32px);
            img {
                max-width: 100%;
                border-radius: 4px;
            }
        }
    }

    small {
        color: #656565;
    }
}

 
.gallery {
    .row-five {
        margin-bottom: 60px;
        .col-5 {
            flex: 0 0 20%;
            max-width: 20%;
            margin: 0 0 30px 0;
            img {
                max-width: 100%;
                object-fit: contain;
                cursor: pointer;
            }
        }
    }
}
.gallery.pintrest {
    margin-bottom: 60px;
    ul {
        display: flex;
        justify-content: center;       
        flex-wrap: wrap;
        margin: 0 -16px;
    }
    li {
        margin: 0 16px 30px 16px;
        width: calc(25% - 32px);
        text-align: center;
        img {
            max-width: 100%; 
            display: inline-block;          
        }

        p {
            margin: 15px 0 0 0;
            font-weight: 700;
        }
    }

    .gallery-head {
        display: flex;
        justify-content: space-between;
        border-top: 2px solid #f0f0f0;
        padding-top: 55px;
        margin-bottom: 20px;
        margin-top: 17px;

        p {
            font-weight: 700;
            i  {
                font-size: 26px;
                margin-left: 10px;
                position: relative;
                top: 4px;
            }
        }
    }
}

.four-col {
    .col-md-3 {       
        img {
            max-width: 100%;
            @media(max-width: 767px) {
                width: 100%;
            }
        }
    }
}
.with-slider.row-3-equalHeight-square {
    .slick-slider {
        width: 100%;
    }
    .slick-list {
        padding-bottom: 40px;
    }
    .slide-item {
        width: 100%;
        float: left;        
    }
    .slick-arrow {
        display: none !important;
    }
}

.row-3-equalHeight-square {
    .row {
        margin: 0 -16px;
    }
    .col-12 {
        padding: 0 16px;
    }
    .inner {
        position: relative;
        &:after {
            content:"";
            display: block;
            padding-bottom: 100%;
        }

        img {
            position: absolute;
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}

.gallery {
    .Col-4-Landscape {
        .col-6 {
            margin-bottom: 30px;
        }
        .inner {
            position: relative;
            width: 100%;
            float: left;
            &:after {
                content: "";
                padding-bottom: 63%;
                display: block;
            }
        }
        img {
            max-width: 100%;
            position: absolute;
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 4px;
        }
    }

    .row-3-equalHeight {
        .col-sm-6 {
            margin-bottom: 30px;
            padding: 0 16px;
        }
        .inner {
            position: relative;
            width: 100%;
            float: left;
            &:after {
                content: "";
                padding-bottom: 150%;
                display: block;
            }
        }
        img {
            max-width: 100%;
            position: absolute;
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;          
        }
    }
    
    

    .row-two {
        .col-12 {
            margin-bottom: 30px;
            padding: 0 16px;
        }
        .inner {
            position: relative;
            width: 100%;
            float: left;
            &:after {
                content: "";
                padding-bottom: 100%;
                display: block;
            }
        }
        img {
            max-width: 100%;
            position: absolute;
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;          
        }
    }

    .row-two-with-portrait {
        .col-12 {
            margin-bottom: 60px;
            padding: 0 16px;
        }
        .inner {
            position: relative;
            width: 100%;
            float: left;
            &:after {
                content: "";
                padding-bottom: 150%;
                display: block;
            }
        }
        img {
            max-width: 100%;
            position: absolute;
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;          
        }
    }

    .row-three {
        margin-bottom: 60px;
        .row {
            margin: 0 -16px;
        }        
        .col-6 {
            padding: 0 16px;           
            &:first-child {
                .inner {
                    height: 374px;
                    img {
                        height: 100%;
                        object-position: center;
                        object-fit: cover;
                        width: 100%;
                    }
                }
            }
            &:nth-child(2){
                .inner {
                    height: 560px;                  
                    img {
                        height: 100%;
                        object-position: center;
                        object-fit: cover;
                        width: 100%;
                    }
                }
            }
            &:nth-child(3) {
                .inner {
                    height: 374px;
                    img {
                        height: 100%;
                        object-position: center;
                        object-fit: cover;
                        width: 100%;
                    }
                }
            }
        }
    }

    .single-image {
        margin-bottom: 60px;
        .row {
            margin: 0 -16px;
        }        
        .col-12 {
            padding: 0 16px;
        }

        .inner {
            position: relative;
            overflow: hidden;
            &:after {
                content:"";
                display: block;
            }
        }
        
        img {
            min-width: 100%;
            max-width: 100%;
            position: inherit;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .row-one {
        margin-bottom: 60px;
        .row {
            margin: 0 -16px;
        }        
        .col-12 {
            padding: 0 16px;
        }
        .inner {
            position: relative;
            overflow: hidden;
            &:after {
                content:"";
                padding-bottom: 67%;
                display: block;
            }
        }
        img {
            min-width: 100%;
            max-width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

  
    .row-four {
        margin-bottom: 60px;
        .row {
            margin: 0 -16px;
        }        
        .col-6 {
            padding: 0 16px;
        }
        .inner {
            height: 408px;
            img {
                height: 100%;
                object-position: center;
                object-fit: cover;
                width: 100%;
            }
        }
         
    }

    &.trio-left-gallery {
        .gallery-row-outer {
            display: flex;
            flex-direction: row-reverse;
        }
    }
    .Trio-Left {
        display: block;       
        margin: 0 -16px 60px -16px;
        float: left;
        .trio-left-image {
            padding: 0 16px;
            float: left;
            &:nth-child(1) {
                width: 66.66%;
                float: right;
                .inner {
                    height: 1037px;
                    img {
                        height: 100%;
                        object-position: center;
                        object-fit: cover;
                        width: 100%;
                    }
                }
            }
            &:nth-child(2) {
                width: 33.33%;  
                margin-bottom: 31px;             
                .inner {
                    height: 503px;
                    img {
                        height: 100%;
                        object-position: center;
                        object-fit: cover;
                        width: 100%;
                    }
                }
            }
            &:nth-child(3) {
                width: 33.33%;               
                .inner {
                    height: 503px;
                    img {
                        height: 100%;
                        object-position: center;
                        object-fit: cover;
                        width: 100%;
                    }
                }
            }
        }
    }

    .one-with-offset {
        margin-bottom: 60px;
        float: left;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        .row {
            margin: 0 -16px;            
        }

        .col-md-10 {
            padding: 0 16px;
            text-align: center;
            img {
                width: 100%;
                display: inline-block;
                max-width: 800px;
                height: auto;
            }
        }
    }

    
    .Trio-Right {
        display: block;
         
        float: left;
        width: 100%;
        margin: 0 -16px 60px -16px;
        .trio-right-image {
            padding: 0 16px;
            float: left;
            &:nth-child(1) {
                width: 50%;
                .inner {
                    height: 862px;
                    img {
                        height: 100%;
                        object-position: center;
                        object-fit: cover;
                        width: 100%;
                    }
                }
            }
            &:nth-child(2) {
                width: 50%;  
                margin-bottom: 31px;   
                         
                .inner {
                    height: 415px;
                    img {
                        height: 100%;
                        object-position: center;
                        object-fit: cover;
                        width: 100%;
                    }
                }
            }
            &:nth-child(3) {
                width: 50%;     
                
                .inner {
                    height: 415px;
                    img {
                        height: 100%;
                        object-position: center;
                        object-fit: cover;
                        width: 100%;
                    }
                }
            }
        }
    }
}


.gallery.partners.five-col {
    clear: both;
}

@media(max-width: 1199px) {  
    .gallery {
        .row-three {       
                          
            .col-6 {                 
                &:first-child {                 
                    .inner-wrapper {
                        position: relative;
                        height: auto;
                        float: left;
                        width: 100%;
                        &:after {
                            display: block;
                            content: "";
                            padding-bottom: 100%;
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                        }
                        .inner{                           
                            height: 100%;
                            width: 100%;    
                            overflow: hidden;                        
                            
                            img {
                                max-height: 288px;
                                height: 100%;
                                object-position: center;
                                object-fit: cover;
                                width: auto;
                            }
                        }
                    }
                }

                &:nth-child(2) {
                    .inner {
                        height: 430px;
                    }
                }
                &:nth-child(3) {
                    .inner-wrapper {
                        position: relative;
                        height: auto;
                        float: left;
                        width: 100%;
                        &:after {
                            display: block;
                            content: "";
                            padding-bottom: 100%;
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                        }
                        .inner{                           
                            height: 100%;
                            width: 100%;    
                            overflow: hidden;                        
                            
                            img {
                                max-height: 288px;
                                height: 100%;
                                object-position: center;
                                object-fit: cover;
                                width: auto;
                            }
                        }
                    }
                }
            }
        }

        .row-four {
            .inner {
                height: 330px;
            }
        }

        .Trio-Left {
            .trio-left-image{
                &:nth-child(1) {
                    .inner {
                        height: 830px;
                    }
                }
                &:nth-child(2) {
                    .inner {
                        height: 400px;
                    }
                }
                &:nth-child(3) {
                    .inner {
                        height: 400px;
                    }
                }
            }
        }

        .Trio-Right {
            .trio-right-image{
                &:nth-child(1) {
                    .inner {
                        height: 640px;
                    }
                }
                &:nth-child(2) {
                    .inner {
                        height: 305px;
                    }
                }
                &:nth-child(3) {
                    .inner {
                        height: 305px;
                    }
                }
            }
        }
    } 
}


@media(max-width: 992px) { 
    .gallery {
        .row-five {
           
            .col-5 {
                flex: 0 0 25%;
                max-width: 25%;
                
            }
        }
       
        .row-three {
            .col-6 {
                &:first-child {
                    .inner-wrapper {
                        .inner {
                            img {
                                max-height: 208px;
                            }
                        }
                    }
                }

                &:nth-child(2) .inner {
                    height: 340px;
                }
                &:nth-child(3) {
                    .inner-wrapper {
                        .inner {
                            img {
                                max-height: 208px;
                            }
                        }
                    }
                }
            }
        }

        
        .row-four {
            .inner {
                height: 240px;
            }
        }
    

        .Trio-Left {
            .trio-left-image{
                &:nth-child(1) {
                    .inner {
                        height: 600px;
                    }
                }
                &:nth-child(2) {
                    .inner {
                        height: 285px;
                    }
                }
                &:nth-child(3) {
                    .inner {
                        height: 285px;
                    }
                }
            }
        }

        .Trio-Right {
            .trio-right-image{
                &:nth-child(1) {
                    .inner {
                        height: 470px;
                    }
                }
                &:nth-child(2) {
                    .inner {
                        height: 220px;
                    }
                }
                &:nth-child(3) {
                    .inner {
                        height: 220px;
                    }
                }
            }
        }
    }
}
@media(max-width: 767px) {
    .row-3-equalHeight-square { 
        .inner-wrapper {
            padding: 0 20px;
        }
    }
    .gallery {
        .row-two-with-portrait {
            .col-12 {
                margin-bottom: 40px;
                padding: 0 20px;                 
            }
        }
        .row-one {
            margin-bottom: 40px;
        }
        .row-three {
            margin: 0 -8px 25px -8px;
            .col-6{
                padding: 0 8px;
                margin-bottom: 16px;
                &:first-child {
                    .inner-wrapper {
                        &:after {
                            display: none;
                        }

                        .inner {
                            img {
                                max-height: none;
                                height: auto;
                                width: 100%;
                            }
                        }
                    }
                }
                &:nth-child(2) {
                    .inner-wrapper {
                        &:after {
                            display: none;
                        }

                        .inner {
                            height: auto;
                            img {
                                max-height: none;
                            }
                        }
                    }
                }

                &:nth-child(3) {
                    .inner-wrapper {
                        &:after {
                            display: none;
                        }

                        .inner {
                            img {
                                max-height: none;
                                height: auto;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        .Col-4-Landscape {
            margin: 0 -8px;
            .col-6 {
                margin-bottom: 16px;                 
                padding: 0 8px;              
            }

            img {
                max-width: none;
                width: 100%;
            }
        }
        .row-four {            
            margin: 0 -8px 25px -8px;
            .inner {
                height: 300px;
                
            }
            .col-6 {
                margin-bottom: 16px;
                padding: 0 8px
            }
        }
        
        .one-with-offset {
            margin-bottom: 40px;
        }

        &.pintrest {
            .gallery-head {
                padding-top: 0;
                border-top: 0;
                margin-top: 5px;
                display: block;
            }
            ul {
                margin: 0 -8px;
                li {
                    width: calc(50% - 16px);   
                    margin: 0 8px 45px 8px; 
                    img {
                        max-width: none;
                        width: 100%;
                    }
                }
            }
        }

        .row-five {      
            margin: 0 -8px;         
            .col-5 {
                flex: 0 0 50%;
                max-width: 50%;
                padding: 0 8px;
                margin-bottom: 16px;
            }
        }

        &.partners {    
            ul {
                margin-bottom: 40px;
                li {
                    width: calc(50% - 16px);   
                    margin: 0 8px 25px 8px; 
                    img {
                        max-width: none;
                        width: 100%;
                    }
                }
            }
           
        }
    }
}

@media(max-width: 767px) and (orientation: portrait) {
    .row-3-equalHeight-square {
        .row {
            margin: 0;
        }
        .col-12 {
            padding: 0;
        }

        .inner {
            margin-top: 20px;
        }
    }
    .gallery {
        .row-3-equalHeight{
            .col-sm-6 {
                margin-bottom: 20px;
                padding: 0 20px;
                
            }
        }
        .row-two {
            .col-12 {
                margin-bottom: 20px;
                padding: 0 20px;
                
            }
        }
        .row-four .inner {
            height: 220px;
        }

        .one-with-offset {
            margin: 0 0 40px 0;
            .col-md-8 {
                padding: 0;
            }

        }
        .Trio-Left {
            margin-bottom: 15px;
            .trio-left-image{
                &:nth-child(1) {
                    .inner {
                        height: 480px;
                    }
                    width: 100%;
                    margin-bottom: 16px;
                }
                &:nth-child(2) {
                    .inner {
                        height: 220px;
                    }
                    width: 50%;
                    padding-right: 8px;
                }
                &:nth-child(3) {
                    .inner {
                        height: 220px;
                    }
                    width: 50%;
                    padding-left: 8px;
                }
            }
        }

        .Trio-Right {
            margin-bottom: 40px;
            margin-left: 0;
            margin-right: 0;
            .trio-right-image {
                &:nth-child(1) {
                    margin-bottom: 16px;
                    padding-left: 0px;
                    padding-right: 0px;
                    width: 100%;
                    .inner {
                        height: 500px;
                    }
                }
                &:nth-child(2) {
                    padding-left: 0px;
                    padding-right: 8px;
                    width: 50%;
                    .inner {
                        height: 140px;
                    }
                }
                &:nth-child(3) {
                    padding-right: 0px;
                    padding-left: 8px;
                    width: 50%;
                    .inner {
                        height: 140px;
                    }
                }
            }
        }
    }
}
@media(max-width: 767px) and (orientation: landscape) {
    .row-3-equalHeight-square {
        .row {
            margin: 0 -8px;
        }
        .col-12 {
            padding: 0 8px;
        }
    }
    .gallery {
        &.four-col {
            ul {
                
                li {
                    width: calc(33% - 16px); 
                   
                    
                }
            }
        }

        &.pintrest {           
            ul {
             
                li {
                    width: calc(33% - 16px);  
                    
                }
            }
        }

         
        .row-five {               
            .col-5 {
                flex: 0 0 33.333%;
                max-width: 33.33%;
                
            }
        }
        
    }
}