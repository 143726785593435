
.-impact-left-img-with-text {
  .impact-main-left {
    .solid-heading {
      max-width: 320px;
    }
  }
}
.left-img-with-text {
    background: $n-blue; 
    padding-top: 20px;
    position: relative;
    width: calc(100% - 145px);
    margin-left: 145px;
  
    .heading {
      padding: 0 70px 0 200px;
      display: inline-block;
      background: #DA291C;
    }
  
    .box-layer-inner {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      padding: 28px 0 50px 0;
  
      .left-img {
         width: 290px;    
         position: relative;
        margin-left: -145px;  
        z-index: 1;
         
         img {         
           width: 290px;
           object-fit: cover;
           object-position: center;
           
         }
      }
  
      .right-info {
        width: calc(100% - 160px);
        padding-left: 40px;
      
  
        h6  {
          background: #0077C8;
          color: #fff;
          width: 400px;
          text-transform: uppercase;
          padding: 13px 25px 13px 40px;
          margin-left: -40px;
          margin-bottom: 0;
          position: relative;
          &:before {
            content: "";
            left: -145px;
            top: 0;
            width: 145px;
            background: #0077C8;
            height: 100%;
            position: absolute;
          }
      }
        p {
          color: #fff;
          margin-top: 25px;
          margin-bottom: 10px;
          strong {
            font-weight: 700;
          }
        }
        h2 {        
          color: #fff;
          margin-bottom: 20px;
          letter-spacing: 0.3px;
        }
      }
    }
  
    .bg-layer {
      position: absolute;
      left: 100%;
      top: 0;
      width: 100%;
      height: calc(100% - 1px);
      background: $n-blue;
      &:before {
        position: absolute;
        left: 0;    
        top: 0;
        height: 20px;
        width: 200%;
        background: url("../images/layer.png")repeat-x;
        content: "";
        transform: rotate(-180deg) translate(50%, 0);
        z-index: 1;
      }
      &:after {
        position: absolute;
        right: 0;    
        bottom: 8px;
        height: 20px;
        width: 200%;
        background: url("../images/layer.png")repeat-x;
        content: "";
        transform: rotate(0deg) translate(0%, 50%);
        z-index: 1;
      }
    }

    .bottom-right-align {
      width: 520px;
      float: right;
      font-size: 16px;
      line-height: 24px;
      margin-top: 20px;
      a {
        color: inherit;
      }
    }
  }
  
  @media(max-width: 992px) {
    .left-img-with-text {      
      width: calc(100% + 80px);
      margin-left: 0;
      margin-top: 250px;
      margin-left: -40px;
      margin-right: -40px;
      .box-layer-inner {
        padding-top: 0px;
        display: block;
        margin: -220px 0 0 0;
        .right-info {
          width: 100%;          
        }

        .left-img {
          width: 290px;         
          margin-left: 0;
          width: 100%;
          text-align: center;
        }
      }

      .bg-layer {       
        width: calc(100% + 80px);
        height: calc(100% - 1px);
      }
    }
  }




  @media(max-width: 992px) and (min-width: 768px) {
    .left-img-with-text {
      .box-layer-inner {
        .right-info {
          h2 {
            br {
              display: none;
            }
          }
        }
      }
    }
  }

  @media(max-width: 992px) {
    .left-img-with-text {
      .box-layer-inner {
        .left-img {
          img {
            height: 384px;
            width: 303px;
          }
        }
      }
      .box-layer-inner {
        .right-info {
          padding-right: 40px;
          h6 {
            margin-top: 40px;
          }
        }
      }

      .bottom-right-align {
        width: 100%;
        padding: 0 40px;
        font-style: italic;
      }
    }
  }


  @media(max-width: 767px) {
    .map-space {
      .left-img-with-text {
        margin-top: 280px;
      }
    }
    .-impact-left-img-with-text {
      .impact-main-left {
        margin-bottom: 94px;
      }
      
      .solid-heading {
        margin-bottom: 56px;
      }
      .right-impact-testimonials.with-slider {
        padding-top: 44px;
      }

      .left-img-with-text {
        .bg-layer::before { 
          height: 8px;
          background-size: 800px;
        }
        .bg-layer::after { 
          height: 8px;
          background-size: 800px;
          bottom: 3px;
        }
      }
    }

    .left-img-with-text {
      padding: 0 36px;  
      .box-layer-inner {
        padding-bottom: 60px;
        margin-top: 0;
        .left-img {
          img {
          
            margin-top: -220px;
          }
        }
        .right-info {
          p {          
            margin-top: 28px;
            margin-bottom: 20px;
          }
          h2 {
            margin-bottom: 20px;
          }
          h6 {
            width: auto;
            display: inline-block;
            margin-top: 12px;
            top: 4px;
            position: relative;
          }
        }
      }

     
    }

    .impact-container .impact-xs-padding p {
      text-align: left;
    }
  }

  @media(max-width: 767px) and (orientation: landscape) { 
    .left-img-with-text {
      .box-layer-inner {
        .right-info {
          h2 {
            br {
              display: none;
            }
          }
        }
      }
    }
  }