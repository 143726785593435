.four-column-card.no-top-layer {
	margin-top: 0;
	padding-top: 65px;
	&:before {
		display: none;
	}
}
.four-column-card.no-bottom-layer { 
	&:after {
		display: none;
	}
}

.four-column-card {
	margin: 0px;
	padding: 25px 0px 50px 0px;
	background: $dark-brown;
	position:relative;
	@media(min-width: 1200px) {
		.container {
			padding: 0 65px;
		}
	}
	@media(min-width: 768px) {
		.col-md-6 {
			padding: 0 16px;
		}
	}
	&:after {
		content: "";
		background: url(../images/gray1.png);
		position: absolute;
		bottom: -14px;
		z-index: 1;
		height: 14px;
		width: 100%;
		background-repeat: no-repeat;
		background-size: cover;
		transform: rotate(180deg);
		@media(min-width: 1401px) {
			background-repeat: repeat;
			background-size: auto;
		}
	}
	
	&:before {
		content: "";
		background: url(../images/layer.png);
		position: absolute;
		top: 0px;
		height: 14px;
		width: 100%;
		background-repeat: no-repeat;
		background-size: cover;
		transform: rotate(180deg);
		@media(min-width: 1401px) {
			background-repeat: repeat;
			background-size: auto;
		}
	}
	
	.common-heading-block {
		.title-four-column-card, h6 {
			color:#fff;
		}

		.subtitle-four-column-card, h6{
			margin-bottom: 0;
		}

		.subtitle-four-column-card {
			font-size: 20px;
    		font-weight: 700;
			@media (max-width: 767px) {
				font-size: 18px; 
			}
		}

		.title-four-column-card {
			margin-bottom: 0.5rem;
			font-size: 40px; 
    		font-weight: 700;
			display: block;
			line-height: 1.2;
			max-width: unset;
			  
			@media (max-width: 767px) {
				font-size: 32px; 
			}
		}
		.heading-inner {
			margin-bottom:32px;
			 
		}
	}
	
	.card-wrap {
		min-height: 314px;
		border-radius: 4px;
		padding: 65px 20px 20px;
		&.card-bg1 {
			background:#E1231A;
		}
		&.card-bg2 {
			background:#CD1F17;
		}
		&.card-bg3 {
			background:#BA1C15;
		}
		&.card-bg4 {
			background:#A61913;
		}
		
		p {
			color:#fff;
		}
		
		.icon-row {
			margin-bottom: 20px;
		}
	}
	
	.bottom-row {
		margin-top:32px;
		.bottom-subtitle {
			color:#fff;
			margin-bottom: 7px;
			font-weight: 700;
			font-size: 20px;
		}
		@media(max-width: 767px) {
			margin-top: 3px;
			.bottom-subtitle {
				font-size: 18px;
			}
		}
		p {
			color:#fff;
			a {
				color:#fff;
			}
		}
		small {
		    font-size: 13px;
		    line-height: 18px;
		    display: block;
		    margin-top: 16px;
		    a {
		    	color: #000;
		    	text-decoration: underline;
		    }
		}


		
	}
	
	&.four-column-card-without-bg {
		background:none;
		.clr-black {
			color:$body-color !important;
		}
		
		&::after {
			opacity:0;
			height:0;
		}
		
		&::before {
			opacity:0;
			height:0;
		}
		
		.card-wrap {
			&.card-bg1 {
			   background: #DA291C;
			}
			&.card-bg2 {
			   background: #AF2116;
			}
			&.card-bg3 {
			   background: #831911;
			}
			&.card-bg4 {
			   background: #57100B;
			}
		}	

		&.v2 {
			h2 {
				color: #000;
			}

			h3, h6 {
				color : #000;
			}

			.bottom-row {
				p {
					color: #000;
					a {
						color: #000;
					}
				}
			}

		}
	}
}

@media(max-width: 767px) {
	.four-column-card.v2 {
		margin: 0;
    }
    
    .four-column-card {
		.card-wrap {
			min-height: 175px;
			margin-bottom:24px;
			padding-top: 32px;
		}
		
		 .common-heading-block .heading-inner {
			margin-bottom: 16px;
		}

		.bottom-row {
			margin-top: 8px;
			small {
				br {
					display: none;
				}
			}
		}
	}
}

@media (min-width:768px) and (max-width:991px) {	
	.four-column-card {
		.card-wrap {
			margin-bottom:24px;
		}
    }
}