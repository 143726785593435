 

.impact-main-wrapper {
  section {
    // overflow-y: auto;
    // overflow-x: hidden;
  }
  .wow {
    visibility: hidden;
  }
  h1 {
    font-size: 40px;
    
    @media(max-width: 767px) {
      font-size: 32px;
    }
  }

  h2 {
    font-size: 26px;
    line-height: 36px;
    
    @media(max-width: 767px) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  h3 {
    
    font-size: 20px;
  }
  p {
    color: $n-blue;
    font-size: 20px;
    line-height: 30px;
    a {
      color: $n-blue;
    }
  }

  .body2 {
    font-size:16px;
    line-height: 24px;
    a {
      color: inherit;
    }
  }
  

  img {
    max-width: 100%;
    height: auto;
    width: auto;
  }

  h2.box-heading {   
    border: 1.5px solid #7EDDD3;
    color: #0077C8;
    padding: 12px 36px;  
    text-transform: uppercase;
    display: inline-block;
    margin: 0;  
    font-size: 20px;
    line-height: 26px; 
    
    &.block {
      display: block;
    }
  }
  
}




.impact-main-full {
  width: 100%
}

.impact-main-left {
  width: 380px;
  float: left;
  padding-right: 40px;
}
.impact-main-right {
  width: calc(100% - 380px);
  float: left;
  padding-left: 80px;
  position: relative;
}

.col-2-para {   
  column-count: 2;
  column-gap: 110px;  
  display: inline-block;
  width: 100%;  
  p {
    font-size: 20px;
    line-height: 33px;
    margin-bottom: 40px;
    color: $n-blue;
  }

  h3 {
    color: #0077c8;
    text-transform: uppercase;
    letter-spacing: 0;
    @media(max-width: 767px) {
      color: #003B5C;
    }
    span {
      display: block;
      
    }
  }
}

.signature {
  img {
    margin-bottom: 15px;
  }
  p {
    color: $d-blue3;    
    span {
      display: block;
    }
  }
}
.impact-left-inner {
  img {
    margin-bottom: 40px;
  }
  p {
    font-size: 20px;
    line-height: 33px;
    color: $n-blue;
    max-width: 320px;
    b,
    strong {
      letter-spacing: 0.3px;
    }
  }
 
  .impact-header {
    text-align: center;
    margin-bottom: 16px;
    p {
      margin-bottom: 0px;
    }
    h1 {
      background: #004e7d;
      color: #fff;
      padding: 20px 36px;
      font-size: 48px;
      line-height: 48px;
      margin-bottom: 32px;
      margin-top: 24px;
    }
  }
}


.impact-head {
  margin-bottom: 48px;
  p {
    line-height: 28px;
    color: #003B5C;
  }
}







.three-col-with-img {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  text-align: center;
  width: 100%;
  clear: both;

  .info-col-inner {
    padding: 0 25px;

    p {
      color: $n-blue;
      font-size: 20px;
    }
  }

  h3 {
    color: $n-blue;
    margin-top: 10px;
    font-size: 40px;
  }

  h2 {
    font-size: 50px;
    font-weight: 700;
    color: #1279C5;
    line-height: 50px;
    span {
      display: block;
      color: $n-blue;
      font-size: 75px;
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }
}


.small-icon-blocks {
  list-style-type: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;   
  padding: 0;
  margin-bottom: 35px;
  
  li {
    text-align: center;
    width: 25%;
    padding: 0 10px;
    margin-bottom: 30px;
    .img-wrap {
      height: 64px;
      margin-bottom: 18px;
      img {
        max-height: 100%;
      }
    }
    h2 {
      font-size: 48px;
      font-weight: 700;
      color: #003B5C;
    }
    p {
      font-size: 18px;
      line-height: 23px;
    }
  }
}

.impact-2-col-with-top-img {
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  justify-content: center ;
  margin-bottom: 25px;
  .inner {
    width: 50%;
    padding: 0 15px;

    .top-img {
      margin-bottom: 20px;
      height: 170px;
      text-align: center;
      .multiple {
        display: flex;
      }
      
    }
    p {
      color: $n-blue;
      display: inline-block;
      max-width: 250px;
    }
  }

  &.with-title {
    .top-img {
      height: 80px;
      align-items: self-end;
      justify-content: center;
    }

    .bottom-info {
      margin-bottom: 32px;
      h6 {
        color: $n-blue;
        text-transform: uppercase; 
        margin-bottom: 12px;
      }
      p { 
        max-width: 355px;
      }
    }
  }
}

.two-col-with-title {
  .impact-2-col-with-top-img {
    .top-img {
      height: 80px;
      align-items: self-end;
      justify-content: center;
    }

    .bottom-info {
      margin-bottom: 32px;
      h6 {
        color: $n-blue;
        text-transform: uppercase; 
        margin-bottom: 12px;
      }
      p { 
        max-width: 355px;
      }
    }
  }
} 
 
.-impact-2-col-para {
  position: relative;
  img {
    margin-bottom: 15px;
  }
  .sticky-title {
   position: relative;
   z-index: 2;
   
  }
  .solid-heading {
    span {

    }

    @media(min-width: 768px) {
      span + span {
          padding-top: 20px;
          position: relative;
          left: -36px;
      }
    }
  }
}

 

.map-outer {
  position: relative;
  margin-top: 70px;
  .map-label.map-label {
    display: flex;
    position: relative;
    left: 370px;
    bottom: 40px;
    align-items: center;
    p {
      margin: 0;
      br {
        display: none;
        @media(max-width: 992px) {
          display: block;
        }
      }
    }
    span {
      display: block;
      height: 28px;
      width: 28px;
      background: #0091D9;
      margin-right: 15px;
    }
  }
}
@media(max-width: 1280px) {
  .impact-main-right {
    padding-left: 0;
  }
}
@media(max-width: 1199px) {
  
  .impact-main-left {
    width: 850px;
    margin: 0 auto;
    margin-bottom: 48px;
    padding-right: 0;
    p {
      max-width: 100%;
    }
 
  }
  
  .impact-main-right {
    width: 100%;
    &.ipad-full {
      width: 100%;
    }
  }
}

@media(max-width: 1008px) and (min-width: 993px){
  .-impact-2-col-para {
    .side-space-85 {
      padding: 0 15px;
    }
  }
}

@media(max-width: 992px) {
  
  .col-2-para {
    grid-column-gap: 70px;
    column-gap: 0;
    column-count: 1;
    p {
      margin-bottom: 20px;
    }
  }

  .impact-container {
    padding: 0 30px;    
  }

  .impact-main-left {
    width: 100%;     
    padding-right: 0px;
    p {
      max-width: 100%;
    }
  }

  .impact-main-right {
    padding-left: 0;
    width: 100%;     
  } 

  .solid-heading {
    display: block;
     
  }
 
  .solid-heading.big-heading {
    display: inline-block;
    min-width: auto;
    &:before {
      top: 0;
      height: 100%;
    }
    span {
      font-size: 56px;
      line-height: 56px;
      display: inline-block;     
    }
  }  
}

@media(max-width: 767px) {  
  .impact-main-wrapper {    
    h2.box-heading {
      font-size: 18px;
      letter-spacing: 2px;
      padding: 11px 20px;
    }    
  }
  
 
  .impact-head {
    p {
      br {
        display: none;
      }
    }
  }
  .impact-main-left {
    margin-bottom: 60px;
  }
  .impact-container {
    padding: 0; 
    .medium-container,
    .small-container {
      padding: 0 36px !important;
    }

    .impact-main-left {
      padding: 0 36px;
    }

    .col-12 {
      padding: 0 36px;
    }

    .xs-no-space {
      padding: 0;
    }

    .impact-xs-padding{
      padding: 0 36px;
    }
  }

  .box-heading {
    text-align: center;
  }

  .impact-main-wrapper {
    .body1,
    p {
      font-size: 18px;
      line-height: 27px;
    }
  }

  .impact-left-inner {
    p {
      font-size: 18px;
      line-height: 30px;
      margin: 0;
    }
  }

  .-impact-2-col-para {
    .solid-heading {
      margin-bottom: 56px;
      max-width: 320px;

     
    }
  }

  
  .col-2-para {
    p {
      font-size: 18px;
      line-height: 1.4;
      display: block;
    }

    .leftSideContent {
      .mobile-hide.active {
        display: inline !important;
      }
    }
    .rightSideContent {
      .mobile-hide.active {
        display: block !important;
      }
    }
  }

  .impact-left-inner img {
    margin-bottom: 40px;
    max-width: 276px;
    max-height: 131px;
  }

  .impact-container {    
    .mobile-negtive-space {
      margin: 0 -36px;
    }
    .row {
      margin: 0;
    }


    .hiddeninfo-btn {
      font-size: 20px;
      text-transform: uppercase;
      font-weight: 700;
      color: #0077cb;
      display: inline-block;
      margin-bottom: 50px;
      position: relative;
      cursor: pointer;
      
      @media(max-width: 767px) {
        font-size: 16px;
        padding-right: 30px;
      }
      span {
        position: absolute;
        right: 0px;
        top: 12px;
        i {
          display: block;
          width: 16px;
          height: 2px;
          background: #0077cb;
          &:nth-child(2) {
            transform: rotate(-90deg);
            top: -2px;
            position: relative;
            width: 14px;
          }
        }
        @media(max-width: 767px) { 
          i {
            width: 15px;
          }
        }
      }
      &.active {
        span {
          i:nth-child(2) {
            display: none;
          }
        }
      }
    }
  }


  .impact-2-col-with-top-img {
    margin-top: 10px;
    .inner {
      width: 100%;
      margin-bottom: 42px;
    }
  }
 
  .two-col-with-title {
    .impact-2-col-with-top-img {
      margin-top: 10px;
      .inner {
        width: 100%;
        margin-bottom: 42px;
      }     
      .bottom-info {
        margin-bottom: 0;
        p {
          margin-bottom: 0;
        }
      }
    }
  } 
 

  .three-col-with-img {
    display: block;
    .info-col-inner {
      p {
        margin-bottom: 20px;
      }
      img {
        margin-bottom: 44px;
      }
      h2 {
        margin-bottom: 32px;
      }
      h3 {
        margin-top: -30px;
      }
      &:last-child {
        p {
          margin-bottom: 16px;
        }
      }
    }
  }

  .small-icon-blocks {
    margin-bottom: 32px;

    li {
      width: 50%;
      margin-bottom: 32px;
      padding-left: 5px;
      padding-right: 5px;
      .img-wrap {
        margin-bottom: 20px;
      }
      p {
        font-size: 18px;
        line-height: 1.2;
        margin: 0;
      }
      h2 {
     
        line-height: 36px;
      }
    }
  }

  .impact-2-col-with-top-img.with-title {
    .bottom-info {
      margin-bottom: 0;
      p {
        margin-bottom: 0;
      }
    }
  }



  .-impact-values {
    .impact-container {
      .impact-main-left {
        margin-bottom: 32px;
      }
      .impact-head {
        margin-bottom: 40px;
      }
    }
  }

  .map-outer {
    margin-top: 0;
    .map-label.map-label {    
      left: 80px;
      bottom: -25px;   
    }
  }
}
