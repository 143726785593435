.full-bleed-banner1 {
  min-height: 585px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;
  background-position: right;
  background-repeat: no-repeat;

  &::after {
    content: "";
    background: url(../images/layer.png);
    position: absolute;
    bottom: 0;
    height: 20px;
    width: 100%;
    left: 0;
    background-repeat: repeat-x;
    @media (max-width: 767px) {
      height: 8px;
      width: 100%;
      background-size: 1000px;
    }
    @media (min-width: 1401px) {
      background-size: auto;
      background-repeat: repeat;
    }
  }

  .container {
    position: relative;
    z-index: 1;
  }

  .desktop-bg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center right;
    background-repeat: no-repeat;

    @media (max-width: 992px) {
      background-position: center right;
    }
    @media (max-width: 767px) {
      background-position: center bottom;
    }
  }

  .mobile-bg {
    display: none;

    @media (max-width: 1024px) {
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center top;
      background-repeat: no-repeat;
    }
  }

  &.content-align-right {
    text-align: right;
  }

  &.content-align-center {
    text-align: center;
  }

  .banner-info {
    @media (min-width: 768px) {
      max-width: 530px;
      display: inline-block;
    }

    div {
      color: #fff;
    }
    h1 {
      color: #fff;
      font-weight: 700;
      margin-bottom: 16px;
    }

    p {
      color: inherit;
      font-size: 16px;
      line-height: 1.4;
      margin-bottom: 32px;
    }

    .large-text-cta {
    }
  }

  .plus-layer {
    position: absolute;
    top: 0;
    left: 0;
  }

  .mob-media-block {
    @media (min-width: 768px) {
      display: none;
    }

    img {
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    background-image: none;
    display: block;
  }

  /*Short banner*/
  &.short {
    background-size: cover;
    background-position: center right;
    min-height: 475px;

    &.extra {
      background-color: #841911;
      background-image: url("../images/short-fullflled-banner.jpg");
    }

    p {
      font-size: 20px;
      font-weight: 700;
    }
  }
}

.full-bleed-banner2 {
  min-height: 585px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;
  background-position: right;
  background-repeat: no-repeat;

  &::after {
    content: "";
    background: url(../images/layer.png);
    position: absolute;
    bottom: 0;
    height: 20px;
    width: 100%;
    background-repeat: repeat-x;
    @media (min-width: 1401px) {
      background-size: auto;
      background-repeat: repeat;
    }
  }

  .container {
    position: relative;
    z-index: 1;
  }

  .desktop-bg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center right;
    background-repeat: no-repeat;

    @media (max-width: 767px) {
      background-position: center bottom;
    }
  }

  .mobile-bg {
    display: none;

    @media (max-width: 767px) {
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center bottom;
      background-repeat: no-repeat;
    }
  }

  &.content-align-right {
    text-align: right;
  }

  &.content-align-center {
    text-align: center;
  }

  .banner-info {
    @media (min-width: 768px) {
      max-width: 530px;
      display: inline-block;
    }
  }
}

.full-bleed-banner4 {
  min-height: 520px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;
  background-position: right;
  background-repeat: no-repeat;

  .container {
    position: relative;
    z-index: 1;
  }
  &.banner-solid-bg {
    .banner-info {
      max-width: 910px;
    }
  }

  .desktop-bg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center right;
    background-repeat: no-repeat;

    @media (max-width: 767px) {
      background-position: center bottom;
    }
  }

  .mobile-bg {
    display: none;

    @media (max-width: 767px) {
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center bottom;
      background-repeat: no-repeat;
    }
  }

  &.content-align-right {
    text-align: right;
  }

  &.content-align-center {
    text-align: center;
  }

  .banner-info {
    @media (min-width: 768px) {
      display: inline-block;
    }

    .small-banner-logo {
      background: $d-blue3;
      display: inline-block;
      padding: 18px 16px;
      margin-bottom: 0px;
    }

    h1.big {
      color: #fff;
      font-weight: 700;
      margin-bottom: 16px;
      font-size: inherit;
      line-height: inherit;
      padding: 0;
      margin: 0;
      letter-spacing: -3px;

      top: 0;
      samp {
        font-family: inherit;
        background: $d-blue3;
        line-height: 70px;
        font-size: 90px;
        padding: 14px 0 15px 0;
      }
      span {
        display: inline-block;
        color: #88e1d8;
        padding: 30px 32px;
        background: none;
        background: url("../images/impact-title-main-bg.png") no-repeat;
        background-position-x: 0%;
        background-position-y: 0%;
        background-clip: border-box;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-position: center;
        // -webkit-box-decoration-break: clone;
        // -ms-box-decoration-break: clone;
        // -o-box-decoration-break: clone;
        // box-decoration-break: clone;
        // border-left: 32px #0077C8 solid;
        // border-right: 32px #0077C8 solid;
        // border-top: 9px #0077C8 solid;
        // border-bottom: 12px #0077C8 solid;
        text-align: center;
      }

      span + span {
        position: relative;
        margin-left: -40px;
      }
    }

    h1 {
      margin-top: 0px;
      letter-spacing: 0px;
      position: relative;
      top: -14px;

      span {
        background: $n-blue;
        display: inline-block;
        padding: 5px 32px 5px 32px;
        color: inherit;
      }
    }
  }

  @media (max-width: 992px) {
    .banner-info {
      h1.big {
        samp {
          font-size: 70px;
          line-height: 52px;
          display: block;
          padding-top: 30px;
          padding-bottom: 30px;
          span {
            padding-top: 0;
            padding-bottom: 0;
          }
          span + span {
            padding-left: 20px;
          }
        }
      }
      h1 {
        font-size: 35px;
      }
    }
  }

  @media (max-width: 767px) {
    min-height: 375px;
    .banner-info {
      h1.big {
        display: inline-block;
        max-width: 350px;
        samp {
          font-size: 48px;
          line-height: 38px;
          display: block;
          padding-top: 0;
          padding-bottom: 0;
          background: none;
          span {
            padding: 18px 30px;
            background: #0077c8;
            color: #7ddcd3;
            background-position-y: 0%;
            background-clip: border-box;
            -webkit-background-clip: inherit;
            -webkit-text-fill-color: inherit;
          }
          span + span {
            margin: 0;
            padding: 0px 24px 18px 24px;
          }
        }
      }
      h1 {
        display: none;
      }
      .small-banner-logo {
        padding: 10px 12px;
      }
      img {
        max-width: 62px;
        max-height: 50px;
      }
    }
  }
}

.full-bleed-banner5 {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;
  background-position: right;
  background-repeat: no-repeat;

  &.banner-solid-bg {
    .banner-info {
      max-width: 910px;
      display: flex;
    }
  }

  .video-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;

    .image-mobile {
      width: 100%;
    }
  }

  .video-element {
    width: 100%;
    display: block;
  }

  .play-pause-button {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .mui-play-pause-button {
    background-color: #0003;
    min-width: 40px;
    height: 40px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .banner-container {
    top: 0px;
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
  }

  .banner-info {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    line-height: 1.2;

    div:first-child {
      line-height: inherit;
    }

    .small-banner-logo {
      display: inline-block;
      padding: 18px 16px;
      margin-bottom: 0px;
    }

    h1.big {
      font-weight: 700;
      margin-bottom: 16px;
      font-size: inherit;
      // line-height: inherit;
      padding: 0;
      margin: 0;
      letter-spacing: -3px;

      top: 0;
      samp {
        font-family: inherit;
        // line-height: 70px;
        font-size: 90px;
        padding: 14px 0 15px 0;
      }
      span {
        display: inline-block;
        padding: 30px 32px;
        background: none;
        background: url("../images/impact-title-main-bg.png") no-repeat;
        background-position-x: 0%;
        background-position-y: 0%;
        background-clip: border-box;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-position: center;
        text-align: center;
      }

      span + span {
        position: relative;
        margin-left: -40px;
      }
    }

    h1 {
      margin-top: 0px;
      letter-spacing: 0px;
      position: relative;
      // top: -14px;
      width: 100%;
      line-height: inherit;

      span {
        display: inline-block;
        line-height: inherit;
      }
    }

    &.no-display-mobile {
      display: none;
    }
  }

  @media (max-width: 992px) {
    .banner-info {
      h1.big {
        samp {
          font-size: 70px;
          line-height: 52px;
          display: block;
          padding-top: 30px;
          padding-bottom: 30px;
          span {
            padding-top: 0;
            padding-bottom: 0;
          }
          span + span {
            padding-left: 20px;
          }
        }
      }
      h1 {
        font-size: 35px;
      }
    }
  }

  @media (max-width: 767px) {
    .banner-info {
      h1 span {
        font-size: 32px !important;
      }

      .small-banner-logo {
        padding: 10px 12px;
      }

      img {
        max-width: 62px;
        max-height: 50px;
      }
    }
  }
}

.full-bleed-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 450px;
  text-align: center;
  position: relative;

  .container {
    max-width: 100%;
    position: relative;
    z-index: 1;
  }

  .desktop-bg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center right;
    background-repeat: no-repeat;

    @media (max-width: 767px) {
      background-position: center bottom;
    }
  }

  .mobile-bg {
    display: none;

    @media (max-width: 767px) {
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center bottom;
      background-repeat: no-repeat;
    }
  }

  h1 {
    font-weight: 700;

    span {
      background: #e3cfb6 url("../images/vector1.png");
      padding: 8px 24px 8px 18px;
      display: inline-block;
    }
  }

  .banner-descp {
    max-width: 607px;
    width: 100%;
    min-width: 40%;
    margin: 38px auto 0;
    background: rgba(0, 58, 93, 0.8);
    color: #fff;
    padding: 40px 45px;
    min-height: 172px;
    display: flex;
    align-items: center;

    p {
      font-weight: 700;
      color: inherit;
      font-size: 20px;
      margin-bottom: 0;
    }
  }

  .cta-wrap {
    .small-text-cta {
      margin: 0 20px;
    }
  }

  &.content-align-right {
    text-align: right;
  }

  &.content-align-center {
    text-align: center;
  }
}

.banner-with-solid-bg.banner-solid-bg {
  .container {
    position: relative;
    z-index: 1;
  }

  .desktop-bg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center right;
    background-repeat: no-repeat;

    @media (max-width: 767px) {
      background-position: center bottom;
    }
  }

  .mobile-bg {
    display: none;

    @media (max-width: 767px) {
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center top;
      background-repeat: no-repeat;
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .full-bleed-banner1 {
    background-position: center right;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .full-bleed-banner1 {
    padding-bottom: 70px;
    padding-top: 40px;
    min-height: 495px;
    background-size: 100%;
  }
}

/*Contact Banner*/
.banner-with-solid-bg.contact-banner {
  min-height: 440px;

  .container {
    position: relative;
    z-index: 1;
  }

  .desktop-bg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;

    @media (max-width: 767px) {
      background-position: center bottom;
    }
  }

  .mobile-bg {
    display: none;

    @media (max-width: 767px) {
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center bottom;
      background-repeat: no-repeat;
    }
  }

  .banner-form {
    .select-outer {
      background: #fff;
      border-radius: 4px;
    }
  }
}

@media (max-width: 767px) {
  .full-bleed-banner {
    .banner-descp {
      padding: 25px 35px;
      margin-top: 31px;

      p {
        margin: 0;
        font-size: 18px;
      }
    }
  }

  .full-bleed-banner1 {
    min-height: 530px;
    padding-top: 40px;
    background-image: none !important;
    position: relative;
    overflow: hidden;

    .container {
      position: relative;
      z-index: 1;
    }

    .mob-media-block {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
    }

    .plus-layer {
      display: none;
    }

    &.short {
      min-height: 400px;

      .mob-media-block {
        bottom: auto;
        top: 0;
      }
    }
  }

  .full-bleed-banner .cta-wrap {
    .small-text-cta {
      margin: 0;
    }

    span {
      display: block;
    }
  }
}

@media (max-width: 767px) and (orientation: landscape) {
  .full-bleed-banner1 {
    .mob-media-block {
      img {
        width: auto;
      }
    }
  }
}

.a-line-underline .line-underline {
  color: inherit !important;
  display: inline;
  position: relative;
  transition: none !important;
}

.a-line-underline:not(:hover) .line-underline {
  border-bottom: 2px solid;
  background-image: none !important;
}

.a-line-underline:hover .line-underline {
  border-bottom: none;
  background-repeat: repeat-x;
  background-position: left bottom;
  background-size: auto 8px;
  padding-bottom: 5px;
}
