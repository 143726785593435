
.two-column-card{
	background-color: #00A1E4;
	padding-top: 100px;
	padding-bottom: 25px;
	position: relative;
	margin-top: 30px;
	 
	z-index: 0;
	&.no-top-layer {
	&:before {
			display: none;
		}
	}
    &.no-bottom-layer {
    	&:after {
    		display: none;
    	}
	}
	
	.card-wrap {
		margin-bottom: 90px;
		transition: 0.3s all;
		cursor:pointer;
		position: relative;
		h3 {
			max-width: 455px;
			transition: all ease-in-out 0.5s;
			br {
				display: none;
				@media(max-width: 1199px) {
					display: block;
				}
				@media(max-width: 767px) {
					display: none;
				}
				@media(max-width: 400px) {
					display: block;
				}
			}
		}
		h3,p {
			color:#003A5D;
		
		}
		p {
		  font-size: 16px;	
		  max-width: 77%;	
			margin-bottom: 20px;			 
			transition: all ease-in-out 0.5s;
		}

		.large-text-cta {
			color: #003A5D;
			border-color: #003A5D;
			margin-top: 15px;
			display: inline-block;
		}
		
		h3 span {display:block;}
		
		&:hover {
			h3,p {
				color:#fff;
				border-color:#fff;
				transition: all ease-in-out 0.5s;
			}
			 
			

			.large-text-cta {
				border-bottom:2px solid transparent;	
				transition: all ease-in-out 0.5s;	
				
				color:#fff;
				&::after {
					opacity:1;
					background: url(../images/link-layer-white.png);
				}
			}

			.hover-thumb {
				opacity: 1;
			}	
		}
		
		.hover-thumb {
			position: absolute;
			top: -61px;
			left: -75px;
			max-width: 170px;
			opacity: 0;
			transition: 0.3s all;
		}
		
		.icon-text-cta {
			margin-bottom: 30px;
		}
	}
	  
	&::after {
		content: "";
		background: url(../images/layer.png);
		position: absolute;
		bottom: 0px;
		height: 20px;
		width: 100%;
		background-repeat: repeat-x;
		@media(max-width: 767px) {
			background-size: 600px;
			height: 8px;
		}
		 
	}
	
	&::before {
		content: "";
		background: url(../images/layer-lb-top.png);
		position: absolute;
		top: -20px;
		height: 20px;
		width: 100%;
		background-repeat: repeat-x;
		@media(max-width: 767px) {
			background-size: 600px;
			height: 8px;
			top: -8px;
		}
	}

	
	.card-inner {
		@media (min-width:1200px) {
			padding:0 48px;
		}
	}
}

@media(max-width: 767px) {
    .two-column-card {
		 
		.card-wrap:hover {
			.hover-thumb {
				opacity: 0;
			}
		}
		
		.card-wrap {
			margin-bottom: 65px;
			p {
				margin:15px 0px;
				max-width: 100%;

			}
			
			// .large-text-cta {
			// 	border-bottom-color:transparent;
			// 	&::after {
			// 		opacity:1;
			// 	}
			// }
			
			.icon-text-cta {
				a {
					border-bottom-color:transparent;
					&::after {
						opacity:1;
						background: url(../images/link-layer-white.png);
					}
				}
			}
		}
	}
}