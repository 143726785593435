/*Modules*/
//@import './modules/overrides';
@import "./modules/variables";
@import "./modules/mixins";
@import "./modules/elements";

/*Partials*/
@import "./partials/footer";
@import "./partials/header";
 

/*Vendor*/
@import "./vendor/bootstrap.min";
@import "./vendor/animate";

/*components*/
@import "./components/fullbleedbanner";
@import "./components/find-store-newsletter";
@import "./components/two-columns-cards";
@import "./components/hero-card";
@import "./components/typography";
@import "./components/common-elements";
@import "./components/buttons";
@import "./components/buttons-new";
@import "./components/responsive-tabs.scss";
@import "./components/modal.scss";
@import "./components/next-pre-slider.scss";
@import "./components/title-banner.scss";
@import "./components/owl-slider.scss";
@import "./components/two-col-with-content";
@import "./components/gallery";
@import "./components/simple-card";
@import "./components/modal-card-box";
@import "./components/profile-info";
@import "./components/grid-media-blocks";
@import "./components/team-listing";
@import "./components/four-column-card";
@import "./components/carousel-media-block";
@import "./components/impact_testimonials";
@import "./components/impact-trends";
@import "./components/impact-left-img-with-text";
@import "./components/impact-cycle";
@import "./components/impact-timeline";
@import "./components/impact-source";
@import "./components/impact-solid-heading";
@import "./components/impact-graph";
@import "./components/impact-percentage-box";
@import "./components/impact-stats";
@import "./components/impact-prioritize";
@import "./components/cookie-bar";
@import "./components/video-card-3-columns";
 
 

/*Contact*/
@import "pages/contact-page.scss"; 
@import "pages/about.scss"; 
@import "pages/partners.scss"; 
@import "pages/designer-gallery"; 
@import "pages/thrift-state"; 


[data-reach-menu-popover] {
    z-index: 1000; 
}

[data-reach-menu-button] {
    color: rgb(0, 0, 0);
    border: none;
    background-color: white;
    font-family: 'Franklin Gothic Medium', Arial, sans-serif;
    font-weight: 800;
    font-size: 12px;
    line-height: 24px;
}

[data-reach-menu-item] {
    color: rgb(0, 0, 0);
    border: none;
    background-color: white;
    font-family: 'Franklin Gothic Medium', Arial, sans-serif;
    font-weight: 800;
    font-size: 12px;
    line-height: 24px;
  }