@media (min-width: 567px) and (max-width: 767px) {
  .container {
    max-width: 100%;
  }
}
.juicer-feed a
{
  display: block;
}
.juicer-feed a:focus,
.slick-arrow:focus,
.notify-bar button:focus,
*:focus {
  outline: 2px dotted #000;;
}
.small-text-cta:focus,
.cta-black:focus,
.cta-white:focus,
.btn:focus {
  outline: none;
}

 


.mb-6,
.my-6 {
  margin-bottom: 6rem !important;
}

.pb-10 {
  padding-top: 10px;
}

.mb-40 {
  margin-bottom: 40px;
}
.mb-60 {
  margin-bottom: 60px;
}

.italic {
  font-style: italic;
}
 
.light * {
  color: #fff;
}

.about-info {
  img {
    height: auto;
    width: auto;
  }
}
.blue-bg {
  background: #00A1E4;
  position: relative;
  padding: 110px 0;
  &.width-round-borders {
      &:before {
          content: "";
          background: url(../images/layer-lb-top.png);
              background-repeat: repeat;
              background-size: auto;
          position: absolute;
          top: -14px;
          height: 14px;
          width: 100%;
          background-repeat: no-repeat;
          background-size: cover;
      }

      &:after {
          content: "";
          background: url(../images/layer.png);
              background-repeat: repeat;
              background-size: auto;
          position: absolute;
          bottom: 0px;
          height: 14px;
          width: 100%;
          background-repeat: no-repeat;
          background-size: cover;
      }
  }
}


@media(min-width: 768px) {
  .impact-container {
    max-width: 720px;   
    margin: 0 auto;
  }
}

@media(min-width: 992px) {
  .impact-container {
    max-width: 960px;       
  }
}

@media(min-width: 1200px) {
  .impact-container {
    max-width: 1215px;   
  }
  .lg-mt-20 {
    margin-top: 20px;
  }
}
@media(min-width: 1300px) {
  .impact-container {
    max-width: 1280px;   
  }
}

.skip {
    position: absolute;
    top: -1000px;
    left: -1000px;
    height: 1px;
    width: 1px;
    text-align: left;
    overflow: hidden;
  }
    
 a.skip:active, 
 a.skip:focus {
   left: 0; 
   top: 0;
   width: auto; 
   height: auto; 
   overflow: visible; 
   color:$body-color;
   z-index: 999;
 }
 
.small-container {
  max-width: 680px;
  margin: 0 auto;
}
.medium-container {
  max-width: 850px;
  margin: 0 auto;
}

.horizontal-rule {
  clear: both;
}
.flex-center {
  display: flex;
  align-items: center;
}

.sharp-corner {
  border-radius: 0 !important;
}

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus { 
  background: #004E7D;
}
 
.relative {
  position: relative;
}

.side-space-35 {
  padding: 0 35px;
}

.side-space-85 {
  padding: 0 85px;
}

.mt-15 {
  margin-top: 15px;
}
.mt-25 {
  margin-top: 25px;
}

@media (min-width: 993px) {
  .desktop-pr-24 {
    padding-right: 24px;
  }

  .desktop-pl-24 {
    padding-left: 24px;
  }
}
@media (max-width: 1280px) {
  .side-space-85 {
    padding: 0;
  }
}


@media (max-width: 767px) {
  .padding-col-xs-5 {
    padding-left: 5px;
  }

  .xs-padding-lr-30 {
    padding: 0 30px;
  }

  .container {
    padding: 0 20px;
  }

  .col-12, .col-xs-12, .col-md-3, .col-md-6, .col-lg-6, .col-md-4  {
    padding: 0 20px;
  }

  .xs-mt-45 {
    margin-top: 45px;
  }

  .row {
    margin: 0 -20px;
  }

  .box-heading {
    padding: 16px 32px;
  }

  .xs-no-padding {
    padding: 0 !important;
  }
}
 