.col-12.text-center {
    margin-top: 35px;
}

.contact-form {
    background: #003b5c;
    padding: 70px 0;
    position: relative;

    .MuiFormHelperText-root.Mui-error {
        display: contents;
        color: #FF7575;
    }
    .MuiFormLabel-root {color: #636363;}
    .MuiSelect-select.MuiSelect-select,
    .MuiFormControl-root.MuiTextField-root,
    .MuiInputBase-root input {
        &,
        &:hover,
        &:active,
        &:focus,
        &:active:focus,
        &:active:hover {
            background-color: #E8F0FE;
            border-radius: 4px;
            transition: none;

            &.Mui-disabled {
                background-color: #e8f0feb7;
            }
        }

        .MuiFilledInput-root {
            background-color: transparent;
        }
    }
  
    &:after {
        position: absolute;
        left: 0;
        width: 100%;
        height: 33px;
        background: url(../images/layer.png)repeat-x;
        bottom: -14px;
        content: "";
    }

    .desktop-bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 580px;
        background-repeat: no-repeat;
        background-size: 100%;
    }

    @media(min-width: 768px) {
      .mobile-bg {
         display: none;
      }
    }

    @media(max-width: 767px) {
        .desktop-bg {
        display: none
        }
        
        .mobile-bg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 300px;
            background-repeat: no-repeat;
            background-size: 100%;
        }
    }

    h2 {
        color: #fff;
        margin-bottom: 50px;
    }

    .noteBlock {
        a {
          color: #fff;
        }
    }

    form {
        width: 775px;
        margin: 0 auto;

        ::-webkit-input-placeholder { /* Edge */
			color: #000;  
			opacity: 1;
		}
	
		:-ms-input-placeholder { /* Internet Explorer 10-11 */
			color: #000;  
			opacity: 1;
		}
	
		::placeholder {
			color: #000; 
			opacity: 1; 
		}  
    }

    textarea {
        width: 100%;
        border: 0;
        resize: none;
        height: 110px;
        padding: 15px 20px;
        border-radius: 3px;
        margin-bottom: 20px;
    }

    .g-recaptcha {
        display: inline-block;
        margin: 5px 0 40px 0;
    }

    .form-control {
        background-color: transparent;
        margin-bottom: 20px;
    }
    
    .space-right {
        padding-right: 7.5px;
    }
 
    .space-left {
        padding-left: 7.5px;
    }

    .field-wrap {
        display: flex;
    }

    .countrycode  {
        width: 50px;
        margin-right: 5px;
        padding: 0 5px;
    }

    .phone-field {
        width: calc(100% - 55px);
    }

    .form-group {
        margin-bottom: 30px;

        &.textarea {
            margin-bottom: 0;
            
            .MuiTextField-root {
                width: 100%;
            }

            textarea {
                margin-bottom: 5px;
            }
        }

        &.MuiFormControl-root {
            width: 100%;
            margin-bottom: 30px;
        }
    } 
}

.bottom-hidden-info small {
    font-size: 13px;
    line-height: 18px; 
    margin-top: 15px;
    margin-bottom: 20px;
    display: none;
}

@media(max-width: 1199px) {
    .contact-banner {
        background-position: center right;
    }
}

@media(max-width: 992px) {
    .contact-form {
        form {
            width: 100%;
        }

        .space-left,
        .space-right {
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    .bottom-hidden-info small {
        display: block;
    }
}

@media(max-width: 767px) {
    .contact-banner.bg6 {
        min-height: auto;
        background: #F0F0F0;
        
        h4.clr-white {
            display: none;
        }

        h2.clr-white {
            color: #000;
            font-size: 32px;
            margin-top: 90px;
            margin-bottom: 30px;
        }
    }
    
    .contact-form {
        padding-left:0px;
        padding-right: 0px;
         
        textarea {
            margin-bottom: 10px;
            height: 170px;
        }

        .g-recaptcha {
            margin-bottom: 30px;
        }

        h2 {
            font-size: 32px;
            margin-bottom: 40px;
        }

        h6 {
            color: #fff;
            font-size: 20px;
            margin-bottom: 30px;
        }

        .space-left ,
        .space-right {
            padding-right: 20px;
            padding-left: 20px;
        }
    }
}