.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

.col-xs-15 {
    width: 20%;
    float: left;
}
@media (min-width: 768px) {
.col-sm-15 {
        width: 20%;
        float: left;
    }
}
@media (min-width: 992px) {
    .col-md-15 {
        width: 20%;
        float: left;
    }
}
@media (min-width: 1200px) {
    .col-lg-15 {
        width: 20%;
        float: left;
    }
}



.responsive-tabs {
    margin: 50px 0;
    .nav-tabs {
        display:none;
    }
    h5 {
        margin-bottom: 32px;
    }
    .main-tabs {
        width: calc(100% + 15px);
        border: 0;
        li.nav-item {
            margin: 0;    
            width: calc(20% - 12px);
            margin-right: 12px;
            text-align: center;
            margin-bottom: 12px;        

            // customize width for additional items
            // &:nth-child(6),
            // &:nth-child(7),
            // &:nth-child(8),
            // &:nth-child(9) {
            //     width: calc(25% - 12px);
            // }
        }
    
        .nav-link {
            border: 0;
            border-radius: 0;
            margin: 0;
            display: block;
            min-height: 112px;
            background: $nab-tab-bg;
            font-size: 13px;
            font-weight: 700;
            color: #003B5C;
            text-transform: uppercase;  
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;     
            height: 100%;
            span {
                display: block;
                line-height: 17px;
                width: 100%;
                margin-top: 0px;
                position: relative;
                bottom : 5px;

            }

            samp {
                display: block;
                width: 100%;
                font-family: inherit;
                font-size: inherit;
            }

            img {
                margin-bottom: 12px;
                max-width: 30px;
                max-height: 30px;
            }

            &.active {
                background: #003B5C;
                color:#fff;
                img {
                    filter : brightness(0) invert(1);
                   
                }
            }
        }
    }

    

    .card-body {
        padding: 0;
        h3 {
            margin-bottom: 40px;
        }
    }
}


.responsive-tabs {
   
    .vertical-tabs {
        position: relative;
        float: left;
        width: 100%;
        margin-top: 52px;

        ul.sub-tabs {
            float: left;
            width: 445px;
            border: 0;
            border-right: 2px solid #f0f0f0;
            padding-top: 80px;
            li {
                clear: both;
                float: left;
                width: 380px;
                margin: 0;
                a.nav-link {
                    border: 0;
                    border-radius: 0;
                    font-size: 20px;
                    //font-family:$gotham-book-font; 
                    font-weight: 400;
                    color:#000;
                    padding: 15px 24px;
                    margin: 3px 0;
                    &.active {
                        background : #f0f0f0;
                        font-weight: 700;
                    }
                }
            }
            
        }

        .tab-content {
            float: left;
            width: calc(100% - 445px);
        }
    }

    .sub-content-block {
        .card {
            position: static;
        }
        h2 {        
            //font-family: $gotham-book-font; 
            font-weight: 400;
            margin-bottom: 40px;   

        }
        h4 {          
            position: absolute;
            left: 0;
            top: 0;
            width: 460px;         
            padding-left: 24px;
        }

        li {
            font-size: 20px;         
            list-style-type: none;
            position: relative;
            margin-bottom: 32px;
            line-height: 27px;
            &:before  {
                content: "";
                position: absolute;
                left: -30px;
                top: 4px;
                border-left: 10px solid #003b5c;
                border-top: 6px solid transparent;
                border-bottom: 6px solid transparent;
            }
        }

        p {
            font-size: 20px;
        }

        .card-body {
            padding-left: 64px;
            padding-right: 24px;
        }
    }
}
@media(min-width:993px) {
    .responsive-tabs {
        .nav-tabs {
            display: flex;
        }
        
        .card {
            border: none;
        }

        .card .card-header {
            display:none;
        }  

        .card .collapse{
            display:block;
        }
    }
}



@media(max-width: 1199px) {
    .responsive-tabs {
        .vertical-tabs {
            ul.sub-tabs {
                width: 360px;
                li {
                    width: calc(100% - 30px);
                }
            }

            .tab-content {
                float: left;
                width: calc(100% - 360px);
            }
        }
    }
}

@media(max-width:992px){
   

    .responsive-tabs {
        .tab-content > .tab-pane {
            display: block !important;
            opacity: 1;
        }

        .vertical-tabs {
            border-bottom: 2px solid #E6E6E6;
            margin-bottom: 10px;
            ul.sub-tabs {
                width: 100%;               
                display: block;                
                padding: 9px 0 20px 0;                   
                border-bottom: 2px solid #f0f0f0;
                margin-bottom: 40px;
                border-right: 0;
              
                li {
                    width: 100%;
                    a.nav-link {
                        font-size: 18px;
                        text-transform: none;
                        font-weight: 800;
                        display: block  ;
                        background: none;
                        padding: 10px 10px;
                        margin-bottom: 0px;         
                        &.active {
                            background: #F0F0F0;
                        }
                    }
                }
            }
        }
        .card {
            background-color: transparent;
            clear: both;
        }
    }
    

    .responsive-tabs {
        h6 {
            display: none;
        }
        .sub-content-block {
            .card-body {
                padding-left: 0;
                padding-right: 0;

            }
            h4 {
                position: static;                 
                margin-bottom: 16px;
                width: auto;
                padding: 0;
            }

            h3 {            
                margin-bottom : 40px;
            }

            .card-header {                
                padding-left: 0;
                padding-bottom: 0;
                margin-bottom: 0;
                padding-top: 0;
                border: 0;
                display: none;
                h5 {
                    a {
                        font-size: 18px;
                        text-transform: none;
                        font-weight: 800;
                        display: block  ;
                        background: #F0F0F0;
                        padding: 10px 10px;
                        margin-bottom: 5px;                        
                        &.collapsed {
                            background: none;
                        }
                    }
                }
            }

        }

        .vertical-tabs {
            margin-top: 0;
            .tab-content {
                float: left;
                width: 100%;
            }

            
           
        }

        .card-header:first-child {
            border-radius: 0;
        }

        .active {
            .card-header {
                &:after {
                    display: none;
                }
            }
        }
        .card-header {
            padding-left: 0px;
            padding-right: 12px;
            border-radius: 0;
            border-bottom: 2px solid #E6E6E6;
            background-color: transparent;
            padding-bottom: 20px;
            margin-bottom: 10px;
            background-color: transparent;
           

            .collapsed {
                
                &:before {
                    content: "";
                    height: 2px;
                    width: 16px;
                    background: #da291c;
                    position: absolute;
                    right: -1px;
                    top: 8px;
                    pointer-events: none;
                }
                &:after {
                    content: "";
                    width: 2px;
                    height: 15px;
                    background: #da291c;
                    position: absolute;
                    right: 6px;
                    top: 2px;
                    pointer-events: none;
                }
            }
            h5 {
                img {
                    filter: grayscale(1);
                    max-height: 36px;
                    max-width: 36px;
                }
                a {
                    position: relative;
                    padding-left: 60px;
                    display: block;
                    font-size: 15px;
                    color: #000;
                    text-transform: uppercase;

                    &:before {
                        content: "";
                        height: 2px;
                        width: 16px;
                        background: #da291c;
                        position: absolute;
                        right: -1px;
                        top: 8px;
                        pointer-events: none;
                    }
                }

                samp {
                    display: block;
                    width: 36px;
                    position: absolute;
                    left: 12px;
                    text-align: center;
                    transform: translate(0,-50%);
                    top: 50%;
                    img {
                        max-width: 36px;
                        max-height: 36px;
                    }
                }
                
            }
        }
        .card {
            border: 0;
            border-radius: 0;
           
        }

        
    }


    .tab-content.sub-content-block {
        .tab-pane {
            display: none !important;
            &.active {
                display: block !important;

                .collapse {
                    display: block !important;
                }
            }
        }
    }

}


@media(max-width: 767px) {
    .responsive-tabs {
        margin: 10px 0;

        .sub-content-block {
            .card-body {
                padding-bottom: 20px;
            }
            h3 {
                margin-bottom: 20px;    
            }
            ul {
                padding-left: 30px;
                margin-bottom: 0px;
            }
            li {
                font-size: 18px;
                line-height: 27px;
                &:before {
                    top: 7px;
                    left: -25px;
                }
            }

            p {
                font-size: 18px;
            }
        }

        
    }
}