.imapct-right-trends {
    margin: 0 0 40px 0;
    .graph {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: space-between;
  
      .line {
        display: block;
        width: 44px;
        height: 4px;      
        margin-bottom: 16px;
      } 
  
      h1 {
        color: #fff;
        font-size: 56px;
        @media(max-width: 767px) {
          font-size: 32px;
        }
  
        span {
          font-size: 16px;
          font-weight: 700;
          display: block;
          padding: 0 15px;
        }
      }
  
      &.orange {
        .line {
          background: #FF7F32;
        }
  
        .graph-icon {
          .ring {
            border: 1px solid #FF7F32;
          }
  
          .circel {          
            background: url("../images/orange-circel.png")no-repeat;
            background-size: cover;
          }
        }
      }
      &.blue {
        .line {
          background: $d-blue3;
        }
        .graph-icon {
          .ring {
            border: 1px solid #003B5C;
          }
  
          .circel {
            background: url("../images/blue-circel.jpg")no-repeat;
            background-size: cover;
          }
        }
      }
  
      &.small {
        
        .graph-icon {
          position: relative;
          height: 320px;
          width: 400px;
          display: flex;
          align-items: center;
          justify-content: center;
    
          .ring {
            height: 320px;
            width: 320px;
            border-radius: 50%;         
            
            position: absolute;
            
          }
    
          .circel {
            height: 185px;
            width: 185px;
            border-radius: 50%;          
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            z-index: 3;
            position: relative;
          }
    
          
        }
        
      }
      &.medium {
        
    
        .graph-icon {
          position: relative;
          height: 400px;
          width: 400px;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 2;
    
          .ring {
            height: 400px;
            width: 400px;
            border-radius: 50%;
            border: 1px solid #003B5C;
            left: 0;
            top: 0;
            position: absolute; 
            z-index: 2;
          }
    
          .circel {
            height: 280px;
            width: 280px;
            border-radius: 50%;
           
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
          }
    
          h4 {
            color: #fff;
            span {
              font-size: 16px;
              font-weight: 700;
              display: block;
              padding: 0 20px;
            }
          }
        }
        
      }
  
      .graph-info {
        width: 180px;   
        p {
          color: $n-blue;
          font-size: 16px;
          line-height: 24px;
        }   
  
        
      }
    }
  
  
    &.portrait {
      display: flex;
      width: 400px;
      float: right;
      .graph {
        display: block;
        width: 50%;
      
  
        .graph-icon {
          width: 100%;
          height: 268px;
          margin-bottom: 40px;
        }
  
        .graph-info {
          width: 100%;
        }
  
        &.small {
          .graph-icon {
            .ring {
              width: 268px;
              height: 268px;
            }
          }
  
          h3 {
            color: #fff;
            font-size: 40px;
            @media(max-width: 767px) {
              font-size: 32px;
            }
          }
        }
  
        &.orange {
          position: relative;
          right: 35px;  
          .circel {
            width: 144px;
            height: 144px;
          }
          .graph-info {
            position: relative;
            right: -55px;   
          }
        }
  
        &.blue {
          position: relative;
          right: -30px;
          .circel {
            width: 168px;
            height: 168px;
            z-index: 4;
          }
          .graph-info {
            position: relative;
            left: -10px;
            padding-right: 24px;
          }         
        }
      }
    }    
}

@media(max-width: 767px) {
  .-impact-right-trends {
    .impact-head {
      margin-bottom: 65px;
    }
  }
  
  .imapct-right-trends {
    display: flex;
    margin-bottom: 96px;
    
    .graph {
      display: block;
      width: 50%;
      .graph-info p {        
        font-size: 13px;
        line-height: 18px;
      }
      .line {      
        width: 32px;
        height: 3px;      
      }
      h1 {
        span {
          font-size: 10px;
        }
      }
      &.small {
        .graph-icon {
          left: -30px;
          height: 210px;
          width: 210px;
          .circel {
            width: 115px;
            height: 115px;
          }

          .ring {
            height: 210px;
            width: 210px;
          }
        }

        .graph-info {
          width: 100%;
          padding-right: 10px;
          margin-top: 55px;
        }
      }

      &.medium {
        margin-top: 0 !important;
        .graph-icon {
          left: -50px;
          height: 210px;
          width: 210px;
          .circel {
            width: 175px;
            height: 175px;
          }

          .ring {
            height: 260px;
            width: 260px;
            left: -25px;
            top: -25px;
          }
        }

        .graph-info {
          width: 100%;
          padding-left: 10px;
          margin-top: 55px;
        }
      }

      
    }
  }

  .imapct-right-trends.portrait {
    width: 100%;
    margin-top: 54px;
    h3 {
      font-size: 35px;
    }

    .graph {
      .graph-info {
        p {
          font-size: 13px;
          line-height: 21px;
        }
      }
      .graph-icon {
        margin-bottom: 32px;
      }
    }
    .graph.small {
      .graph-info {
        margin-top: 0;
      }
      .graph-icon {
        .ring {
          width: 210px;
          height: 210px;
        }
      }
    }
    .graph.small.orange {
      right: 15px;
      .graph-icon {
        .circel {
          width: 125px;
          height: 125px;
        }
      }

      .graph-info {
        right: -25px;
      }
    }
    .graph.small.blue {
      right: -20px;
      .graph-icon {
        .circel {
          width: 150px;
          height: 150px;
        }
      }
    }
  }
}