.banner-solid-bg {
    &.banner-style-1 {
        background-color: #003b5b;
        background-image: url("../images/about-left-banner-icon.png"), url("../images/about-right-banner-icon.png");
        background-repeat: no-repeat, no-repeat;
        background-position: left top, right top;
		}
		
		.banner-info {
			max-width: 750px;
			display: inline-block;
		}

		&.bottom-padding {
			@media(max-width: 767px) {
				padding-bottom: 95px;
			}
		}
}


.banner-solid-bg {
	h1 {
		margin-bottom: 12px;
	}
	h6 {
		margin-top: 0px;
		a {
			color: inherit;
			text-decoration: underline;
		}
	}

	p {
		small {
			color: inherit;
		}
	}
	small {
		font-size: 13px;
	    color: #fff;
	    margin-top: 0px;
	    line-height: 20px;
	    a {
	    	color: #fff;
	    	text-decoration: underline;
	    }
	}

	ul {
		padding: 0;
		display: inline-block;
	}
	li {
		a {
			color: inherit;
		}
	}

	p {
		a {
			color: inherit;
		}
	}
}

.banner-with-solid-bg {
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
		position: relative;
		&::after {
		content: "";
		background: url(../images/layer.png);
		position: absolute;
		bottom: 0px;
		height: 20px;
		width: 100%;
		background-repeat: repeat-x;
		@media(max-width: 767px) {
			background-size: 1000px;
			height: 10px;
			
		}
	}
} 


/*Contact Banner*/
.banner-with-solid-bg.contact-banner {    	
	h2 {
		margin-bottom: 15px;
	}
}

@media(max-width: 767px) {
    .banner-solid-bg {
        &.banner-style-1 {
            background: #003b5c url("../images/svg/mobile-contact-bg.svg") no-repeat;
            background-size: 100%;
        }
    }

    	
	.banner-with-solid-bg  {
		h6 {
			max-width: 310px;
            margin: 0 auto;
            margin-top: 6px;
		}
	}
}