// .bg1 {
//    background-image: url("../images/banner.png");
// }

// .bg2 {
//    background-image: url("../images/banner2.png");
// }

// .bg3 {
//    background-image: url("../images/banner3.jpg");
// }

// .bg4 {
//    background-image: url("../images/star-banner.jpg");
// }

// .bg5 {
//    background-image: url("../images/bg-new.jpg");
// }
// .bg6 {
//    background-image: url("../images/contact-banner.png");
// }


.user-name-block {
	display: flex;
	position: absolute;
	right: 0;
	bottom: 17px;
	z-index: 1;

	.name {
		background: #F6F4EB;
		padding: 5px 10px;
		font-size: 13px;
		color: #000;
	}

	.icon {
		background: #F6F4EB;
		padding: 1px 10px;
		font-size: 17px;
		color: #000;
		display: flex;
		align-items: center;
		text-align: center;
		margin-left: 5px;
	}
}


.follow-our-chanel-block {
	&.half-top-reverse {
		margin-top: -40px;
		margin-bottom: 0;
	}

	.follow-inner {
		background: #007473;
		min-height: 80px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 4px;
		margin-bottom: 20px;

		.social-items-title,h4 {
			font-size: 22px;
			font-weight: 700;
			color: #fff;
			margin-bottom: 0;
			margin-right: 30px;
			line-height: 1.2;
		}

		.social-block ul li a {
			color: #fff;
		}
	}
}


.half-top-reverse {
	position: relative;
	margin-top: -55px;
	margin-bottom: -40px;
	z-index: 2;
}



.pad-lr-10 {
	padding-left: 10px;
	padding-right: 10px;
}

.custom-row {
	margin-left: -10px;
	margin-right: -10px;
}









.full-width-media-layer {
	position: relative;

	.desktop-bg {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background-size: 100%;
		background-position: center top;
		background-repeat: no-repeat;

		@media(max-width: 767px) {
			background-position: center top;
			display: none;
		}
	}

	.mobile-bg {
		display: none;

		@media(max-width: 767px) {
			display: block;
			position: absolute;
			left: 0;
			bottom: 0;
			height: 100%;
			width: 100%;
			background-size: 100%;
			background-position: center top;
			background-repeat: no-repeat;
			z-index: 1;
		}
	}


}
.common-card-inner:hover ::-webkit-scrollbar-track {
	opacity: 1;
}
.common-card-inner {

  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.1);
}

/* Works on Chrome, Edge, and Safari */
.common-card-inner::-webkit-scrollbar {
	width: 12px;	 
 }

.common-card-inner::-webkit-scrollbar-track {
	background: rgba(0, 0, 0, 0.1); 
}

.common-card-inner::-webkit-scrollbar-thumb {
  background-color:  rgba(0, 0, 0, 0.1);
  border-radius: 20px;
	border: 3px solid rgba(0, 0, 0, 0.3); 
}

.common-heading-block {
	position: relative;
	z-index: 1;

	img {
		margin-top: 30px;
		margin-bottom: 15px;

		@media(max-width: 767px) {
			margin-top: 40px;
		}
		@media(min-width: 1600px) {
			margin-top: 40px;
		}
	}

	.heading-inner {
		max-width: 725px;
		margin: 0 auto 30px;

		p {
			margin: 0px 0 24px 0px;
			display: inline-block;
			max-width: 590px;
		}

		h2 {
			margin: 0 0 12px 0;
		}

		h6 {
			margin-top: 0px;
			margin-bottom: 12px;
		}
	}

	&.move-top-minus {
		margin-top: -8%;

		@media(max-width: 992px) {
			margin-top: -5%;
		}

		@media(max-width: 767px) {
			margin-top: 0%;
		}

	}
}


.two-column-vertical-list {
	.vertical-list-wrap {
		margin-bottom: 34px;

		.media-info {
			padding-left: 2px;
			padding-top: 24px;

			.column-card-title {
				margin-bottom: 16px;

				@media (max-width:767px) {
					font-size: 17px;
				}
				font-size: 20px;
				font-weight: 700;
				@media (max-width: 767px) {
					font-size: 18px; 
				}
				
				display: inherit !important;
			}

			h3,h6 {
				margin-bottom: 16px;

				@media (max-width:767px) {
					font-size: 17px;
				}
			}
	
			h3 {
				font-size: 20px;
				font-weight: 700;
				@media (max-width: 767px) {
					font-size: 18px; 
				}
			}

			p {
				margin-bottom: 16px;
			}

			@media (max-width:767px) {

				p,
				a {
					display: none;
				}
			}
		}

		.media-block {
			img {
				width: 100%;
				object-fit: cover;
				height: auto;

				@media (min-width:1200px) {
					height: 273.75px;
				}

				@media (min-width:992px) and (max-width:1199px) {
					height: 210px;
				}
			}
		}

		.mob-media-info {
			@media (min-width:768px) {
				display: none;
			}

			@media (max-width:767px) {
				display: block;
				margin-top: 16px;

				p {
					margin-bottom: 20px;
				}
			}
		}

		.tag-style {
			@media (max-width:767px) {
				margin-bottom: 24px;
			}
		}

		.space-right,
		.space-left {
			@media(min-width: 993px) {
				display: flex;

			}
		}

	}



	@media(max-width: 767px) {
		.space-left {
			padding-left: 7.5px;
		}

		.space-right {
			padding-right: 7.5px;
		}
	}
}







.mosaic-block {
	.top-block {
		padding: 0px 0px;


		.logo-media {
			text-align: center;

			@media(max-width: 767px) {
				text-align: left;
			}

			img {
				max-width: 100%;
				width: auto;
				height: auto;
				object-fit: contain;
			}
		}

		.col-md-3 {
			text-align: center;

			@media(max-width: 767px) {
				text-align: left;
			}
		}

		.social-row {
			display: inline-block;
			text-align: left;
		}

		.social-block {
			text-align: left;
			margin-top: 15px;

			ul {
				li {
					&:first-child {
						margin-left: 0;
					}

					a {
						color: #007473;
					}
				}
			}
		}

		h6 {
			font-size: 16px;
			color: #007473;
		}

		p {
			font-size: 14px;
		}
	}

	.masonry-block {
		.masonry-listing {
			-moz-column-count: 5;
			-moz-column-gap: 16px;
			-moz-column-width: 20%;
			-webkit-column-count: 5;
			-webkit-column-gap: 16px;
			-webkit-column-width: 20%;
			column-count: 5;
			column-gap: 16px;
			column-width: 20%;
			padding: 0;
			margin-bottom: 50px;

			.masonry-item {
				list-style: none;
				margin-bottom: 16px;

				@media (max-width:767px) {
					margin-bottom: 12px;
				}

				img {
					width: 100%;
				}

				.small-media {
					@media (min-width:1200px) {
						height: 257px;
						object-fit: cover;
					}
				}
			}

			@media (max-width:767px) {
				-moz-column-count: 2;
				-moz-column-gap: 12px;
				-moz-column-width: 50%;
				-webkit-column-count: 2;
				-webkit-column-gap: 12px;
				-webkit-column-width: 50%;
				column-count: 2;
				column-gap: 12px;
				column-width: 50%;
			}
		}

		.desktop-masonry-listing {
			@media (max-width:767px) {
				display: none;
			}

			.small-media {
				@media (min-width:768px) and (max-width:991px) {
					min-height: 141px;
				}
			}
		}

		.mob-masonry-listing {
			@media (min-width:768px) {
				display: none;
			}

			@media (max-width:767px) {
				.large-media {
					height: 228px;
					object-fit: cover;
				}

				.small-media {
					height: 182px;
					object-fit: cover;
				}
			}
		}
	}
}


.two-column-card-layout {
	margin: 50px 0px;

	.verical-list-wrap {
		@media (max-width:767px) {
			margin-bottom: 24px;
		}
		.tag-style {
			font-size: 80px;
			margin: 24px 0 0 0;
		}

		.horizontal-list-item-title {
			margin:16px 0px 16px;
			font-size: 20px;
    		font-weight: 700;
			@media (max-width: 767px) {
				font-size: 18px; 
			}
			line-height: 1.2;
		}
		
		h3, h6 {
			margin:16px 0px 16px;
		}

		h3 {
			font-size: 20px;
    		font-weight: 700;
			@media (max-width: 767px) {
				font-size: 18px; 
			}
		}

		p {
			margin-bottom: 20px;
		}

		.media-row {
			img {
				width: 100%;
				object-fit: cover;

				@media (min-width:1200px) {
					height: 385px;
				}

				@media (min-width:992px) and (max-width:1199px) {
					height: 300px;
				}

				@media (min-width:768px) and (max-width:991px) {
					height: 220px;
				}
			}
		}
	}

	.col-md-6 {
		padding: 0 16px;

		@media(max-width: 767px) {
			padding: 0 20px;
		}
	}

	&.four-cols {
		.gallery-head {
			display: flex;
			justify-content: space-between;
			margin-bottom: 32px;
			margin-top: 0px;

			h4 {
				margin: 0;
			}

			p {
				font-weight: 700;
				margin: 0;

				i {
					font-size: 24px;
					margin-left: 12px;
					position: relative;
					top: 4px;
				}
			}
		}

		.verical-list-wrap {
			.media-row {
				img {
					height: auto;
				}
			}

			h5 {
				margin: 16px 0 40px 0;
				font-weight: 700;
				font-size: 16px;
			}
		}

		@media(max-width: 767px) {
			.gallery-head {
				display: block;
				margin-bottom: 24px;
				border: 0;
				padding-top: 0;

				h4 {
					margin-bottom: 16px;
				}
			}

			.container {
				.row {
					margin: 0 -8px;
				}

				.col-12,
				.col-6 {
					padding: 0 8px;
				}
			}

			.verical-list-wrap {
				h5 {
					margin: 12px 0 24px 0;
				}
			}
		}
	}

	// &.five-cols {
	// 	.col-5 {
	// 		margin: 0 16px 40px 16px;
	// 		width: calc(20% - 32px);
	// 		text-align: center;
	// 		padding: 0;
	// 		img {
	// 			max-width: 100%;
	// 			display: inline-block;
	// 		}
	// 	}

	// 	@media(max-width: 767px) and (orientation: landscape){
	// 		.col-5 {
	// 			width: calc(33.33% - 16px);
	// 		}
	// 	}

	// 	@media(max-width: 767px) {
	// 		.row {
	// 			margin: 0 -8px;
	// 		}
	// 		.col-5 { 
	// 			width: calc(50% - 16px);
	// 			margin: 0 8px 25px 8px;
	// 		}
	// 	}


	// }
}


@media(min-width: 993px) {
	.two-column-card-layout {
		.container {
			padding: 0 16px;

			.row {
				margin: 0 -16px;
			}

			.col-md-6 {
				padding: 0 16px;
			}
		}
	}

	.two-column-vertical-list {
		.container {
			padding: 0 16px;

			.row {
				margin: 0 -16px;
			}

			.col-md-6 {
				padding: 0 16px;
			}
		}
	}
}

@media(max-width: 767px) {
	.two-column-card-layout {
		margin: 24px 0 0 0;
	}
}

.pagination-listing {
	padding: 0;
	margin: 25px 0px;
	text-align: center;
	width: 100%;

	li {
		list-style: none;
		display: inline-block;
		margin: 0 9px;
		cursor: pointer;

		a {
			color: #000;
			font-size: 15px;
			font-weight: 700;

			&:hover {
				text-decoration: none;
			}
		}

		&.active {
			a {
				background: #004E7D;
				color: #fff;
				padding: 5px 10px;
				border-radius: 50%;
			}
		}

		&.previous {
			a {
				display: inline-block;
				height: 10px;
				width: 10px;
				position: relative;
				text-indent: -9999px;

				&:before {
					content: "\f104";
					display: inline-block;
					font: normal normal normal 14px/1 FontAwesome;
					font-size: 14px;
					font-size: inherit;
					text-rendering: auto;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
					position: absolute;
					left: 0;
					text-indent: 0;
					top: 5px;
				}
			}
		}

		&.next {
			a {
				display: inline-block;
				height: 10px;
				width: 10px;
				position: relative;
				text-indent: -9999px;

				&:before {
					content: "\f105";
					display: inline-block;
					font: normal normal normal 14px/1 FontAwesome;
					font-size: 14px;
					font-size: inherit;
					text-rendering: auto;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
					position: absolute;
					left: 0;
					text-indent: 0;
					top: 5px;
				}
			}
		}
	}
}


.two-column-card-without-layer {
	padding: 90px 0px 90px;

	&::before {
		height: 0;
		opacity: 0;
	}
}


.map-block {
	min-height: 600px;

	@media (min-width:768px) {
		display: flex;
	}

	.sidebar-address-block {
		padding: 30px 35px;

		@media (min-width:768px) {
			display: inline-block;
			width: 33%;
			max-height: 600px;
			overflow: auto;
		}

		.location-textfield-block {
			p {
				font-size: 13px;
				color: #656565;
				margin: 15px 0px;
				line-height: 16px;

				a {
					color: #656565;
					;
					text-decoration: underline;
				}
			}
		}
	}

	.wide-map-block {
		@media (min-width:768px) {
			display: inline-block;
			width: 67%;
		}

		img {
			width: 100%;

			@media (min-width:768px) {
				height: 600px;
				object-fit: cover;
			}
		}
	}

	@media (max-width:767px) {
		display: none;
	}

	&.mob-map-block {
		display: none;

		@media (max-width:767px) {
			display: block;
		}

		.nav-tabs {
			justify-content: space-evenly;

			.nav-item {
				width: 50%;
				text-align: center;

				a {
					background: #fff;
					color: #000;
					font-size: 16px;
					font-weight: 700;

					&.active {
						background: #ccc;
					}

					img {
						margin: -2px 3px 0 0;
					}
				}
			}
		}

		.tab-content {
			.map-media {
				width: 100%;
				min-height: 500px;
				object-fit: cover;
			}
		}

		.location-textfield {
			padding: 15px;
		}

		.sidebar-address-block {
			padding: 15px;
		}

		.location-textfield-block {
			padding-bottom: 15px;
		}
	}
}


body .textfield-wrap-with-btn {
	position: relative;

	input {
		height: 40px;
		border: 2px solid #a8a8a8;
		border-radius: 4px 0px 0px 4px;
		font-size: 15px;
		padding-left: 20px;
		width: 100%;
	}

	.btn-primary {
		position: absolute;
		right: 0;
		top: 0;
		height: 40px;
		border-radius: 0px 4px 4px 0px;
		line-height: 16px;
	}
}


.address-box {
	margin-bottom: 15px;
	min-height: 295px;
	background: #fff;
	border-radius: 4px;
	padding: 20px;
	border: 2px solid #707070;

	.logo-row {
		margin-bottom: 12px;
	}

	.location-name {
		display: flex;
		justify-content: space-between;

		h6 {
			font-size: 16px;
			margin: 0;
		}

		.distance {
			font-size: 12px;
			color: #7D7979;
		}
	}

	.location-name+p {
		font-size: 14px;
	}

	p {
		margin: 12px 0px;
	}

	.opening-time {
		text-transform: uppercase;
	}

	.directions-links {
		a {
			font-size: 14px;
			text-transform: uppercase;
			font-weight: 700;
			color: #000;
			text-decoration: underline;
			margin: 0 15px;

			&:first-child {
				margin-left: 0;
			}
		}
	}

	.donation-text {
		color: #656565;

		a {
			color: #656565;
			text-decoration: underline;
		}
	}
}

.item-three {
	.common-card {
		.bottom-links-row {
			position: absolute;
			width: calc(100% - 60px);
			bottom: 40px;
		}
	}
}
.item-two {
	.slick-slider {
		margin: 0 -16px;

		.slick-arrow.slick-prev {
			left: -47px;
		}

		.slick-arrow.slick-next {
			right: -47px;
		}
	}

	.common-card {
		min-height: 346px;
		display: block;
		margin-bottom: 0;

		h3 {
			min-height: 100px;
		}

		.bottom-links-row {
			position: absolute;
			width: calc(100% - 60px);
			bottom: 40px;
			display: flex;
			align-items: flex-end;
			justify-content: space-between;

			img {
				margin-top: 0;
			}
		}
	}
}

@media(max-width: 767px) {
	.item-two {
		.slick-slider {
			margin: 0;
		}

		.common-card {

			.bottom-links-row {
				bottom: 30px;

				img {
					margin-top: 0;
					margin-bottom: 10px;
				}
			}
		}
	}
}

.common-card {
	height: 430px;
	border-radius: 4px;
	padding: 40px 32px 0px 32px;
	margin-bottom: 40px;
	background-position: left bottom;
	background-size: 100%;
	background-repeat: no-repeat;
	display: flex;
	flex-wrap: wrap;
	position: relative;

	.tag-style.style2 {
		margin-bottom: 12px;
	}

	.common-card-inner {
		height: calc(100% - 110px);
		overflow-y: auto;
		overflow-x: hidden;
		width: 100%;
	}

	h5 {
		margin-bottom: 12px;
	}

	@media(max-width: 1199px) {
		margin-bottom: 30px;
	}

	@media(max-width: 767px) {
		padding: 32px 30px 00px 30px;
	}


	.bottom-links-row {
		position: static;
		width: 100%;

		img {
			float: right;
			margin-top: 15px;
			width: auto !important;
		}

		a {
			float: left;
		}
	}

	h5 {
		margin-bottom: 12px;
	}

	h5,
	p,
	.clr-whihte,
	.clr-dgreen2 {
		color: inherit;
	}

	&.card-with-black-color {

		h5,
		p,
		a {
			color: #000;
		}


		a {
			border-color: #000;

			&:hover {
				color: #fff;
			}
		}
	}

	@media (max-width:767px) {
		margin-bottom: 15px;
	}

	&.small-box {
		min-height: 355px;

		@media (min-width:1200px) {
			padding-left: 44px;
			padding-right: 44px;
		}
	}

	.br-fixed-layer {
		position: absolute;
		right: 0;
		bottom: 0;
		width: auto !important;
	}

	.layer-fixed-right {
		position: absolute;
		top: 0;
		right: 0;
	}

	&.media-box {
		padding: 0;

		img {
			width: 100%;
			object-fit: cover;
			border-radius: 4px;
			height: 430px;
		}
	}
}

@media(max-width: 992px) {
	.common-card {
		.common-card-inner {
			height: calc(100% - 43px);
		}
	}
}

@media(max-width: 767px) {
	.common-card {
		.common-card-inner {
			height: calc(100% - 31%);
		}
	}
}

@media(max-width: 767px) and (orientation: landscape) {
	.common-card {
		background-image: none !important;
		min-height: 300px;
		height: auto;
	}
}

.common-boxes-wrap {
	@media (min-width:768px) {
		padding: 0 25px;
	}
}


.timing-listing {
	ul {
		li {
			list-style: none;
		}
	}
}


.location-info {
	.direction-links {
		a {
			margin-right: 10px;
		}
	}

	.location-media.location-media-mob {
		@media (min-width:768px) {
			display: none;
		}
	}
}


.store-location-block {
	@media (max-width:767px) {
		.location-media {
			display: none;

			&.location-media-mob {
				display: block;
			}
		}
	}
}

.parking-list {
	padding: 0;

	li {
		float: left;
		margin: 0 10px;
		width: calc(33.3% - 20px);
		text-align: center;
		list-style: none;
		background: #f0f0f0;
		min-height: 95px;
		border-radius: 4px;
		font-size: 12px;
		line-height: 18px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 10px 5px;
		font-weight: 700;

		&:first-child {
			margin-left: 0;
		}
	}

	@media(max-width: 767px) {
		float: left;
		width: 100%;

		li {
			width: calc(33.3% - 14px);
		}
	}
}

.payment-row {
	p {
		margin-bottom: 10px;
	}
}

.media-slider {
	.item {
		img {
			width: 100% !important;
		}
	}

	.owl-nav {
		button.owl-prev {
			left: 15px;
			top: 50%;
		}

		button.owl-next {
			right: 15px;
			top: 50%;
		}
	}
}

.location-media {
	img {
		width: 100%;

		@media (min-width:1200px) {
			height: 556px;
			object-fit: cover;
		}
	}
}


.store-near-by {
	padding: 70px 0px;

	.address-box {
		border: none;
		margin-bottom: 0;
	}
}

.facility-wrap {
	margin: 0 -23px;

	@media(max-width: 767px) {
		margin: 0 -15px;
	}

	ul {
		padding: 0;
		margin: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		li {
			list-style: none;
			width: calc(20% - 32px);
			display: inline-block;
			margin: 0 16px;
			text-align: center;
			vertical-align: top;

			@media (max-width:767px) {
				width: 23.3%;
			}

			.inner-wrap {
				display: inline-block;
				position: relative;
			}

			p {
				min-height: 36px;
				margin-top: 5px;
			}
			
			.learn-more {
				display: flex;
				width: 82px;
				height: 82px;
				background: #004E7D;
				color: #fff;
				border-radius: 50%;
				text-transform: uppercase;
				align-items: center;
				font-size: 14px;
				line-height: 20px;
				font-weight: 700;
				justify-content: center;
			}
		}
	}

	.mob-title {
		position: relative;

		&::after {
			content: "\f106";
			font-family: 'FontAwesome';
			position: absolute;
			right: 0;
			transition: 0.3s all;
		}

		&.open {
			&::after {
				transform: rotate(180deg);
			}
		}

		@media (min-width:768px) {
			display: none;
		}

		@media (max-width:767px) {
			display: block;
			font-size: 26px;
		}
	}

	h4 {
		@media (max-width:767px) {
			display: none;
		}
	}
}


.non-profit-block {
	.mob-title {
		position: relative;

		&::after {
			content: "\f106";
			font-family: 'FontAwesome';
			position: absolute;
			right: 0;
			transition: 0.3s all;
		}

		&.open {
			&::after {
				transform: rotate(180deg);
			}
		}

		@media (min-width:768px) {
			display: none;
		}

		@media (max-width:767px) {
			display: block;
			font-size: 26px;
		}
	}

	h4 {
		@media (max-width:767px) {
			display: none;
		}
	}

}

.facilities-block .row {
	flex-direction: row-reverse;
}


.pb-200 {
	padding-bottom: 200px;

	@media (max-width:767px) {
		padding-bottom: 150px !important;
	}
}

.m-rev-135 {
	margin-top: -135px;
}

.store-offer {
	.mob-title {
		position: relative;

		&::after {
			content: "\f106";
			font-family: 'FontAwesome';
			position: absolute;
			right: 0;
			transition: 0.3s all;
		}

		&.open {
			&::after {
				transform: rotate(180deg);
			}
		}

		@media (min-width:768px) {
			display: none;
		}

		@media (max-width:767px) {
			display: block;
			font-size: 26px;
		}
	}

	h4 {
		@media (max-width:767px) {
			display: none;
		}
	}
}






.media-slider {
	overflow: hidden;
	position: relative;

	.owl-slider {
		width: calc(100% - 100px);

		.owl-stage-outer {
			overflow: visible;
		}
	}

	.owl-carousel {
		position: static;
	}
}





.full-bleed-feature-media {

	img {
		width: 100%;
		height: auto;

		@media (max-width:767px) {
			min-height: 338px;
			object-fit: cover;
		}
	}
}

.three-cols-media {
	.media-wrap {
		img {
			object-fit: cover;
			width: 100%;
			height: auto;
			@media (min-width:1200px) {
				height: 562px;
			}

			@media (min-width:992px) and (max-width:1199px) {
				height: 434.58px;
			}

			@media (min-width:768px) and (max-width:991px) {
				height: 314.72px;
			}
		}

		@media (max-width:767px) {
			margin-bottom: 15px;
		}
	}
}


.longform-text {
	h5 {
		font-size: 22px;
	}
}

.two-cols-media {
	.media-wrap {
		img {
			object-fit: cover;
			width: 100%;

			@media (min-width:1200px) {
				height: 576px;
			}

			@media (min-width:992px) and (max-width:1199px) {
				height: 450px;
			}

			@media (min-width:768px) and (max-width:991px) {
				height: 330px;
			}
		}

		@media (max-width:767px) {
			margin-bottom: 15px;
		}
	}
}


.steps-common-wrap {
	.row {
		align-items: center;
	}

	h6 {
		font-size: 15px;
	}

	p {
		line-height: 28px;
	}

	.descp-block {
		padding: 0 20px;

		@media (max-width:767px) {
			margin-bottom: 45px;
		}
	}

	.media-block {
		img {
			object-fit: cover;
			width: 100%;

			@media (min-width:1200px) {
				height: 433px;
			}

			@media (min-width:992px) and (max-width:1199px) {
				height: 337.5px;
			}

			@media (min-width:768px) and (max-width:991px) {
				height: 247.5px;
			}
		}
	}
}

.text-right-media-left-block {
	.row {
		flex-direction: row-reverse;
	}
}

.text-info-right {
	p {
    	color:inherit;
	}
}
.text-block {
	padding-top: 32px;

	.row {
		position: relative;
		align-items: center;
	}

	img {
		max-width: 100%;
		height: auto;
	}

	a {
		color: inherit;

		&:hover {
			color: inherit;
		}

		&.btn-primary {
			color: #fff;
		}
	}


	&.divider {
		.text-info-left {
			padding-right: 51px;
			//margin-bottom: 48px;
		}

		.text-info-right {
			padding-left: 51px;
			//margin-bottom: 48px;
		}

	}

	.text-info-left {
		padding-top: 32px;
		padding-bottom: 48px;

		@media (min-width:768px) {
			padding-right: 10px;
		}
	}

	.divider-line {
		position: absolute;
		left: calc(50% + 1px);
		top: 0;
		width: 2px;
		height: calc(100% - 48px);
		background: #f0f0f0;
	}

	h4 {
		margin-bottom: 24px;
	}

	.main-title {
		h2 {
			margin: 0;
			padding-bottom: 32px;
		}
	}

	.text-info-right {
		padding-top: 32px;
		padding-bottom: 48px;
	}

	&.text-block-withour-border {
		.text-info-left {
			padding-top: 0;
			padding-bottom: 0;
		}

		.text-info-right {
			border-left: none;
			padding-top: 0;
			padding-bottom: 0;

			p {
				a {
					color: #656565;
					text-decoration: underline;
				}
			}
		}
	}

	&.no-divider {
		padding-top: 0;
		padding-bottom: 0;

		.row {
			align-items: center;
		}

		.divider-line {
			display: none;
		}

		.text-info-right {
			padding-left: 17px;
			border-left: 0;
			padding-bottom: 25px;
		}

		.text-info-left {
			padding-right: 17px;
			padding-bottom: 25px;

			img {
				max-width: 100%;
			}
		}
	}

}

@media(max-width: 767px) {
	.text-block {
		clear: both;

		&.no-divider {
			.text-info-right {
				padding-left: 0;
				padding-top: 0;
				padding-bottom: 0;
			}

			.text-info-left {
				padding-right: 0;
				padding-top: 24px;
				padding-bottom: 16px;
			}
		}

		&.divider {
			.text-info-right {
				padding-left: 0;
				padding-top: 40px;
				margin-bottom: 0;
			}

			.text-info-left {
				padding-right: 0;
				padding-bottom: 40px;
				margin-bottom: 0;
			}
		}

		.main-title {
			h2 {
				padding-bottom: 0
			}
		}

		.divider-line {
			position: static;
			height: 2px;
			width: calc(100% - 32px);
			margin-left: 16px;
		}
	}
}

.common-media {
	img {
		width: 100%;
	}
}

.bullet-listing {
	padding-left: 20px;

	@media (max-width:767px) {
		padding-left: 0;
	}

	li {
		background-image: url("../images/list-icon.png");
		background-repeat: no-repeat;
		list-style: none;
		background-position: left center;
		padding-left: 25px;

		a {
			color: #000;
		}
	}
}


.grid-box {
	.media-row {
		img {
			object-fit: cover;
			width: 100%;

			@media (min-width:1200px) {
				height: 375px;
			}

			@media (min-width:992px) and (max-width:1199px) {
				height: 290px;
			}

			@media (min-width:768px) and (max-width:991px) {
				height: 210px;
			}
		}
	}

	.small-text {
		color: #656565;
	}

	h5 {
		font-size: 22px;
	}

	@media (max-width:767px) {
		margin-bottom: 15px;
	}
}


.related-story-block {
	hr {
		margin-bottom: 50px;
	}
}

.story-page {
	margin-bottom: 35px;

	.find-store-newsletter {
		.newsletter-inner {
			h4 {
				color: white;
			}
		}
	}
}

.next-prevs-slider {
	margin-bottom: 40px;

	.container {
		@media(min-width: 1200px) {
			padding: 0 100px 0 70px;
			border-top: 2px solid #f0f0f0;
			padding-top: 40px;
			margin-top: 40px;
			border-bottom: 2px solid #f0f0f0;
			padding-bottom: 40px;
		}
	}

	.right {
		.inner {
			justify-content: flex-end;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
		}

		.story-info {
			padding-right: 32px;
			text-align: right;
			width: calc(100% - 152px);
		}
	}

	.left {
		.inner {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
		}

		.story-info {
			padding-left: 32px;
			width: calc(100% - 152px);
		}
	}

	h6 {
		font-size: 15px;
		text-transform: uppercase;
		margin-bottom: 12px;

		a {
			text-decoration: none;
			color: inherit;
		}
	}

	p {
		margin: 0;

		a {
			text-decoration: none;
			color: inherit;
		}
	}

	.story-media {
		img {
			width: 152px;
			height: 113px;
			object-fit: cover;

		}
	}

	.slick-arrow {
		&:hover {
			opacity: 0.9;
		}

		&.slick-prev {
			left: -54px;
		}

		&.slick-next {
			right: -69px;
		}

		@media(max-width: 1199px) {
			display: none;
		}
	}



}


@media(max-width: 767px) {

	.next-prevs-slider {
		.container {
			.row {
				margin: 0 -8px;
			}

			.col-6 {
				padding: 0 8px;
			}
		}

		.left {
			.story-info {
				width: 100%;
				padding-left: 0;
				margin-top: 16px;
			}
		}

		.right {
			.story-info {
				width: 100%;
				padding-right: 0;

			}
		}

		.story-media {
			img {
				width: 100%;
				max-width: 190px;
			}
		}

		.mobile-button-wrap {
			display: flex;
			align-self: center;
			margin-bottom: 16px;

			h6 {
				width: 60px;
			}

			.slick-arrow {
				display: block !important;
				position: relative;
				left: auto;
				right: auto;
				transform: translate(0);
			}
		}

		.left {
			.mobile-button-wrap {
				justify-content: flex-start;

				h6 {
					margin: 5px 0 0 16px;
				}
			}

			.story-info {
				margin-top: 16px;
			}
		}

		.right {
			.mobile-button-wrap {
				justify-content: flex-end;
				text-align: right;

				h6 {
					margin: 5px 16px 0 0;
				}
			}

			.story-media {
				margin-top: 0px;
				margin-bottom: 16px;
			}
		}
	}
}

.next-prvs-slider-block {
	padding: 45px 0px;

	hr {
		margin-bottom: 45px;
	}

	.owl-slider {
		@media (min-width:768px) {
			padding: 0 60px;
		}
	}

	.owl-dots {
		display: none;
	}

	.owl-nav {
		@media (max-width:767px) {
			button.owl-prev {
				left: 0;
				top: -35px;
			}

			button.owl-next {
				right: 0;
				top: -35px;
			}
		}
	}
}





.tab-style {
	.nav.nav-tabs {
		justify-content: center;
		border: none;

		.nav-link {
			color: #000;
			text-transform: uppercase;
			font-size: 13px;
			font-weight: 700;
			border: none;
			padding-left: 0;
			padding-right: 0;
			margin: 0 40px;
			text-align: center;

			&.active {
				border-bottom: 4px solid #DA291C;
			}

			small {
				font-family: $gotham-book-font;
				text-transform: none;
			}
		}
	}

	.tab-content {
		@media (min-width:992px) {
			max-width: 880px;
			margin: 0 auto;
		}
	}
}


.tooltip-content-class {
	background-color: #fff;
	border: 1px solid transparent;
	padding: 8px 21px;
	color: #000;
	opacity: 1;
	box-shadow: 0px 0px 3px rgb(0 0 0 / 20%);
	font-size: 13px;
}

.tooltip-arrow-class {
	fill: white;
}


.facilities-tabs-block {
	hr {
		border-top: 2px solid rgba(0, 0, 0, 0.1);
	}

	.tb-space {
		margin: 30px 0 30px 0;
	}

	.facility-wrap {
		margin-top: 33px;

		ul {
			li {
				margin: 0 16px 10px;
				position: relative;

				img {
					max-width: 120px;
				}

				.smallicon {
					max-width:24px;
				}
				.tooltip-trigger-class {
					border: none;
					cursor: unset;
					background: none;
				}

				

				p {
					font-weight: 700;
					margin-top: 6px;
				}

				.add-icon {
					position: absolute;
					top: 5px;
					right: 5px;

					.place-bottom.type-dark {
						background: #fff;
						color: #000;
						opacity: 1;
						box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);

						&:after {
							border-bottom: 6px solid #fff !important;
						}
					}

					.place-left.type-dark {
						background: #fff;
						color: #000;
						opacity: 1;
						box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);

						&:after {
							border-left: 6px solid #fff !important;
						}
					}

					.place-right.type-dark {
						background: #fff;
						color: #000;
						opacity: 1;
						box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);

						&:after {
							border-right: 6px solid #fff !important;
						}
					}

					.place-top.type-dark {
						background: #fff;
						color: #000;
						opacity: 1;
						box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);

						&:after {
							border-top: 6px solid #fff !important;
						}
					}
				}
			}
		}
	}

	.tab-content {
		text-align: center;

		p {
			display: inline-block;
			max-width: 650px;
			padding: 0 10px;
		}
	}
}

.common-card {
	&.bg-dgreen1 {
		.cta-white {
			color: $donate-banner-bg;
			border-color: $donate-banner-bg;

			&:hover {
				color: #fff;
			}
		}
	}
}

.card-with-bg-same-fill {
	position: relative;

	&::after {
		content: "";
		background: url(../images/layer.png);
		position: absolute;
		bottom: 0px;
		height: 20px;
		width: 100%;
		background-repeat: repeat-x;

		@media(max-width: 767px) {
			background-size: 1000px;
			height: 10px;
		}

		@media(min-width: 1401px) {
			background-size: auto;
			background-repeat: repeat;
		}

	}

	&::before {
		content: "";
		background: url(../images/layer.png);
		position: absolute;
		top: -1px;
		height: 20px;
		width: 100%;
		background-repeat: repeat-x;
		-webkit-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
		transform: rotate(180deg);

		@media(max-width: 767px) {
			background-size: 1000px;
			height: 10px;
		}
		@media(min-width: 1401px) {
			background-size: auto;
			background-repeat: repeat;
		}
	}

	.desktop-bg {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background-size: cover;
		background-position: center right;
		background-repeat: no-repeat;
		border-radius: 4px;

		@media(max-width: 767px) {
			background-position: center bottom;
		}
	}

	.mobile-bg {
		display: none;

		@media(max-width: 767px) {
			display: block;
			position: absolute;
			left: 0;
			bottom: 0;
			height: 100%;
			width: 100%;
			background-size: cover;
			background-position: center bottom;
			background-repeat: no-repeat;
			border-radius: 4px;
		}
	}

	@media(max-width: 1199px) {
		.slick-slide {
			padding: 0 22px;
		}
	}

	@media(max-width: 992px) {
		.slick-slide {
			padding: 0 10px;
		}

		.slick-arrow {
			display: none !important;
		}
	}

	@media(max-width: 767px) {
		.slick-slide {
			padding: 0 20px;
		}
	}

	@media(max-width: 575px) {

		.slick-dots {
			bottom: -30px;
		}

		&.pb-5 {
			padding-bottom: 4.5rem !important;
		}
	}

	&.no-top-layer:before {
		display: none;
	}

	&.no-bottom-layer:after {
		display: none;
	}
}

.card-with-only-text-block-outer {
	position: relative;

	.container {
		position: relative;
		z-index: 1;
	}

	.desktop-bg {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background-size: cover;
		background-position: center right;
		background-repeat: no-repeat;
		border-radius: 4px;

		@media(max-width: 767px) {
			background-position: center bottom;
		}
	}

	.mobile-bg {
		display: none;

		@media(max-width: 767px) {
			display: block;
			position: absolute;
			left: 0;
			bottom: 0;
			height: 100%;
			width: 100%;
			background-size: cover;
			background-position: center bottom;
			background-repeat: no-repeat;
			border-radius: 4px;
		}
	}
}

.card-with-only-text-block {
	border-radius: 4px;
	padding: 40px 15px 40px 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: relative;
	z-index: 1;
	min-height: 300px;

	h3 {
		margin-bottom: 12px;
	}

	h4 {
		margin-bottom: 12px;
	}

	h6,
	p {
		margin-bottom: 24px;
	}

	p {
		display: inline-block;
		max-width: 1040px;

		a {
			color: inherit;
		}
	}

	&.title-align-left {
		flex-direction: row;
		min-height: 230px;
		padding-bottom: 40px;
		padding-top: 40px;

		h3 {
			float: left;
			width: 45%;
			position: relative;
			margin: 0;

			span.dollar {
				display: inline-block;
				background: #fff;
				border-radius: 50%;
				padding: 10px;

				span {
					background: -webkit-linear-gradient(45deg, #a67b00, #e2b92e 80%);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				}
			}
		}

		p {
			float: left;
			width: 55%;
			text-align: left;
			margin: 0;
			line-height: 30px;

			@media(min-width: 768px) {
				font-size: 20px;
				line-height: 30px;
			}

			.medium-font {
				font-size: 23px;
				font-weight: 500;

				@media(max-width: 767px) {
					font-size: 18px;
				}

				span {
					display: block;
					font-size: 26px;
					margin-top: 10px;

					@media(max-width: 767px) {
						font-size: 20px;
					}
				}
			}
		}


		@media(max-width: 1199px) {
			h3 {
				width: 400px;
			}

			p {
				width: calc(100% - 400px);
			}

		}

		@media(max-width: 992px) {
			flex-wrap: wrap;
			min-height: auto;
			padding: 50px 30px;

			h3 {
				width: 100%;
				text-align: left;
				top: 0;
				margin-bottom: 20px;
			}

			p {
				width: 100%;

			}

		}
	}
}

.common-media-with-text-block {
	.row {
		align-items: center;
	}

	.media-wrap {
		img {
			width: 100%;
		}
	}

	&.desktop-fixed-height {
		.media-wrap {
			@media (min-width:1200px) {
				margin-top: -90px;
			}

			img.desktop-hide {
				@media (max-width:767px) {
					margin-bottom: -120px;
				}
			}
		}

		.info.mt-5 {
			@media (max-width:767px) {
				margin-top: 0 !important;
			}
		}

		@media (max-width:767px) {
			margin-bottom: 50px;
		}
	}
}

.holiday-banner {
	min-height: 475px;
	display: flex;
	align-items: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.pinterest-board {
	.heading-row {
		display: flex;
		justify-content: space-between;
		margin-bottom: 32px;

		@media(max-width: 767px) {
			margin-bottom: 24px;
		}

		h4 {
			margin: 0;

			@media(max-width: 767px) {
				margin-bottom: 16px;
			}
		}

		span {
			font-weight: 700;

			b {
				font-weight: 400;
			}

			i {
				font-size: 29px;
				margin-left: 12px;
				position: relative;
				top: 4px;
			}
		}
	}

	.pinterest-card {
		margin-bottom: 40px;

		b {
			margin-top: 16px;
			display: block;
			font-weight: 700;
			font-weight: 400;

			@media(max-width: 767px) {
				margin-top: 12px;
			}
		}

		.media-wrap {
			img {
				object-fit: cover;
				width: 100%;

				@media (min-width:1200px) {
					height: 272px;
				}

				@media (min-width:992px) and (max-width:1199px) {
					height: 210px;
				}

				@media (min-width:768px) and (max-width:991px) {
					height: 150px;
				}
			}
		}
	}

	@media(max-width: 992px) {
		.row {
			margin: 0 -8px;

			.col-md-3 {
				padding: 0 8px;
			}

			.pinterest-card {
				margin-bottom: 24px;
			}
		}
	}

}


.banner-with-bg-media {
	&::after {
		opacity: 0;
		height: 0;
	}

	background-size: cover;
	background-repeat: no-repeat;
}


body .find-job {
	z-index: 5;

	.btn {
		background: #fff;
		margin-right: 25px;
	}
}

.careers-info-block {
	z-index: 0;

	.top-block {
		@media (min-width:992px) {
			max-width: 950px;
			margin: 0 auto;
		}

		.main-title {
			margin: 45px 0px 80px;
		}
	}
}

.large-video-block {
	.video-row {
		iframe {
			width: 100%;

			@media (max-width:1199px) {
				height: auto;
			}

			@media (max-width:767px) {
				min-height: 185px;
			}
		}
	}
}

.fpo-slider {
	margin-bottom: 100px;

	@media (max-width:767px) {
		margin-bottom: 50px;
	}

	.media-slider {
		.owl-nav {
			button.owl-prev {
				left: -70px;
			}

			button.owl-next {
				right: -70px;
			}
		}

		.owl-carousel {
			.item {
				position: relative;

				.slider-caption {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);

					h3 {
						margin: 0;
						color: rgba(255, 255, 255, 0.6);
					}
				}
			}
		}

		@media (max-width:767px) {
			margin: 0;
		}
	}

	@media (max-width:767px) {
		.owl-nav {
			display: none;
		}

		.owl-dots {
			display: block !important;
			margin-top: 15px;

			.owl-dot {
				background-color: #7D7979 !important;
				opacity: 0.4;

				&.active {
					opacity: 1;
					background-color: #7D7979 !important;
				}
			}
		}
	}
}

body {
	.owl-carousel {
		.owl-item {
			.item {
				img {
					width: 100%;
				}
			}
		}
	}
}

.common-boxes-wrap {
	&.ptb-80 {
		padding: 80px 0px;
	}

	@media(max-width: 767px) {
		&.with-slides {
			overflow: hidden;
			margin: 0 -15px 0 0;

			.owl-slider {
				width: calc(100% - 70px);
			}

			.owl-carousel .owl-stage-outer {
				overflow: visible;
			}

			.owl-dots {
				position: relative;
				left: 25px;
			}
		}
	}
}

.boxes-two-rows {
	.common-card {
		margin-bottom: 44px;

		@media (max-width:767px) {
			margin-bottom: 15px;
			min-height: 375px;
		}
	}
}


@media (max-width:992px) {
	.facilities-tabs-block {
		.facility-wrap {
			ul {
				li {
					.add-icon {}
				}
			}
		}
	}
}

@media (max-width:767px) {
	.follow-our-chanel-block {
		.follow-inner {
			display: block;
			text-align: center;
			min-height: auto;
			padding: 30px 15px;

			.social-block {
				text-align: center;
				margin-top: 5px;
			}

			h4 {
				margin-right: 0;
				margin-bottom: 12px;
			}
		}
	}




	.mosaic-block {
		.top-block {
			padding: 55px 0px 45px;

			.logo-media {
				margin-bottom: 32px;
			}
		}

		.info-block {
			p {
				max-width: 380px;
			}
		}

		.col-md-5 {
			padding: 0 20px;
		}

		.col-md-3 {
			padding: 0 20px;
		}
	}



	.two-column-card-without-layer {
		padding: 55px 0px 55px;
	}

	.two-column-card-without-layer::before,
	.two-column-card-without-layer::after {
		height: 30px;
		opacity: 1;
	}

	.location-info {
		.direction-links {
			margin-bottom: 20px;

			.btn-secondary {
				margin-right: 0;
			}

			a {
				font-size: 14px;
			}
		}

		.timing-listing.text-right {
			text-align: left !important;

			ul {
				padding-left: 0;
			}
		}
	}

	.media-slider {
		margin-left: -15px;
		margin-right: -15px;

		.owl-dots {
			display: none;
		}
	}

	.parking-list {
		li {
			&:last-child {
				margin-right: 0;
			}
		}
	}

	.store-near-by {
		padding: 30px 0px;

		h4,
		h3 {
			text-align: center;
		}

		.address-box {
			margin-bottom: 15px;
		}
	}

	.non-profit-block {
		margin-bottom: 35px;
	}

	.breadcrumbs-row {
		margin-top: 0 !important;

		ul li {
			list-style: none;
			text-transform: uppercase;
			font-size: 12px;
			margin: 0 13px;
		}
	}





	.carousel-heading-row {
		.small-text-cta.cta-black {
			float: none;
		}

		h4 {
			display: block;
		}
	}



	.next-prvs-slider-block hr {
		margin-bottom: 85px;
	}

	.story-inner {
		.story-media {
			img {
				margin-bottom: 15px;
			}
		}
	}



	.follow-our-chanel-block.half-top-reverse {
		margin-top: -61px;
	}


	.text-block.text-block-withour-border {
		.text-info-right {
			border-top: none;
			padding-top: 0;
			margin-top: 15px;
		}
	}


	body .facilities-tabs-block {
		.facility-wrap {
			margin-top: 35px !important;
		}

		h6 {
			text-transform: uppercase;
			border-bottom: 4px solid #DA291C;
			padding-bottom: 15px;
			margin: 0;
			display: inline-block;

			span {
				display: block;
				text-transform: none;
				font-weight: 800;
				position: relative;
				top: 5px;
			}
		}

		.heading-row {
			margin-bottom: 20px;
		}

		.mobile-sub-heading span {
			display: inline-block;
		}

		.tab-content .tab-pane {
			display: block;
		}

		h4 {
			br {
				display: none;
			}
		}



		.facility-wrap ul {
			justify-content: flex-start;

			li {
				margin: 0 15px 20px;
				width: calc(32.4% - 30px);

				.icon-wrap {
					img {
						height: 80px;
						width: 80px;
						object-fit: cover;
					}

				}

				p {
					font-size: 13px;
					line-height: 17px;
					min-height: auto;
				}

				.add-icon {
					top: 3px;
					right: -4px;

					img {
						max-width: 20px;
						height: auto;
					}
				}
			}
		}

		p .block-element,
		h4 .block-element {
			display: inline;
		}

		.facility-inner-wrap {
			max-width: 345px;
			margin: 0 auto;
		}
	}


	.common-media-with-text-block {
		.media-wrap {
			margin-top: 25px;
		}
	}

	.card-with-only-text-block {
		padding: 25px;

		.large-text-cta {
			font-size: 16px;
		}
	}

	.holiday-banner {
		background-image: url(../images/star-mob-banner.jpg);
		display: block;

		h1 {
			margin-top: 40px;
		}
	}

	.pinterest-board .heading-row {
		display: block;
	}

	body .find-job {
		text-align: center;

		&.half-top-reverse {
			margin-top: -101px;
		}

		.btn {
			background: #fff;
			margin: 5px 0;
		}

		h4 {
			margin-bottom: 10px !important;
		}
	}

	.careers-info-block {
		padding-top: 100px;

		&.half-top-reverse {
			margin-top: -101px;
		}

		h4 {
			text-align: left !important;
		}

		.logo-media {
			text-align: center;
			margin-bottom: 50px;
		}
	}

	body .bg5 {
		background-image: url(../images/banner-new-mob.jpg);
	}


	.banner-with-bg-media {
		.banner-info {
			margin-top: -50px;
		}
	}

	body .card-with-bg-same-fill {
		.cta-white {
			border-bottom: none;

			&::after {
				opacity: 1;
			}
		}
	}

}










@media (max-width: 767px) and (orientation : landscape) {
	body .facilities-tabs-block {

		.facility-wrap ul {
			justify-content: center;

			li {
				margin: 0 15px 20px;
				width: calc(25% - 30px);

			}
		}

	}
}

@media (min-width:768px) and (max-width:991px) {
	body .fpo-slider .media-slider {
		.owl-nav button.owl-prev {
			left: -23px;
		}

		.owl-nav button.owl-next {
			right: -23px;
		}
	}

	body .common-card {
		padding: 25px 15px;

		.large-text-cta {
			font-size: 16px;
		}
	}


}

@media (min-width:992px) and (max-width:1199px) {
	.modal-card-box .caption-block h5 {
		display: inline-block;
		max-width: 540px;

		span {
			display: inline-block;
		}
	}

}



.select-outer {
	position: relative;

	&:after {
		content: "";
		position: absolute;
		right: 15px;
		top: 18px;
		border-top: 8px solid #000;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		pointer-events: none;
	}

	select {
		width: 240px;
		height: 40px;
		border: 0;
		border-radius: 3px;
		padding: 0 20px;
		-moz-appearance: none;
		-webkit-appearance: none;

		&:focus {
			outline: 2px dotted #fff;
		}
	}
}

.banner-form {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 40px;



	.search-form {
		::-webkit-input-placeholder {
			/* Edge */
			color: #000;
			opacity: 1;
		}

		:-ms-input-placeholder {
			/* Internet Explorer 10-11 */
			color: #000;
			opacity: 1;
		}

		::placeholder {
			color: #000;
			opacity: 1;
		}
	}


	.or {
		display: block;
		font-size: 16px;
		color: #fff;
		font-weight: bold;
		margin: 0 22px;
		text-transform: lowercase;
	}

	input[type="text"] {
		width: 245px;
		height: 40px;
		border: 0;
		border-radius: 3px;
		padding: 0 20px;
		float: left;
		margin-right: 5px;
	}

	input[type="submit"] {
		background: #e1231a;
		text-transform: uppercase;
		color: #fff;
		font-size: 14px;
		font-weight: 700;
		text-transform: uppercase;
		float: left;
		border: 0;
		padding: 8px 25px;
		border-radius: 3px;
	}

}

.search-item {
	margin-bottom: 26px;
	border-bottom: 2px solid #f0f0f0;
	padding-bottom: 32px;
}

@media(max-width: 767px) {
	.banner-form {
		width: 245px;
		margin: 0 auto;
		display: block;

		.or {
			color: #000;
			margin: 5px 0;
		}

		input[type="submit"] {
			margin-top: 25px;
			margin-bottom: 40px;
			float: none;
		}
	}



}


.stroy-share-icons {
	padding: 0;	 
	text-align: center;
	font-weight: 500;
	font-size: 13px;
	margin: 0;
	li {
		display: inline-block;
		margin: 0 15px;
		font-size: 15px;
		&:first-child {
			margin-left: 40px;
		}
		i {
			color: #000;
		}

		a:hover {
			i {
				color: $orange-color
			}
		}
	}
}