header {
    transition: 0.5s all;
    position: absolute;
    left: 0;
    width: 100%;
    background: #fff;
    z-index: 10;
    &.v1 {
        top: 00px;
        &.nav-up {
            top: 0px;
        }
    }
    &.v2 {
        top: -140px;
        position: fixed;
        visibility: hidden;
        &.nav-up.header-active {
            top: 0;
            visibility: visible;
        }
        &.nav-down.header-active {
            top: 0;
            visibility: visible;
        }
    }
}

header.header-thrift {
    &.v2 {
        top: -210px;
        visibility: hidden;
        &.nav-down.header-active {
            top: 0;
            visibility: visible;
        }
        .logo-row {
            padding-top: 0;
        }
        .header-inner-main-2 {
            .hero-listing-row {
                ul {
                    padding-top: 4px;
                }
            }
        }
    }
}

.main-header {
    &.v1 {
        position: relative;
    }
}

header {
    .middle-block {
        position: absolute;
        left: 50%;
        transform: translate(-50%);
    }
    .top-bar {
        min-height: 40px;
        background: $primary-color;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        span {
            color: #fff;
            font-size: 13px;
            a {
                color: #fff;
                text-decoration: underline;
                font-weight: 700;
            }
        }
    }
    .main-menu-listing {
        padding: 13px 0px 7px 0px;
        transition: 0.3s all;
        .listing-block {
            float: right;
            ul {
                li {
                    display: inline-block;
                    margin: 0 16px;
                    &:first-child {
                        margin-left: 0;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        font-size: 13px;
                        font-weight: 700;
                        color: $body-color;
                        &.active {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
    .langauage-block {
        display: inline-block;
    }
    .header-inner-main {
        border-top: 2px solid #f0f0f0;
        padding: 18px 0px 16px 0px;
        border-bottom: 2px solid #f0f0f0;
        transition: 0.3s all;
        .container-fluid {
            display: flex;
            justify-content: space-between;
        }
    }
    .container-fluid {
        @media (min-width: 1200px) {
            padding: 0px 24px;
        }
    }
    .hero-listing-row {
        margin-bottom: -47px;
        z-index: 1;
        position: relative;
        ul {
            margin: 15px 0 0 0;
            background: #E1251B;
            padding: 14px 24px;
            border-radius: 4px;
            position: relative;
            top: -2px;
            li {
                display: inline-block;
                margin: 0 20px;
                position: relative;
                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                    &::after {
                        opacity: 0;
                    }
                }
                a {
                    color: #fff;
                    font-size: 15px;
                    font-weight: 700;
                    &.active {
                        text-decoration: underline;
                    }
                    &:hover {}
                    sup {
                        font-size: 10px;
                    }
                }
                &::after {
                    content: "";
                    width: 2px;
                    height: 16px;
                    background: #fff;
                    position: absolute;
                    top: 4px;
                    right: -21px;
                }
            }
        }
    }
    .logo-row {
        text-align: center;
        img {
            max-width: 240px;
            width: auto;
            height: auto;
            @media(max-width: 1199px) {
                max-width: 150px;
            }
        }
    }
    .header-inner-main-2 {
        .hero-listing-row ul {
            margin: 0;
            background: transparent;
            padding: 0;
            @media (min-width: 1200px) {
                padding: 6px 0 0 0;
            }
            li {
                a {
                    color: #E1251B;
                    &.active {
                        text-decoration: underline;
                    }
                }
                &::after {
                    background: #E1251B;
                }
            }
        }
        .find-store {
            position: relative;
            z-index: 1;
            @media(max-width: 1199px) and (min-width: 993px) {
                width: 350px;
                display: flex;
                justify-content: flex-end;
            }
            .search-box {
                display: inline-block;
            }
            .find-store-btn {
                margin-left: 12px;
            }
        }
    }
}

header.v1 {
    .logo-row img {
        max-width: 240px;
        height: auto;
        width: auto;
    }
}

header.v2 {
    .logo-row {
        padding-top: 5px;
        margin-bottom: 5px;
    }
    .header-inner-main-2 {
        .hero-listing-row {
            ul {
                padding: 10px 0 0 0;
            }
        }
    }
}

.mobile-header {
    display: none;
    visibility: hidden;
    * {
        visibility: hidden;
    }
}

.header-thrift {
    .header-inner-main-2 {
        display: block;
        padding: 14px 0px;
        border-bottom: none;
        .container-fluid {
            align-items: center;
        }
        .hero-listing-row {
            margin-bottom: 0;
            ul {
                padding-top: 6px;
            }
        }
    }
    &.stuck {
        // .header-inner-main-2 {
        //     border-bottom: 0;
        //     .logo-row {
        //         padding-top: 0;
        //     }
        // }
    }
}

header.main-header.header-thrift.stuck {
    //position: relative;
}

.thrift-nav {
    border-top: 2px solid #f0f0f0;
    padding: 25px 0px;
    .thrift-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        ul {
            padding: 0;
            margin: 0;
            li {
                display: inline-block;
                margin: 0 25px;
                a {
                    font-size: 13px;
                    font-weight: 700;
                    color: #000;
                    text-transform: uppercase;
                    border-bottom: 2px solid transparent;
                    text-decoration: none;
                    padding-bottom: 5px;
                    transition: 0.3s all;
                    &:hover {
                        border-color: #E1251B;
                    }
                    &.active {
                        border-color: #E1251B;
                    }
                    img {
                        margin-right: 6px;
                        margin-top: -2px;
                    }
                }
            }
        }
        h6 {
            margin: 0 45px 0 0;
            font-weight: 800;
            font-size: 24px;
            @media (max-width: 1199px) {
                margin: 0 25px 0 0;
            }
        }
    }
    &.mob-bottom {
        padding-top: 0;
        border-top: none;
        @media (min-width: 768px) {
            display: none;
        }
        .thrift-inner {
            border-top: 2px solid #f0f0f0;
            margin-top: -15px;
            ul {
                text-align: center;
                li {
                    display: block;
                    margin: 23px 0px;
                }
            }
        }
    }
}

header.main-header.header-thrift.stuck .thrift-nav {
    // position: fixed;
    // top: 0;
    width: 100%;
    background: #fff;
    border-bottom: 2px solid #f0f0f0;
}

@keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.slideInDown {
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
}

.animated {
    -webkit-animation-duration: 0.75s;
    animation-duration: 0.75s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

body {
    .select2-container--default .select2-selection--single .select2-selection__arrow b {
        border: 0;
        background: url("../images/down-arrow.svg")no-repeat;
        background-size: 12px;
        width: 12px;
        height: 12px;
        margin: -5px 0 0 4px;
    }
    .select2-container--default .select2-results__option--selected {
        background: transparent;
    }
    .select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
        background-color: transparent;
        color: #E1251B !important;
    }
    .select2-dropdown {
        border: 0;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
        width: auto !important;
        .select2-results {
            padding: 5px 0;
        }
        .select2-results__option {
            padding: 0 32px 0 12px;
            font-size: 12px;
            color: #000 !important;
            line-height: 22px;
            font-weight: 700;
            &:hover {
                background: none !important
            }
        }
    }
    .select2-search--dropdown {
        display: none;
    }
    .select2-container--default {
        .select2-selection--single {
            border: 0;
            border-radius: 0;
            .select2-selection__rendered {
                font-weight: 700;
                line-height: 24px;
                color: #000;
            }
        }
    }
    .select2-selection--single .select2-selection__rendered {
        width: 50px;
    }
}

@media(min-width: 1800px) {
    header.v1.lang-fr {
        .find-store {
            .store-toggle-btn,
            .search-toggle-btn {
                display: none;
            }
            .dropdown-search {
                margin-right: 0;
                &:after {
                    display: none;
                }
                .search-box {
                    position: static;
                    display: inline-block !important;
                }
            }
            .dropdown-store {
                .btn.find-store-btn {
                    position: static;
                    display: inline-block !important;
                }
            }
        }
    }
}

@media(min-width: 1400px) {
    header.v1.lang-en {
        .find-store {
            .store-toggle-btn,
            .search-toggle-btn {
                display: none;
            }
            .dropdown-search {
                margin-right: 0;
                &:after {
                    display: none;
                }
                .search-box {
                    position: static;
                    display: inline-block !important;
                }
            }
            .dropdown-store {
                .btn.find-store-btn {
                    position: static;
                    display: inline-block !important;
                }
            }
        }
    }
}

@media(max-width: 1199px) {
    header.v1.lang-fr {
        .main-menu-listing {
            .listing-block {
                ul {
                    li {
                        margin: 0 12px;
                    }
                }
            }
        }
    }
}

@media(max-width: 991px) {
    .main-header {
        display: none;
    }
    .lang-fr.mobile-header {
        .search-box {
            clear: both;
            input {
                &:focus {
                    &::placeholder {
                        font-size: 10px;
                    }
                }
            }
        }
    }
    .mobile-header {
        display: block;
        visibility: visible;
        * {
            visibility: visible;
        }
        .search-box {
            clear: both;
            input {
                &:focus {
                    width: 100%;
                }
            }
        }
    }
    .mobile-header {
        .main-top-bar {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 64px;
        }
        .mobile-toggle {
            width: 65px;
            span {
                height: 2px;
                display: block;
                width: 18px;
                clear: both;
                float: left;
                border-radius: 3px;
                background: #000;
                margin: 1.5px 0;
            }
        }
        .center-logo {
            img {
                max-height: 24px;
            }
        }
        .right-store-btn {
            a {
                font-size: 13px;
                font-weight: 700;
                font-weight: 400;
                text-transform: uppercase;
                color: #000;
                i {
                    color: #E1251B;
                    font-size: 20px;
                    position: relative;
                    top: 2px;
                    margin-left: 4px;
                }
            }
        }
        .mobile-navbar {
            background: $mobile-navbar-bg;
            margin: 0 -15px;
            ul {
                list-style-type: none;
                padding: 0;
                display: flex;
                justify-content: space-around;
                height: 48px;
                align-items: center;
                margin: 0 auto;
                max-width: 480px;
            }
            li {
                a {
                    color: #fff;
                    font-weight: 700;
                    font-size: 13px;
                    text-transform: uppercase;
                }
                &:last-child:before {
                    opacity: 0;
                }
            }
        }
        .mobile-permotion-bar {
            background: $mobile-permotion-bar;
            margin: 0 -15px;
            text-align: center;
            p {
                color: #fff;
                font-size: 13px;
                margin: 0;
                padding: 7px 0 8px 0;
                a {
                    font-weight: 700;
                    text-decoration: underline;
                    text-decoration-color: inherit;
                    color: #fff;
                }
            }
        }
        .find-store-collapse-block {
            height: 130px;
            padding: 20px;
            position: absolute;
            top: 0;
            background: #fff;
            width: 100%;
            top: -130px;
            display: none;
            transition: 0.3s all;
            input {
                height: 48px;
                background: #f0f0f0;
                border: none;
                border-radius: 50px;
                padding: 0 24px 0 24px;
                text-align: left;
                width: 100%;
                text-transform: uppercase;
                font-weight: 700;
                -webkit-transition: 0.3s all;
                -o-transition: 0.3s all;
                transition: 0.3s all;
                font-size: 13px;
                transition: 0.3s all;
            }
            h6 {
                margin-bottom: 15px;
            }
            .close-icon {
                position: absolute;
                top: 23px;
                right: 25px;
                cursor: pointer;
            }
            .textfield-wrap {
                position: relative;
                img.arrow-icon {
                    position: absolute;
                    right: 15px;
                    top: 13px;
                }
            }
            &.open {
                display: block;
                top: 0;
                z-index: 1;
            }
        }
        /*mobile slide menu*/
        .mobile-menu {
            position: fixed;
            top: 0;
            left: -100%;
            width: 300px;
            background: #fff;
            padding: 0 32px;
            height: 100%;
            overflow: scroll;
            z-index: 11;
            transition: all ease-in-out 0.3s;
            &.is-active {
                transition: all ease-in-out 0.3s;
                left: 0;
            }
            .menu-close-btn {
                width: 100%;
                clear: both;
                float: left;
                margin: 30px 0 17px 0;
                text-align: right;
                cursor: pointer;
                .inner {
                    display: inline-block;
                    height: 16px;
                    width: 16px;
                }
                span {
                    display: block;
                    width: 18px;
                    background: #000;
                    height: 2px;
                    &:first-child {
                        position: relative;
                        transform: rotate(-45deg);
                        top: 8px;
                    }
                    &:last-child {
                        position: relative;
                        transform: rotate(45deg);
                        top: 6px;
                    }
                }
            }
            ul {
                list-style-type: none;
            }
            .mobile-find-store {
                .find-store-btn {
                    height: 48px;
                    background: #f0f0f0;
                    border: none;
                    border-radius: 50px;
                    padding: 0 24px 0 45px;
                    text-align: center;
                    text-transform: uppercase;
                    font-weight: 700;
                    -webkit-transition: 0.3s all;
                    -o-transition: 0.3s all;
                    transition: 0.3s all;
                    font-size: 13px;
                    width: 175px;
                    &:hover {
                        background: #cccccc;
                    }
                }
            }
        }
    }
    .mobile-main-menu {
        ul {
            margin: 6px 0 25px 0;
            list-style-type: none;
            padding: 0 0 25px 0;
            float: left;
            width: 100%;
            border-bottom: 2px solid #f0f0f0;
        }
        li {
            margin: 20px 0 0 0;
            a {
                font-size: 18px;
                font-weight: 700;
                color: #000;
                text-transform: uppercase;
            }
        }
    }
    .mobile-secondary-menu {
        clear: both;
        margin-bottom: 9px;
        float: left;
        width: 100%;
        ul {
            padding: 0;
        }
        li {
            margin-bottom: 11px;
            a {
                font-size: 15px;
                text-transform: none;
                color: #000;
                font-weight: 500;
            }
        }
        &.primary {
            border-bottom: 2px solid #f0f0f0;
            padding-bottom: 5px;
            margin-bottom: 25px;
        }
    }
    .mobie-search-block {
        clear: both;
        position: relative;
        margin-top: 10px;
        float: left;
        width: 100%;
         ::-webkit-input-placeholder {
            /* Edge */
            color: #000;
            opacity: 1;
            text-transform: uppercase;
        }
         :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #000;
            opacity: 1;
            text-transform: uppercase;
        }
         ::placeholder {
            color: #000;
            opacity: 1;
            text-transform: uppercase;
        }
        input[type="text"] {
            background: #f0f0f0;
            border-radius: 50px;
            height: 48px;
            line-height: 48px;
            border: 0;
            padding: 0 0 0 40px;
            width: 150px;
            font-size: 13px;
            font-weight: 700;
            &:focus {
                border: 0;
                width: 100%;
            }
        }
        input[type="submit"] {
            position: absolute;
            left: 15px;
            top: 16px;
            border: 0;
            width: 16px;
            text-indent: -99999px;
            background: url("../images/search-icon.svg")no-repeat;
            &:focus {
                border: 0;
            }
        }
    }
    header.mobile-header {
        .langauage-block {
            margin-top: 25px;
            margin-bottom: 25px;
        }
    }
    .mobile-store-search {
        background: #fff;
        position: fixed;
        left: 0;
        top: -250px;
        width: 100%;
        transition: all ease-in-out 0.3s;
        padding: 20px 16px 24px 16px;
        &.active {
            top: 0;
            transition: all ease-in-out 0.3s;
        }
        .close-btn {
            position: absolute;
            right: 16px;
            cursor: pointer;
            height: 18px;
            width: 18px;
            cursor: pointer;
            span {
                display: inline-block;
                width: 18px;
                background: #000;
                height: 2px;
                &:first-child {
                    position: absolute;
                    transform: rotate(45deg);
                    top: 6px;
                    left: 0px;
                }
                &:last-child {
                    position: absolute;
                    transform: rotate(-45deg);
                    top: 6px;
                    left: 0;
                }
            }
        }
         ::-webkit-input-placeholder {
            /* Edge */
            color: #A8A8A8;
            opacity: 1;
            font-weight: 700;
        }
         :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #A8A8A8;
            opacity: 1;
            font-weight: 700;
        }
         ::placeholder {
            color: #A8A8A8;
            opacity: 1;
            font-weight: 700;
        }
        form {
            position: relative;
            div {
                width: 100%;
            }
            div {
                div {
                    padding: 1px 10px;
                    text-align: left;
                }
                .items {
                    font-size: 13px;
                }
            }
        }
        p {
            font-size: 16px;
            line-height: 12px;
            text-align: center;
            font-weight: 700;
            color: #000;
            margin-bottom: 20px;
        }
        input {
            width: 100%;
            height: 48px;
            border-radius: 50px;
            border: 0;
            background: #f0f0f0;
            padding: 0 20px;
            line-height: 48px;
            &:focus {
                border: 0;
            }
        }
        .arrow-right {
            border: 0;
            position: absolute;
            right: 17px;
            top: 14px;
            background: url("../images/circel-arrow.svg") no-repeat;
            width: 20px;
            height: 20px;
            background-size: 20px;
            &:focus {
                border: 0;
            }
        }
    }
    .mobile-menu-mask {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .30);
        opacity: 0;
        visibility: hidden;
        transition: all ease-in-out 0.3;
    }
    .mobile-header.active {
        .mobile-menu-mask {
            display: block;
            opacity: 1;
            visibility: visible;
            transition: all ease-in-out 0.3;
        }
    }
    .thrift-mobile-header {
        .thrift-nav {
            .thrift-inner {
                display: block;
                position: relative;
                .secondary {
                    display: none;
                }
                samp {
                    font-size: 13px;
                    font-weight: 700;
                    text-transform: uppercase;
                    em {
                        font-style: normal;
                    }
                }
                .thrift-top-head {
                    display: flex;
                    justify-content: space-between;
                    .category-dropdwon {
                        font-size: 13px;
                        font-weight: 700;
                        text-transform: uppercase;
                        img {
                            margin-right: 2px;
                            margin-top: -2px;
                        }
                        i {
                            font-size: 16px;
                            margin-left: 16px;
                            position: relative;
                            top: 1px;
                        }
                    }
                }
                &.active {
                    .listing-row {
                        display: block;
                    }
                    .secondary {
                        display: block;
                    }
                    .primary {
                        display: none;
                    }
                }
                .listing-row {
                    text-align: center;
                    display: none;
                    position: absolute;
                    top: 47px;
                    width: 100%;
                    background: #fff;
                    left: 0;
                    z-index: 2;
                    li {
                        display: block;
                        margin: 20px 0;
                    }
                }
            }
        }
    }
}

.thrift-mobile-header.v2 .thrift-nav .thrift-inner .listing-row {
    top: 44px;
}

.notify-bar {
    /* min-height: 61px;*/
    text-align: center;
    position: relative;
    .inner {
        /* position: absolute;
         left: 0;
         top: 0;
         z-index: 1;*/
        width: 100%;
        background-color: #ccc;
    }
    p {
        font-size: 13px;
        margin: 0;
        line-height: 18px;
    }
    small {
        display: inline-block;
        max-width: 1060px;
        padding: 15px 0 10px 0;
        color: #707070;
        a {
            text-decoration: underline;
            text-decoration-color: #000;
        }
    }
    button {
        position: absolute;
        right: 20px;
        top: 20px;
        border-radius: 5px;
        background: #fff;
        border: 0;
        padding: 8px 5px;
        text-align: center;
        &:focus {
            outline: none;
        }
        span {
            display: block;
            height: 2px;
            width: 11px;
            background: #000;
            position: relative;
            pointer-events: none;
            text-indent: -99999px;
            color: #fff;
            &:nth-child(2) {
                transform: rotate(90deg);
                top: -2px;
                width: 11px;
            }
            &:nth-child(1) {
                transform: rotate(0deg);
            }
        }
        &.open {
            span {
                &:nth-child(2) {
                    opacity: 0;
                }
            }
        }
    }
    .container,
    .col-12 {
        position: static;
    }
    @media(max-width:767px) {
        button {
            right: 10px;
            top: 10px;
        }
        small {
            padding-right: 20px;
        }
    }
}

header.lang-fr {
    @media(min-width: 1300px) {
        .search-box input {
            width: 155px;
            padding-right: 10px;
            &:focus {
                width: 320px;
            }
        }
    }
    @media(min-width: 993px) and (max-width: 1199px) {
        .hero-listing-row ul li a {
            font-size: 12px;
        }
    }
    @media(max-width: 767px) {
        .search-box input {
            width: 150px;
        }
    }
}

.menu-links-component {
    text-align: center;
    .listing-row {
        width: 100%;
        background: #fff;
        margin: 20px 0 20px 0;
        padding-left: 0;
        li {
            display: block;
            margin: 35px 0;
            img {
                margin-right: 10px;
            }
            a {
                font-size: 13px;
                font-weight: 700;
                color: #000;
                text-transform: uppercase;
                border-bottom: 2px solid transparent;
                text-decoration: none;
                padding-bottom: 5px;
                transition: 0.3s all;
                &:hover {
                    border-color: #E1251B;
                }
                &.active {
                    border-color: #E1251B;
                }
                img {
                    margin-right: 6px;
                    margin-top: -2px;
                }
            }
        }
    }
}

.find-store {
    .dropdown-search {
        margin-right: 30px;
        position: relative;
        display: inline-block;
        top: 9px;
        &:after {
            content: "";
            pointer-events: none;
            width: 2px;
            position: absolute;
            right: -16px;
            height: 30px;
            background: #f0f0f0;
            top: -4px;
        }
        .search-toggle-btn {
            i {
                color: #E1251B;
                font-size: 20px;
            }
        }
        .search-box {
            position: absolute;
            right: 0;
            top: 50px;
            display: none !important;
            input {
                width: 250px;
            }
        }
        &.is-active {
            .search-box {
                display: block !important;
            }
        }
    }
    .dropdown-store {
        display: inline-block;
        position: relative;
        top: 10px;
        .store-toggle-btn {
            i {
                color: #E1251B;
                font-size: 23px;
                position: relative;
                top: 1px;
            }
        }
        .btn.find-store-btn {
            position: absolute;
            right: 0;
            top: 50px;
            display: none;
        }
        &.is-active {
            .find-store-btn {
                display: block;
            }
        }
    }
}

.header-thrift {
    .find-store {
        .dropdown-search {
            top: 0;
            .search-box {
                top: 48px;
            }
        }
        .dropdown-store {
            top: 0;
            .btn.find-store-btn {
                top: 50px;
            }
        }
    }
}

@media(max-width: 992px) {
    .lang-fr.mobile-header .mobile-menu .mobile-find-store .find-store-btn {
        width: 235px
    }
}

#app-cover {
    position: relative;
    float: left;
    top: 4px;
    right: 0;
    left: 0;
    width: 61px;
    height: 20px;
    margin: 0;
    #select-button {
        position: relative;
        height: 20px;
        padding: 0;
        border-radius: 0;
        cursor: pointer;
    }
    #options-view-button {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        opacity: 0;
        cursor: pointer;
        z-index: 3;
    }
    #options-view-button+samp {
        position: absolute;
        right: -2px;
        top: 3px;
        background: url(/static/media/down-arrow.b569a73a.svg) no-repeat;
        height: 6px;
        width: 11px;
        content: "";
        transition: all ease-in-out 0.3s;
    }
    #options-view-button:checked+samp {
        transform: rotate(-180deg);
        transition: all ease-in-out 0.3s;
    }
    #selected-value {
        font-size: 12px;
        line-height: 1;
        font-weight: 700;
        color: #000;
    }
    .option {
        label {
            position: absolute;
        }
    }
    .option,
    .label {
        font-size: 12px;
        line-height: 1;
        font-weight: 700;
        color: #000;
    }
    .options {
        position: absolute;
        left: 0;
        width: 250px;
    }
    #options {
        position: absolute;
        top: 22px;
        right: 0;
        left: 0;
        width: 61.5px;
        margin: 0;
        padding: 0;
        z-index: 1;
    }
    #options-view-button:checked~#options {
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
    }
    .option {
        position: relative;
        line-height: 1;
        transition: 0.3s ease all;
        z-index: 2;
        legend {
            margin: 0;
        }
        &:first-child {
            .label {
                padding-top: 10px !important;
                padding-bottom: 10px !important;
            }
        }
        &:nth-child(2) {
            .label {
                padding-bottom: 10px !important;
            }
        }
        &:nth-child(3) {
            .label {
                padding-bottom: 10px !important;
            }
        }
        &:nth-child(4) {
            .label {
                padding-bottom: 10px !important;
            }
        }
    }
    .option i {
        position: absolute;
        left: 14px;
        padding: 0;
        display: none;
    }
    #options-view-button:checked~#options .option i {
        display: block;
        padding: 12px 0;
    }
    .label {
        display: none;
        padding: 0;
    }
    #options-view-button:checked~#options .label {
        display: block;
        padding: 4px 0px 4px 11px;
        background: #fff;
    }
    .s-c {
        position: absolute;
        left: 0;
        width: 100%;
        height: 50%;
    }
    .s-c.top {
        top: 0;
    }
    .s-c.bottom {
        bottom: 0;
    }
    input[type="radio"] {
        position: absolute;
        right: 0;
        left: 0;
        width: 100%;
        height: 50%;
        margin: 0;
        opacity: 0;
        cursor: pointer;
    }
    .s-c:hover {
        height: 100%;
        z-index: 1;
    }
    .opt-val {
        position: absolute;
        left: 0px;
        width: 61px;
        height: 17px;
        opacity: 0;
        transform: scale(0);
    }
    .option input[type="radio"]:checked~.opt-val {
        opacity: 1;
        transform: scale(1);
    }
    .option input[type="radio"]:checked~i {
        top: 0;
        bottom: auto;
        opacity: 1;
        animation: unset;
    }
    .selected .label,
    .option input[type="radio"]:checked~i,
    .option input[type="radio"]:checked~.label {
        color: #E1251B !important;
    }
    .option input[type="radio"]:checked~.label:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
    }
    #options-view-button:not(:checked)~#options .option input[type="radio"]:checked~.opt-val {
        top: -41px;
    }
    .option:nth-child(1) input[type="radio"]:checked~.opt-val {
        top: -22px;
        background: #fff;
        width: 22px;
    }
    .option:nth-child(2) input[type="radio"]:checked~.opt-val {
        top: -48px;
        background: #fff;
        width: 22px;
    }
    .option:nth-child(3) input[type="radio"]:checked~.opt-val {
        top: -64px;
        background: #fff;
        width: 22px;
    }
    .option:nth-child(4) input[type="radio"]:checked~.opt-val {
        top: -88px;
        background: #fff;
        width: 22px;
    }
    #option-bg {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 40px;
        transition: 0.3s ease all;
        z-index: 1;
        display: none;
    }
    #options-view-button:checked~#options #option-bg {
        display: block;
    }
    .option:hover .label {
        color: #E1251B;
    }
    .option:nth-child(1):hover~#option-bg {
        top: 0;
    }
    .option:nth-child(2):hover~#option-bg {
        top: 40px;
    }
    .option:nth-child(3):hover~#option-bg {
        top: 80px;
    }
    .option:nth-child(4):hover~#option-bg {
        top: 120px;
    }
}

.flag-icon {
    width: 25px;
    padding-right: 5px;
}

.lang-dropdown-with-flag {
    display: inline-block;
    .flag-icon {
        
        left: 34px;
        width: 25px;
    }
    #app-cover {
        width: 107px;
        .option:first-child {
            img {
                top: 6px;
            }
            .label {
                padding-top: 10px !important;
            }
        }
        #options-view-button {
            height: 25px;
            top: -7px;
            right: auto;
            &:checked~#options {
                img {
                    opacity: 1;
                }
                .label {
                    padding: 4px 0px 4px 42px;
                }
            }
        }
        #select-button {
            padding-left: 42px;
        }
        #select-box::after {
            right: -2px;
        }
        .opt-val {
            display: none;
        }
        .label {
            display: none;
            position: relative;
            &:after {
                content: "";
                width: 50px;
                height: 1px;
                background: #E1251B;
                left: 43px;
                bottom: 6px;
                position: absolute;
                opacity: 0;
            }
        }
        #options {
            width: 107px;
            padding-top: 0px;
        }
        .option {
            img {
                position: absolute;
                left: 10px;
                top: 0;
                width: 25px;
                opacity: 0;
                z-index: 1;
            }
        }
        .option.selected .label:after {
            opacity: 1;
        }
        .option .label:hover:after {
            opacity: 1;
        }
    }
    @media(max-width: 1199px) {
        .flag-icon {
            left: 25px
        }
    }
    @media(max-width: 992px) {
        .flag-icon {
            left: 42px
        }
    }
    &.small-flag {
        #app-cover {
            .label:after {
                width: 17px;
            }
        }
    }
}

@media(max-width: 1199px) {
    .find-store {
        .dropdown-store {
            .store-toggle-btn {
                i {
                    top: 0;
                }
            }
            .btn.find-store-btn {
                top: 42px;
            }
        }
        .dropdown-search {
            .search-box {
                top: 45px;
            }
        }
    }
    .header-thrift {
        .find-store {
            .dropdown-store {
                .btn.find-store-btn {
                    top: 47px;
                }
            }
        }
    }
    header {
        &.v2 {
            top: -180px;
            visibility: hidden;
            .header-inner-main-2 {
                padding: 13px 0;
                .hero-listing-row {
                    ul {
                        padding: 9px 0 0 0;
                    }
                }
            }
        }
    }
    header.header-thrift {
        &.v2 {
            top: -255px;
            visibility: hidden;
            &.nav-down.header-active {
                top: 0;
                visibility: visible;
            }
        }
    }
    .thrift-nav {
        .container {
            max-width: 100%;
        }
        .thrift-inner {
            ul {
                li {
                    margin: 0 13px;
                }
            }
        }
    }
}

@media(min-width: 768px) and (max-width: 992px) {
    .mobile-header {
        .mobile-navbar {
            margin: 0 -15px;
        }
        .mobile-permotion-bar {
            margin: 0 -15px;
            min-height: 40px;
        }
    }
}

@media(max-width: 992px) {
    header.v2 {
        top: -300px;
        visibility: hidden;
    }
    .find-store-collapse-block.open form div {
        width: 100%;
    }
    .mobile-header.thrift-mobile-header {
        &.v2 {
            top: -350px;
            visibility: hidden;
            &.nav-down.header-active {
                top: 0;
                visibility: visible;
            }
        }
    }
    .mobile-header {
        .container {
            max-width: 100%;
        }
        .mobile-permotion-bar {
            padding: 0 15px;
        }
        &.v1 {
            position: static;
        }
    }
    .thrift-nav {
        padding: 15px 0px;
        .thrift-inner {
            h6 {
                position: relative;
                top: 1px;
            }
        }
    }
    .thrift-mobile-header {
        .thrift-nav {
            .thrift-inner {
                .thrift-top-head {
                    .category-dropdwon {
                        position: relative;
                        top: 4px;
                    }
                }
            }
        }
        &.v1 {
            position: static;
        }
    }
}

@media(max-width: 767px) {
    .mobile-header.thrift-mobile-header {
        &.v2 {
            &.nav-down.header-active {
                top: 0;
                visibility: visible;
            }
        }
    }
    header {
        .container {
            padding-left: 16px;
            padding-right: 16px;
        }
        .col-12 {
            padding-left: 16px;
            padding-right: 16px;
        }
        .row {
            margin-left: -16px;
            margin-right: -16px;
        }
    }
    .mobile-header {
        &.mobile-stuck {
            // position: fixed;
            // top: 0;
            // left: 0;
            // width: 100%;         
            //  z-index: 9;
            //  &.active {
            //      height: 100%;
            //  }
            // .main-top-bar {
            //    display: none;
            //  }
            //  .thrift-nav {
            //      background: #fff;
            //  }
        }
        .mobile-navbar {
            margin-left: -16px;
            margin-right: -16px;
            padding: 0 16px;
            ul {
                justify-content: space-between;
            }
        }
        .mobile-permotion-bar {
            margin-left: -16px;
            margin-right: -16px;
        }
        &.lang-fr {
            .mobile-navbar {
                display: flex;
                align-items: center;
                height: 48px;
                ul {
                    display: inline-block;
                    height: auto;
                }
                li {
                    float: left;
                    position: relative;
                    margin: 0 10px;
                    &:first-child {
                        margin-left: 0;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                    &:nth-child(2):after {
                        content: "";
                        left: -10px;
                        top: 6px;
                        width: 2px;
                        height: 15px;
                        position: absolute;
                        background: #fff;
                    }
                    &:nth-child(2):before {
                        content: "";
                        right: -10px;
                        top: 6px;
                        width: 2px;
                        height: 15px;
                        position: absolute;
                        background: #fff;
                    }
                    a {
                        font-size: 11px;
                    }
                }
            }
        }
        &.lang-en {
            .mobile-navbar {
                display: flex;
                align-items: center;
                height: 48px;
                ul {
                    display: inline-block;
                    height: auto;
                }
                li {
                    float: left;
                    position: relative;
                    margin: 0 10px;
                    &:first-child {
                        margin-left: 0;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                    &:nth-child(2):after {
                        content: "";
                        left: -10px;
                        top: 5px;
                        width: 2px;
                        height: 15px;
                        position: absolute;
                        background: #fff;
                    }
                    &:nth-child(2):before {
                        content: "";
                        right: -10px;
                        top: 5px;
                        width: 2px;
                        height: 15px;
                        position: absolute;
                        background: #fff;
                    }
                    a {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

@media(max-width: 479px) {
    .mobile-header.lang-fr {
        .mobile-navbar {
            ul {
                position: relative;
                top: 2px;
            }
            li {
                margin: 0 5px;
                width: calc(33.33% - 7px);
                text-align: center;
                a {
                    display: inline-block;
                    font-size: 12px;
                    line-height: 15px;
                }
            }
            li:nth-child(2) {
                padding: 0 10px;
            }
            li:nth-child(2)::before {
                right: 10px;
                top: 0;
                width: 2px;
                height: 30px
            }
            li:nth-child(2)::after {
                left: 10px;
                top: 0;
                width: 2px;
                height: 30px
            }
        }
    }
}

@media(max-width: 400px) {
    .mobile-header.lang-fr {
        .mobile-navbar {
            li {
                margin: 0 5px;
            }
            li:nth-child(2) {
                padding: 0 5px;
                position: relative;
            }
            li:nth-child(2)::before {
                right: 0px;
                top: 0;
                width: 2px;
                height: 30px
            }
            li:nth-child(2)::after {
                left: 0px;
                top: 0;
                width: 2px;
                height: 30px
            }
        }
    }
}

@media(max-width: 370px) {
    .mobile-header.lang-fr {
        .mobile-navbar {
            li {
                a {
                    font-size: 9px;
                }
            }
        }
    }
    .mobile-header.lang-en {
        .mobile-navbar {
            li {
                a {
                    font-size: 11px;
                }
                &:nth-child(2)::after {
                    top: 6px;
                }
                &:nth-child(2)::before {
                    top: 6px;
                }
            }
        }
    }
}

@media(max-width: 327px) {
    .mobile-header.lang-en {
        .mobile-navbar {
            ul {
                width: 100%;
                position: relative;
                top: 3px;
            }
            li {
                margin: 0 5px;
                width: calc(33.33% - 7px);
                text-align: center;
                a {
                    display: inline-block;
                    font-size: 12px;
                    line-height: 15px;
                }
                &:nth-child(2)::after {
                    top: 6px;
                    left: 0;
                }
                &:nth-child(2)::before {
                    top: 6px;
                    right: 0;
                }
            }
        }
    }
}

.find-store-collapse-block .textfield-wrap .storeAutoCompleteId .arrow-right,
.mobile-store-search .storeAutoCompleteId .arrow-right {
	position: absolute;
	right: 16px;
	top: 13px;
	background: #E1251B;
	color: #fff;
	border-radius: 50%;
	font-size: 14px;
	width: 20px;
	height: 20px;
	text-align: center;

    a i {
        position: relative;
        bottom: 2px;
    }
}