.carousel-media-block {
    position: relative;
    .container {
        position: relative;
    }
    .desktop-bg {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background-size: cover;
		background-position: center top;
		background-repeat: no-repeat;
		 
	}

	.mobile-bg {
		display: none;
		@media(max-width: 767px) {
			display: block;
			position: absolute;
			left: 0;
			bottom: 0;
			height: 100%;
			width: 100%;
			background-size: cover;
			background-position: center top;
			background-repeat: no-repeat;
		}
	}
	.owl-slider {
		overflow: hidden;
		padding: 0 140px;
		.slick-list {
			overflow: visible;
		}
		.owl-nav {
			button.owl-prev {
			left:20px;
			top:50%;
		}

			button.owl-next {
				right:20px;
				top:50%;
			}
		}
		.slick-slide {
			padding: 0 10px;
			img {
				width: 100%;
			}
		}

		.slick-list{
			// margin: 0 -10px;
			// padding:0 20% 0 0;		
		}

		.slick-arrow {			 
			&.slick-prev {
				left: -77px;				 
			}
			&.slick-next {
				right: -77px;				 
			}
		}


    }	
    

    &.item-one {
        overflow: hidden;
       
        .owl-slider {
            overflow: visible;
            width: 880px;
            margin: 0 auto;
            padding: 0;

            .slick-slide {
                padding: 0 16px;

                .item {
                    display: block;
                    position: relative;
                    width: 100%;
                    &:after {
                        content: "";
                        display: block;
                        padding-bottom: 66%;
                    }

                    img {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }

                
            }

            .slick-arrow.slick-prev {
                left: -247px;
                @media(max-width: 1400px) {
                    left: -220px;                    
                }
                
            }
            .slick-arrow.slick-next {
                right: -247px;
                @media(max-width: 1400px) {
                    right: -220px;                    
                }
            }
        }
    }
}

.slick-arrow {
	height: 45px;
	width: 45px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;	 
	color: #fff;
	z-index: 1;
	&.slick-prev {
		left: -77px;
        background: url("../images/prev-btn.png") no-repeat;
        background-size: 45px;
        &:before {
            display: none;
        }
	}
	&.slick-next {
        right: -77px;
        background: url("../images/next-btn.png") no-repeat;
        background-size: 45px;	
        &:before {
            display: none;
        }	 
	}
}
.carousel-heading-row {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  h4 {
    display:inline-block;
    margin: 0;
  }
  p {
      margin: 0;
  }
  
  .small-text-cta.cta-black {
	float:right;
  }
}

.carousel-media-block { 
    &.item-three {
        .container-fluid {
            padding: 0;
        }
        .slick-slide {
            span {
                display: block;
                position: relative;
                width: 100%;
                &:after {
                    content: "";
                    display: block;
                    padding-bottom: 150%;
                }

                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }
    }
    
    &.mixed-items {
        .owl-slider .slick-slide {
            img {
                max-height: 505px;
                width: auto;
            }
        }
    }

}

 
@media (max-width:1360px) {	
    .carousel-media-block.item-one {
        position: relative;
        .container-fluid {
            position: relative;
        }
        .owl-slider {
            .slick-slider {
                position: static;
            }
            .slick-arrow.slick-prev {
                left: 32px;
            }
            .slick-arrow.slick-next {
                right: 32px;
            }

        }
    }
}
@media (max-width:1360px) {	
    .carousel-media-block.item-one {
        position: relative;
        .owl-slider {
             width: 750px;
        }
    }
}
@media (max-width:1199px) {	
    .carousel-media-block { 
        &.mixed-items {
            img {
                max-height: 400px;
            }
        }
    }
}
@media (max-width:992px) {	
    .carousel-media-block.item-one {
        position: relative;
        .owl-slider {
             width: 500px;
        }
    }
    .carousel-media-block { 
        &.mixed-items {
            img {
                max-height: 363px;
            }
        }
    }
}
@media (max-width:992px) {	
	.carousel-media-block {
        
		.owl-slider {
			padding: 0px;
			.slick-slide {
				padding: 0 7px;
			}
		}
    }
}

@media (max-width:767px) {	
    .carousel-heading-row {
        display: block;
    }

    .carousel-media-block.item-one {
        .container-fluid {
            padding: 0 0 30px 0;
        }
        .owl-slider {
            width: 100%;
            .slick-slide {
                padding: 0;
            }
            .slick-arrow {
                display: none !important;
            }

        }

        
    }

    .carousel-media-block { 
        &.mixed-items {
            .slick-track {
                display: flex;
            }
            .owl-slider .slick-slide img {
                max-height: 400px;
                width: auto;
            }
            .slick-dots {
                bottom: -30px;
            }

            .owl-slider {
                padding: 0px 0px 40px 0px;
            }
        }
        &.item-three {
            .owl-slider {
                padding-bottom: 40px;
            }

            .slick-dots {
                bottom: -30px;
            }

            .slick-slide { 
                padding: 0;
            }
        }
    }

    

    .slick-dots {
        bottom: 0;
        li {
            height: auto;
            width: auto;
            button {
                width: 12px;
                height: 15px;
                border-radius: 4px;
                opacity: 0.4;
                background-color: #0b7eb5;
                &:before {
                    display: none;
                }
            }
            &.slick-active {
                button {
                    opacity: 1;
                }
            }
        }
    }
}

@media(max-width: 767px) {
    .carousel-media-block { 
        &.item-three {
             
            .slick-slide { 
                padding: 0 8px;
            }
        }
    }
}