.hero-card {
	z-index: 1;
	clear: both;
    position: relative;
    @media(min-width: 768px) {
        .no-space {
            padding: 0;
        }
 
	}
	
	.tag-style {
		margin-bottom: 0;
	}
	
	&.hero-card-small {
	  .card-inner {
		background: #004E7D;
		border-radius: 4px;
		overflow: hidden;
		.row {
			align-items: center;
		}
	  }

	  .hero-media-info {
		padding-right: 64px;
		padding-left: 79px;
	
		h5,p {
			color:#fff;
			margin-bottom: 0
		}

		.body1,p {
			margin-top: 12px;
		}
		h3 {
			margin-bottom :0;
		}
		
		h5 {
		  font-weight: 700;
		  margin-top: 16px;
		}

		.large-text-cta,
		.read-more-link,
		.small-text-cta,
		.btn {
			margin-top: 24px;
			clear: both;
			display: inline-block;
		}


	  }

	  .hero-media {
		  padding: 0;
		img {
			width: 100%;
			object-fit:cover;
			// @media (min-width:1200px) {
			// 	height: 288.75px;    
			// }
			
			// @media (min-width:992px) and (max-width:1199px) {
			// 	height: 225px;
			// }
			
			// @media (min-width:768px) and (max-width:991px) {
			// 	height: 165px;
			// }
		}	
	   }  
		
		margin-bottom:30px;	
	}

	&.hero-card-large {
		&.full-width {
			padding: 50px 0;
		}

		&.without-padding {
			.hero-media-info {
				padding: 0 15px 0 0;
			}
		}
		.hero-media-info-title {
			font-size: 32px;
			font-weight: 700;
			line-height: 1.2;
			@media(max-width: 767px) {
				font-size: 26px;
			}
		}
		.hero-media-info-title-small {
			font-size: 24px;
			font-weight: 700;
			margin-top: 0 !important;
			margin-bottom: 0.5rem !important;
			line-height: 1.2;
		}
		
	  .card-inner {
		background: #90D0D0;	
		border-radius: 4px;	
		overflow: hidden;
		a {
			text-decoration-color: inherit;
		}
		.row {
			align-items: center;
			@media(min-width: 768px) {
				margin: 0;
			}
		}
	  }
	  
	  .hero-media-info {
			@media(min-width: 993px) {
				padding-left: 62px;
				padding-right: 62px;
			}

			.subheading {
				color:#000;
				font-weight: 700;	
				margin-bottom: 0;
				margin-top: 12px;
			}
		 
			h3,p,h6 {
				color:#000;
			}
			
			h3,h6 {
			font-weight: 700;	
			}
			
			h6 {
				margin-bottom: 0;
				margin-top: 12px;
			}
			h3 {
				margin-top: 16px;
				margin-bottom: 0px;
				span {
					display:block;
				}
				text {
					font-size:16px;
					position: relative;
					top: -16px;
				}
			}

			
			p {
				margin-top: 20px;
				margin-bottom: 0;
			}

			.large-text-cta,
			.read-more-link,
			.small-text-cta,
			.btn {
				margin-top: 24px;
				clear: both;
				display: inline-block;
			}
			 
		}
	  
	  .hero-media {
		img {
			width: 100%;
			height: auto;	
			// @media (min-width:1200px) {
			// 	height: 433.13px;    
			// }
			
			@media (min-width:992px) and (max-width:1199px) {
				height: 337.5px;
			}
			
			 
		}	
	   }
	  
	}

	&.hero-card-medium {
	  .card-inner {
		background: #F0F0F0;
		border-radius: 4px;
		overflow: hidden;
		.row {
			align-items: center;
		}
	  }

	  .hero-media-info {
		padding: 24px 40px 32px;
		@media(min-width: 993px) {
			padding-left: 49px;
		}
		h4,p {
			color:#000;
		}
		p {
			margin-top: 20px;
			margin-bottom: 0;
		}

		.large-text-cta,
		.small-text-cta,
		.read-more-link,
		.btn {
			margin-top: 24px;
			clear: both;
			display: inline-block;
		}
		
		h2 {
		  margin-top:16px;		
		  span {display:block;}	
		}
		 
		h6 {
			margin-bottom: 0;
			margin-top: 12px;
		}
		h4 {
			margin-top: 16px;
		}
		h5 {
		  font-weight: 700;	
		  margin-top: 16px;	
		}

	  }

	  .hero-media {
		img {
			width: 100%;
			object-fit:cover;
			@media (min-width:1200px) {
				//height: 385.3px;    
			}
			
			@media (min-width:992px) and (max-width:1199px) {
				height: 300px;
			}
			
			 
		}	
	   }
		
		margin-bottom:30px;	
		
		p.clr-white {
			color:#fff !important;
		} 
	}

 
	.hero-card-dark-info {	
		max-width: 505px;
		img {
			margin-bottom: 10px;
		}
		p {
			color: #000;
			font-size: 14px;
			max-width: 468px;
		}

		h5 {
			margin: 20px 0;
		}
		.social-block {
			text-align: left;
			p {
				margin: 0 0 10px 0;
				color: #007672;
				font-weight: 700;
				font-size: 16px;
			}

			li {
				margin: 0 15px 0 0;
				i {
					color: #007672;
					font-size: 22px;
				}
			}
		}

		@media(max-width: 992px) {
			padding-bottom: 32px;
		}
	}
}

@media(max-width: 992px) {
	.hero-card {
		&.hero-card-small {
			.hero-media-info {
				padding: 24px 40px 32px;
			}
		}
		&.hero-card-large {
			&.full-width {
				padding: 0px 0 50px 0;
			}

			.hero-media-info {				 
				h4 {
					margin-top: 12px;
				}

				.body1 {
					margin-top: 20px;
				}
				p,.body2 {
					margin-top: 12px;
				}
			}
		}
		&.hero-card-medium {
			.hero-media-info {
				padding: 24px 44px 32px;
				h4 {
					margin-top: 12px;
				}

				.body1 {
					margin-top: 20px;
				}
				p,.body2 {
					margin-top: 12px;
				}
			}
		}
	}
}
@media(max-width: 767px) {
    .hero-card {						
			&.hero-card-large {
				&.full-width  {
					padding: 8px 0 0 0;
				}
				.hero-media-info {
					padding: 24px 44px 32px;
				}

				&.without-padding {
					.container {
						padding: 0;
						.row {
							margin: 0;
						}	
						.hero-media {
							padding: 0;
						}

						.hero-media-info {
							padding: 0 20px;
						}
					}
					 
				}
			}	

			.hero-card-dark-info {
				h5 {
					margin: 20px 0;
				}
				h5,
				p {				
					font-size: 16px;
				}
			}
    }
    
    .hero-card.hero-card-medium .hero-media-info .btn-primary {
		padding: 9px 15px;
		font-size: 15px;
	}
}

@media (min-width:768px) and (max-width:991px) {
    .hero-card.hero-card-small {
      .hero-media-info {          
        //   h5 {
        //       font-size: 16px;
        //   }
        //   p {
        //       font-size:14px;
        //       margin-bottom:5px;
        //       line-height:16px;
        //   }
      }	
   }
   
   .hero-card.hero-card-large {
      .hero-media-info {       
		padding: 24px 44px 32px;                    
      }	
   }

   
}