.owl-nav button {
    position: absolute;
    top: 50%;
    background-color: #000;
    color: #fff;
    margin: 0;
    transition: all 0.3s ease-in-out;
  }
  .owl-nav button.owl-prev {
    left: -73px;
  }
  .owl-nav button.owl-next {
    right: -73px;
  }
  
  .owl-dots {
    text-align: center;
    padding-top: 15px;
  }
  .owl-dots button.owl-dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    background: #ccc;
    margin: 0 3px;
  }
  .owl-dots button.owl-dot.active {
    background-color: #000;
  }
  .owl-dots button.owl-dot:focus {
    outline: none;
  }
  .owl-nav button {
      position: absolute;
      top: 45%;
      transform: translateY(-50%);
  }
  
  .owl-nav button:focus {
      outline: none;
  }
  
  body .owl-carousel .owl-item img {
      display: block;
      width: auto;
  }
  
  
  body .owl-nav button {
      height: 45px;
      width: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: #004E7D !important;
      color: #fff !important;
  }
  
  body .owl-nav button span {
      font-size: 0;
  }
  
  .owl-nav button.owl-next::before {
      content: "\f105";
      position: absolute;
      font-family: 'FontAwesome';
      font-size: 30px;
      left: 19px;
      top: 9px;
  }
  
  .owl-nav button.owl-prev::before {
      content: "\f104";
      position: absolute;
      font-family: 'FontAwesome';
      font-size: 30px;
      left: 16px;
      top: 9px;
  }
  
  body .owl-dots .owl-dot {
      background-color: #0b7eb5 !important;
      border-radius: 4px;
      height: 15px;
      width: 12px;
  }
  
  body .owl-dots .owl-dot.active {
      background-color: #004e7d !important;
  }
  
  
  @media (max-width:767px) {
  .common-boxes-wrap .owl-slider .owl-nav {
      display:none;
  }	
  }
  
  @media (min-width:768px) {
    .owl-dots {	
      display:none;	
    }	
  }	