
/* btn style */

.btn {
  &.btn-primary {
    background: $orange-color;
    border-color: $orange-color;
    text-transform: uppercase;
    font-size: 20px;
    padding: 9px 25px 10px 25px;
    border-radius: 4px;
    transition: 0.3s all;
    font-weight: 700;
		position: relative;
	svg,
	.svg-outer,
	span,
	i {
		display: none;
	}
    &:hover {
      background: #a4050d;
      border-color: #a4050d;
    }

    &:active {
      background: #a4050d;
      box-shadow: none !important;
      border-color: #a4050d;        
    }
    &:focus {		 
	  box-shadow: none;
	  background: #a4050d;
    border-color: #a4050d;
      &:after {
        border: 2px dotted #000;
        position: absolute;
        left: -6px;
        top: -6px;
        content: "";
        width: calc(100% + 12px);
        height: calc(100% + 12px);
        pointer-events: none;
        border-radius: 4px;
      }
    }
	}
	
	&.btn-success {
    background: $donate-banner-bg;
    border-color: $donate-banner-bg;
    text-transform: uppercase;
    font-size: 20px;
    padding: 9px 25px 10px 25px;
    border-radius: 4px;
    transition: 0.3s all;
    font-weight: 700;
	position: relative;
	color: #fff;
	svg,
	.svg-outer,
	span,
	i {
		display: none;
	}
    &:hover {
      background: #133930;
	  border-color: #133930;
	  color: #fff;
    }
    &:active {
      background: #133930 !important;
      box-shadow: none !important;
      border-color: #133930 !important;        
    }
    &:focus {		 
	  box-shadow: none;
		background: #133930;
	  border-color: #133930;
      &:after {
        border: 2px dotted #000;
        position: absolute;
        left: -6px;
        top: -6px;
        content: "";
        width: calc(100% + 12px);
        height: calc(100% + 12px);
        pointer-events: none;
        border-radius: 4px;
      }
    }
  }
   
  &.find-store-btn {
		position: relative;
		padding: 0;
		::-webkit-input-placeholder { /* Edge */
			color: #000;
			opacity: 1;
			text-transform: uppercase;
		}
	
		:-ms-input-placeholder { /* Internet Explorer 10-11 */
			color: #000;
			opacity: 1;
			text-transform: uppercase;
		}
		
		::placeholder {
			color: #000;
			opacity: 1;
			text-transform: uppercase;
		}

	
		input {
			height: 48px;
			background: #f0f0f0;
			border: none;
			border-radius: 50px;
			padding: 0 24px 0 42px;
			text-align: center;
			width: 100%;
			text-transform: uppercase;
			font-weight: 700;
			transition: all 0.5s ease-in-out;
			font-size: 13px;
			width:164px;
			text-align: left;
			&:focus {
				outline:none;
				width: 225px;
				transition: all 0.5s ease-in-out;
				padding: 0 24px 0 44px;
				text-align:left;

			}
			
			&:hover {
			  background:#cccccc;
			}
			
			&:focus + .fa-map-marker + .arrow-right {opacity:1;}
			&:focus + .fa-map-marker {display:none;}
		}
    
    
    .fa-map-marker {
      color: #E1251B;
      font-size: 18px;
      margin: 0px 6px 0 0px;
			position: absolute;
			left: 25px;
			top: 15px;
    }

		.arrow-right {
			position: absolute;
			right: 16px;
			top: 13px;
			background: #E1251B;
			color: #fff;
			border-radius: 50%;
			font-size: 14px;
			width: 22px;
			height: 22px;
			text-align: center;
			opacity: 0;
		}		

		&.active {
			.fa-map-marker {
				display: none;
			}

			.arrow-right {
			 	opacity: 1;			 
			}
		}

		div  {
			div {
				padding-top: 1px;
				text-align: left;
			}
			.items {
				font-size: 13px;
			}
		}

		input + div:nth-child(1) {
			left: auto !important;
			right: 20px !important;
		}
  } 

  &.btn-secondary {
    background: none;
    border: 2px solid $orange-color;
    text-transform: uppercase;
    font-size: 16px;
    padding: 9px 25px 10px 25px;
    border-radius: 4px;
    transition: 0.3s all;
    font-weight: 700;
    position: relative;
	color: $orange-color;
	svg,
	.svg-outer,
	span,
	i {
		display: none;
	}
    &:hover {
      background: $orange-color;
	  	color:#fff;
    }
    &:active {
      background: $orange-color !important;
      box-shadow: none !important;
      border-color: $orange-color !important;        
    }
    &:focus {		 
      box-shadow: none;
			background: $orange-color;
      &:after {
        border: 2px dotted #000;
        position: absolute;
        left: -6px;
        top: -6px;
        content: "";
        width: calc(100% + 12px);
        height: calc(100% + 12px);
        pointer-events: none;
        border-radius: 4px;
      }
    }
  }
}




.large-text-cta {
  font-size: 20px;
	border-bottom: 2px solid;
	text-decoration: none;
	padding-bottom: 5px;
	transition: all 0.5s ease-in-out;
	position: relative;	
	font-weight: 700;
	
	
	&.cta-white {
		border-bottom: 2px solid #fff;
		transition: all 0.5s ease-in-out;
		color:#fff;
		
		&::after {
			content:"";
			background: url(../images/link-layer-white.png);
			left: 0;
			opacity: 0;
			bottom: -7px;
			height: 10px;
			width: 100%;
			position: absolute;
			transition: all 0.5s ease-in-out;
			background-repeat: repeat-x;
		}
		
		&:hover {
			text-decoration: none;
			border-bottom:2px solid transparent;
			
			&:after {
				opacity: 1;
				transition: all 0.5s ease-in-out;
			}
		}
		
		&:focus {		 
		  box-shadow: none;
		  &:before {
			border: 2px dotted #fff;
			position: absolute;
			left: -12px;
			top: -9px;
			content: "";
			width: calc(100% + 24px);
			height: calc(100% + 24px);
			pointer-events: none;
			border-radius: 4px;
		  }
		}
	}
	
	&.cta-black {
		border-bottom: 2px solid #1c1c1c;
		color:#1c1c1c;
		transition: all 0.5s ease-in-out;
		
		&::after {
			content:"";
			background: url(../images/link-layer-blue.png);
			left: 0;
			background-repeat: repeat-x;
			opacity: 0;
			bottom: -7px;
			height: 10px;
			width: 100%;
			position: absolute;
			transition: all 0.5s ease-in-out;
		}
		
		&:hover {
			text-decoration: none;
			border-bottom:2px solid transparent;
			
			&:after {
				opacity: 1;
				transition: all 0.5s ease-in-out;
			}
		}
		
		&:focus {		 
		  box-shadow: none;
		  &:before {
			border: 2px dotted #1c1c1c;
			position: absolute;
			left: -12px;
			top: -9px;
			content: "";
			width: calc(100% + 24px);
			height: calc(100% + 24px);
			pointer-events: none;
			border-radius: 4px;
		  }
		}
	}
	
	
	

		
}



.small-text-cta {
    font-size: 16px;
	border-bottom: 2px solid;
	text-decoration: none;
    padding-bottom: 5px;
    transition: 0.3s all;
	position: relative;	
	
	&.bold {
		font-weight: bold;
	}
	&.cta-white {
		border-bottom: 2px solid #fff;
		color:#fff;
		
		&::after {
			content:"";
			background: url(../images/link-layer-white.png);
			left: 0;
			background-repeat: repeat-x;
			opacity: 0;
			bottom: -7px;
			height: 10px;
			width: 100%;
			position: absolute;
			transition: all 0.5s ease-in-out;
		}
		
		&:hover {
			text-decoration: none;
			transition: all 0.5s ease-in-out;
			border-bottom: 2px solid transparent;
			
			&:after {
				opacity: 1;
			}
		}
		
		&:focus {		 
		  box-shadow: none;
		  &:before {
			border: 2px dotted #fff;
			position: absolute;
			left: -12px;
			top: -9px;
			content: "";
			width: calc(100% + 24px);
			height: calc(100% + 24px);
			pointer-events: none;
			border-radius: 4px;
		  }
		}
	}

	&.cta-black {
		border-bottom: 2px solid #1c1c1c;
		color:#1c1c1c;
		
		&::after {
			content:"";
			background: url(../images/link-layer-blue.png);
			left: 0;
			background-repeat: repeat-x;
			opacity: 0;
			bottom: -7px;
			height: 10px;
			width: 100%;
			position: absolute;
			transition: all 0.5s ease-in-out;
		}
		
		&:hover {
			text-decoration: none;
			border-bottom:2px solid transparent;
			
			&:after {
				opacity: 1;
			}
		}
		
		&:focus {		 
		  box-shadow: none;
		  &:before {
			border: 2px dotted #1c1c1c;
			position: absolute;
			left: -12px;
			top: -9px;
			content: "";
			width: calc(100% + 24px);
			height: calc(100% + 24px);
			pointer-events: none;
			border-radius: 4px;
		  }
		}
	}	
}


.icon-text-cta {
  display: flex;	
  a {
    font-size: 24px;
	font-weight: 700;
	transition: all 0.5s ease-in-out;
	position:relative;
	border-bottom: 2px solid;
  }	
  
  &.text-white {
	a {
		color:#fff;
		border-bottom: 2px solid #fff;
		padding-bottom:2px;
		&::after {
			content:"";
			background: url(../images/link-layer-blue.png);
			left: 0;
			background-repeat: no-repeat;
			opacity: 0;
			bottom: -7px;
			height: 10px;
			width: 100%;
			position: absolute;
			transition: all 0.5s ease-in-out;
		}
		
		&:hover {
			text-decoration: none;
			border-bottom:2px solid transparent;
			color:#003A5D;
			
			&:after {
				opacity: 1;
			}
		}
		
		&:focus {		 
		  box-shadow: none;
		  color:#003A5D;
		  &:before {
			border: 2px dotted #003A5D;
			position: absolute;
			left: -9px;
			top: -9px;
			content: "";
			width: calc(100% + 24px);
			height: calc(100% + 24px);
			pointer-events: none;
			border-radius: 4px;
		  }
		}
	}
  }
  
  .icon-media {
	margin-right:20px;
  }
} 

.read-more-link {  
  font-size:13px;
  color:#656565;
  text-decoration:underline;
	text-decoration-color: #656565;
	display: inline-block;
	svg,
	.svg-outer,
	span,
  i {
	  display: none;
  }
}

.tag-style {
    color: #fff;
    text-transform: uppercase;
    padding: 6px 12px;    
    display: inline-block;
	font-size:13px;
	line-height:13px;
	font-weight: 700;
	margin-bottom: 16px;
	&.tag-bg1 {
		background: #00968F;
	}
	&.tag-bg2 {
		background: #004E7D;
	}
	&.tag-bg3 {
		background: #528B1A;
	}
	&.tag-bg4 {
		background: #00A1E4;
	}
}

.tag-style.style2 {
	font-size: 16px;
	padding: 11px 20px 10px 20px;
	line-height: 100%;
	height: 39px;
	margin: -10px 0 12px 0;
	background: #fff;
	color: #000;
}

.cta-btn-wrap {
	display: flex;
	
	a:first-child {
		margin-right: 15px;
	}

	@media(max-width: 767px) {
		display: block;
		a:first-child {
			margin-right: 0px;
			margin-bottom: 20px;
		}
	}
}

/*for french */
.lang-fr {
	@media(min-width: 1300px) {
		.btn.find-store-btn {
			input {
				width: 224px;
			}
		}
	}
	 
}