.-impact-right-stats { 
  padding-top: 20px;
  .fixed-img {    
    .impact-header {
      float: left;
      width: 100%;
      .hidden-para {
        font-size: 16px;
        line-height: 23px;
        position: absolute;
        left: 0;
        top: 25%;
        transform: translate(-25%);
        opacity: 0;
      }

      position: relative;
      img {
        position: absolute;
        left: -70px;
        top: -20px;
       

      }
    }
    .inner {
      float: right;
      width: auto;
      text-align: left;
      
    }
    .solid-heading {
      margin: 5px 0 5px 0;
      font-size: 56px;
      line-height: 50px;
      letter-spacing: 0;
      span {
        display: inline-block;
        background: #0077C8;
        color: white;
        padding: 20px 36px;
        text-align: left;
      }
    }
    p {
      display: inline-block;
      margin: 0;
      &:nth-child(5) {
        float: right;
      }
    }

  }
}
.impact-right-stats {
    width: 100%;
    clear: both;
    width: 100%;
  
   

    .two-col-with-img {
      display: flex;
      align-items: center;
      margin-bottom: 120px;
      .img-col {
        width: 50%;
        position: relative;
        .animatted-img {
          position: absolute;
          left: -30px;
          bottom: 0;
          z-index: 1;
          max-width: none;
          width: 600px;     
          transform: translate(-22%, 0);
        }
        img {
          z-index: 2;
          position: relative;
        }
        .inner {
          display: inline-block;
          overflow: hidden;
          position: relative;
          &.circel {
            border-radius: 50%;       
            img.animatted-img {
              width: 1084px;
              height: 624px;
              bottom: -40px;
              max-width: none;
              left: -30px;
              transform: translate(-50%,0%);
            }        
          }
        }
        h2 {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
          color: #fff;
          background: $primary-color;
          z-index: 3;
          padding: 6px 20px;
        }
      }
  
      .info-col {
        width: 50%;
       
      }
  
      .info-col-inner {
        padding-left: 50px;
        p {
          font-size: 20px;
          color: $n-blue;
          padding-right: 55px;
          margin-bottom: 5px;
        }
        .inline {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 35px;
          span {
            display: block;
            font-size: 25px;
            font-weight: 700;
            color: $n-blue;
            p {
              display: none;
            }
            &.large {
              font-size: 70px;
              line-height: 70px;
              margin-right: 15px;
              font-weight: 800;
              color: $n-blue
            }
  
            img {
              margin-left: 15px;
            }
          }
        }
      }
    }

  }

  @media(max-width: 992px) {
    .-impact-right-stats {
      .fixed-img {
        .inner {
          text-align: right;
        }
        .impact-header {
          .hidden-para {
            left: 50px;
          }

          img {
            left: 0;
          }
        }
      }
    }
  }

  @media(max-width: 767px) {
    .-impact-right-stats {
      padding-top: 120px;
      .fixed-img {
        p:nth-child(5) {
          float: none;
        }
        .solid-heading {
          margin: 24px 0 32px 0;
          font-size: 48px;
          line-height: 48px;
          text-align: center;
          span {
            background: #004E7D;
          }
        }
        .inner {
          text-align: center;
        }
        .impact-header {
          .hidden-para {
            opacity: 1;
            position: static;
            transform: translate(0);
            margin-bottom: 48px;
          }

          img {
            left: -160px;
            position: relative;
            margin-bottom: 60px;
            top: 0;
          }
        }
      }
      .three-col-with-img {
        margin-bottom: 44px;
      }
    }

    .impact-right-stats .two-col-with-img .img-col .inner.circel img.animatted-img {
      bottom: -50px;
    }
    .-impact-right-stats {
      .impact-left-inner {
        .impact-header {
          margin-bottom: 0;
        }
      }

      .impact-head {
        margin-bottom: 38px;
      }
    }

    .impact-right-stats {
      .two-col-with-img {
        display: block;
        margin-bottom: 80px;

        .img-col {
          width: 100%;
          margin-bottom: 52px;
        }
        .info-col {
          width: 100%;
          
          .info-col-inner {
            padding-left: 0px;
            p {
              margin-bottom: 24px;
              padding-right: 0;
            }
            .inline {
              margin-bottom: 40px;
            }
          }
        }
        &:nth-child(2) {
          margin-bottom: 8px;
          .info-col {
            .info-col-inner {
              p {
                margin-bottom: 48px; 
              }
            }
          }
        }
      }
    }
  }