.termly-consent-banner-termly-banner-774e7d, 
.termly-consent-banner-termly-tooltip-7ac9fe,
.termly-consent-banner-termly-banner-774e7d .termly-consent-banner-content-ec96bb > p,
.termly-consent-banner-termly-banner-774e7d .termly-consent-banner-content-ec96bb > p > span,
.termly-consent-banner-termly-banner-774e7d .termly-consent-banner-content-ec96bb > p > span span,
.termly-consent-banner-preference-modal-a864ac,
.termly-consent-banner-termly-banner-774e7d.termly-consent-banner-bottom-7115a9 {
  background:#E1251B!important;
}

.termly-consent-banner-termly-banner-774e7d .termly-consent-banner-content-ec96bb > p .termly-consent-banner-btn-container-60889f .termly-consent-banner-preference-button-14dc74 button {
  border: 1px solid #fff !important;
  background: none !important;
  color: #fff !important;
  transition: all ease-in-out 0.3s;
}

.termly-consent-banner-termly-banner-774e7d .termly-consent-banner-content-ec96bb > p .termly-consent-banner-btn-container-60889f .termly-consent-banner-preference-button-14dc74 button:hover {
  background: #fff !important;
  color: #E1251B !important;
  transition: all ease-in-out 0.3s;
}

.termly-style-cookie-preference-44d002,
.termly-consent-banner-termly-banner-774e7d .termly-consent-banner-content-ec96bb > p .termly-consent-banner-btn-container-60889f button {
  background: #fff !important;
  color: #E1251B !important;
  transition: all ease-in-out 0.3s;
  border: 1px solid #fff !important;
}

.termly-style-cookie-preference-44d002:hover,
.termly-consent-banner-termly-banner-774e7d .termly-consent-banner-content-ec96bb > p .termly-consent-banner-btn-container-60889f button:hover {
  background: none !important;
  color: #fff !important;
  transition: all ease-in-out 0.3s;
  
}
.termly-consent-banner-content-ec96bb {
  background: none !important;
}
.termly-cookie-preference-button {
  border: 0px solid $cookie-button-color !important;
  color: $cookie-button-color !important;
  text-shadow: none !important;
  font-weight: 400 !important;
  background: #fff !important;
  font-size: 12px !important;
  width: auto !important;
  height: auto !important;
  padding: 5px 15px !important;
  margin-top: 10px;
  transition: all ease-in-out 0.3s;
  font-family: "Roboto", Arial, sans-serif;
  border-radius: 3px;
  box-sizing: border-box;

  @media(max-width: 992) {
    margin-top: 24px;
  }
}

.termly-cookie-preference-button.second-avenue {
  color: #0057B7 !important;

  &:hover {
    color: #fff !important;
    background: #0057B7 !important;
  }
}

.termly-cookie-preference-button.greendrop {
  color: #006341 !important;

  &:hover {
    color: #fff !important;
    background: #006341 !important;
  }
}

.termly-cookie-preference-button:hover {
  color: #fff !important;  
  background: $cookie-button-color !important;
  transition: all ease-in-out 0.3s; 
}



.termly-consent-banner-preference-modal-a864ac .termly-consent-banner-close-tag-container-953f6e .termly-consent-banner-close-tag-356e10 {
  background: url("../images/svg/close-white.svg")no-repeat !important;
  background-size: 15px !important;
  height: 15px !important;
  width: 15px !important;
  opacity: 1;
}