.orange-layer3 {
  position: absolute;
  right: -240px;
  top: -172px;
  z-index: 1;
}

.orange-layer1 {
  position: absolute;
  right: -180px;
  top: -20px;
  z-index: 1;
}

.orange-layer2 {
  position: absolute;
  left: 318px;
  top: -109px;
  z-index: 1;
}

.right-impact-testimonials {
  position: relative;
  &.with-slider {
    display: none;
  }
  clear: both;  
    .testimonilas-outer {
      position: relative;     
      height: 460px;
      .desktop-bg.one {
        max-width: none;
        width: 1000px;
        left: -95px;
        position: relative;
        top: -20px;
      }
      .desktop-bg {
        @media(max-width: 1366px) {
          width: 100%;
        }
      }
      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
       
       
        .user-name {
          position: absolute;
          left: 0;         
          padding-top: 8px;
          padding-bottom: 8px;
          width: auto;
          overflow: hidden;

          .inner {
            background: #0077C8;
            display: flex;
            z-index: 1;
            text-align: center;
           
          }
         
          i {
            color: #fff;
            font-size: 24px;
            margin-top: 7px;
          }
          .icon {
            background: #084F7B;
            width: 34px;
            position: relative;
            &:after {             
              border-left: 8px solid transparent;
              border-right: 8px solid transparent;
              content: "";
              position: absolute;
              left: 50%;            
              transform: translate(-50%);
            }
          }

          .name {
            color: #fff;
            font-size: 20px;
            padding: 7px 14px 8px 14px;
            font-weight: 500;
          }
        }
        p {
          color: $n-blue;
          line-height: 28px;
          letter-spacing: -1px;
          position: relative;
          left: -20px;
          span {
            font-weight: 700;
            color: #f0f0f0;
            display: inline-block;
            font-size: 125px;
            position: absolute;           
            height: 43px;
            width: 62px;
            line-height: 85px;
          }
          
        }
  
        &.bg1 {
          background: url("../images/svg/teal-texture1.svg")no-repeat;
          background-size: cover;
          background-position: center;
          height: 334px;
          width: 388px;
          left: -90px;
          p {
            width: 200px;
            text-align: center; 
            .quote-left {
              left: -35px;
              top: -20px;
            }

            .quote-right {
              right: -12px;
              bottom: -20px;
            }
          }

          .user-name {
            bottom: 40px;
            left: 75px;
            .icon {
              &:after {
                top: -8px;
                border-bottom: 8px solid #084F7B;
              }
            }
          }
        }
  
        &.bg2 {
          background: url("../images/svg/teal-texture2.svg")no-repeat;
          background-size: cover;
          background-position: center;
          height: 270px;
          width: 360px;
          left: 225px;
          top: 20px;
          p {
            width: 240px;
            text-align: center;
            left: 0;
            .quote-left {
              left: -42px;
              top: -20px;
            }

            .quote-right {
              right: -27px;
              bottom: -20px;
            }
          }

          .user-name {
            top: -25px;
            left: 75px;
            .icon {
              &:after {
                bottom: -8px;
                border-top: 8px solid #084F7B;
              }
            }
          }
        }
        &.bg3 {
          background: url("../images/svg/teal-texture3.svg")no-repeat;
          background-size: cover;
          background-position: center;
          height: 290px;
          width: 358px;
          left: 585px;
          top: 20px;
          p {
            width: 220px;
            text-align: center;
            left: 0;
            .quote-left {
              left: -43px;
              top: -20px;
            }

            .quote-right {
              right: -17px;
              bottom: -45px;
            }
          }

          .user-name {
            bottom: -10px;
            left: 95px;
            .icon {
              &:after {
                top: -8px;
                border-bottom: 8px solid #084F7B;
              }
            }
          }
        }
        &.bg4 {
          background: url("../images/svg/teal-texture4.svg")no-repeat;
          background-size: cover;
          background-position: center;
          height: 251px;
          width: 328px;
          top: 70px;
          left: -30px;
          p {
            width: 195px;
            text-align: center;
            left: 0;
            .quote-left {
              left: -58px;
              top: -19px;
            }
            .quote-right {
              right: -18px;
              bottom: -25px;
            }
          }
          .user-name {
            bottom: 25px;
            left: 60px;
            .icon {
              &:after {
                top: -8px;
                border-bottom: 8px solid #084F7B;
              }
            }
          }
        }
        &.bg5 {
          background: url("../images/svg/teal-texture5.svg")no-repeat;
          background-size: cover;
          background-position: center;
          height: 307px;
          width: 340px;
          left: 270px;
          top: 40px;
          p {
            width: 240px;
            text-align: center;
            left: 20px;
            .quote-left {
              left: -50px;
              top: -20px;
            }
            .quote-right {
              right: 0;
              bottom: -24px;
            }
          }
          .user-name {
            top: -7px;
            left: 55px;
            .icon {
              &:after {
                bottom: -8px;
                border-top: 8px solid #084F7B;
              }
            }
          }
        }
        &.bg6 {
          background: url("../images/svg/teal-texture6.svg")no-repeat;
          background-size: cover;
          background-position: center;
          height: 298px;
          width: 332px;
          left: 610px;
          top: 40px;
          p {
            width: 240px;
            text-align: center;
            left: 0;
            .quote-left {
              left: -39px;
              top: -20px;
            }
            .quote-right {
              right: -28px;
              bottom: -20px;
            }
          }
          .user-name {
            bottom: 20px;
            left: 35px;
            .icon {
              &:after {
                top: -8px;
                border-bottom: 8px solid #084F7B;
              }
            }
          }
        }
        &.bg7 {
          background: url("../images/svg/teal-texture7.svg")no-repeat;
          background-size: cover;
          background-position: center;
          height: 377px;
          width: 825px;
          left: 120px;
          top: -160px;
          p {
            width: 580px;
            text-align: center;
            left: 0;
            top: 20px;
            .quote-left {
              left: -65px;
              top: -19px;
            }
            .quote-right {
              right: 80px;
              bottom: -18px;
            }
          }
          .user-name {
            bottom: 60px;
            left: 235px;
            .icon {
              &:after {
                top: -8px;
                border-bottom: 8px solid #084F7B;
              }
            }
          }
        }
        &.bg8 {
          background: url("../images/svg/teal-texture8.svg")no-repeat;
          background-size: cover;
          background-position: center;
          height: 233px;
          width: 419px;
          left: 120px;
          top: 20px;
          z-index: 2;
          p {
            width: 230px;
            text-align: center;
            left: 0;
            top: 0;
            .quote-left {
              left: -67px;
              top: -18px;
            }
            .quote-right {
              right: -35px;
              bottom: -20px;
            }
          }
          .user-name {
            bottom: 15px;
            left: 90px;
            .icon {
              &:after {
                top: -8px;
                border-bottom: 8px solid #084F7B;
              }
            }
          }
        }
        &.bg9 {
          background: url("../images/svg/teal-texture9.svg")no-repeat;
          background-size: cover;
          background-position: center;
          height: 460px;
          width: 419px;
          left: 530px;
          top: -25px;
          p {
            width: 160px;
            text-align: center;
            left: 0;
            top: -30px;
            .quote-left {
              left: -65px;
              top: -19px;
            }
            .quote-right {
              right: -10px;
              bottom: -22px;
            }
          }
          .user-name {
            bottom: 120px;
            left: 90px;
            flex-direction: row-reverse;
            .icon {
              &:after {
                top: -8px;
                border-bottom: 8px solid #084F7B;
              }
            }
          }
        }
        &.bg10 {
          background: url("../images/svg/teal-texture10.svg")no-repeat;
          background-size: cover;
          background-position: center;
          height: 272px;
          width: 335px;
          left: -10px;
          top: 220px;
          p {
            width: 190px;
            text-align: center;
            left: 0;
            top: 20px;
            .quote-left {
              left: -65px;
              top: -19px;
            }
            .quote-right {
              right: -26px;
              bottom: -24px;
            }
          }
          .user-name {
            bottom: -3px;
            left: 80px;
            .icon {
              &:after {
                top: -8px;
                border-bottom: 8px solid #084F7B;
              }
            }
          }
        }
        &.bg11 {
          background: url("../images/svg/teal-texture11.svg")no-repeat;
          background-size: cover;
          background-position: center;
          height: 466px;
          width: 435px;
          left: 310px;
          top: 180px;
          p {
            width: 160px;
            text-align: center;
            left: 0;
            top: -10px;
            .quote-left {
              left: -52px;
              top: -19px;
            }
            .quote-right {
              right: -38px;
              bottom: -24px;
            }
          }
          .user-name {
            bottom: 80px;
            left: 115px;
            .icon {
              &:after {
                top: -8px;
                border-bottom: 8px solid #084F7B;
              }
            }
          }
        }
      }

      &.below-slides {
        height: auto;
      }

    }
  
    &.v2 {
      height: 700px;
      .layer4{
        position: absolute;
        left: -39px;
        top: 141px;
      }
      .layer5 {
        position: absolute;
        left: 150px;
        top: 20px;
      }
      .layer6{
        position: absolute;
        right: 130px;
        top: 103px;
      }
    }
    &.v3 {
      height: 700px;
      .layer7 {
        position: absolute;
        bottom: -100px;
        left: -35px;
        z-index: 1;
        pointer-events: none;
      }

      .layer8 {
        position: absolute;
        left: 180px;
        z-index: 1;
        top: 140px;
      }
      .layer9 {
        position: absolute;
        right: 0;
        top: -100px;
      }
    }


    &.square {

      

      .testimonilas-outer  {
        padding: 50px 140px;
        background: #f0f0f0;
        text-align: center;
        position: relative;
        height: auto;
        p {
          margin-bottom: 22px;
          position: relative;
          .quote-left {            
            font-weight: 700;
            color: #0077C8;
            display: inline-block;
            font-size: 125px;
            position: absolute;
            height: 43px;
            width: 62px;
            line-height: 85px;
          }
          .quote-right {            
            font-weight: 700;
            color: #0077C8;
            display: inline-block;
            font-size: 125px;
            position: absolute;
            height: 43px;
            width: 62px;
            line-height: 85px;
          }
        }

        .name {
          margin: 0 !important;
          color: #003B5C;
          font-weight: 700;
          span {
            display: block;
          }
        }
        &.square1 {
          p {
            .quote-left {
              left: -61px;
              top: -17px; 
            }
            .quote-right {  
              right: -38px;
              bottom: -20px;
             }
          }

          img {
            height: 196px;
            width: 196px;
            border-radius: 50%;
            object-fit: cover;
            object-position: center;
            position: absolute;
            left: -76px;
            bottom: -55px;
          }

          
        }

        &.square2 {
          padding: 48px 84px;
          width: 620px;        
          float: right;
          margin-bottom: 50px;
          p {
            margin-bottom: 20px;
            .name {
              margin-bottom: 0;
            }
          }

          img {
            height: 240px;
            width: 240px;
            border-radius: 50%;
            object-fit: cover;
            object-position: center;
            position: absolute;
            left: -120px;
            bottom: -30px;
          }

          .quote-left {
            left: -70px;
            top: -19px;
          }
          .quote-right {
            right: -37px;
            bottom: -21px;
          }
        }
      }
    }

    .static-container {
      width: 1050px;
      display: flex;
      margin-left: -80px;
      .testimonilas-outer {       
        padding: 48px 82px;
        &.square3 {
          padding: 48px 72px;
        }
      }

      .left-block {
        width: 512px;
        margin-right: 40px;  

       .square3 {
         padding-left: 84px;
         padding-right: 84px;
       }
        
        .quote-left  {
          left: -70px;
          top: -19px;
        }

        .quote-right  {
          bottom: -20px;
          right: -63px;
        }
      }

      .right-block {
        width: calc(100% - 472px); 
        .square3 {
          padding-left: 90px;
          padding-right: 90px;
        }
        .quote-left  {
          left: -78px;
          top: -19px;
        }

        .quote-right  {
          bottom: -20px;
          right: -70px;
        }
        
      }
    }
  }
  
  .right-impact-testimonials{
    &.v1 {
      .layer4,
      .layer5,
      .layer6,
      .layer7,
      .layer8,
      .layer9 {
        display: none;
      }
    }
    &.v2 {
      .desktop-bg,
      .layer7,
      .layer8,
      .layer9 {
        display: none;
      }
    }
    &.v3 {
      .desktop-bg,
      .layer4,
      .layer5,
      .layer6 {
        display: none;
      }
    }

  }

  @media(max-width: 1366px) {  
    .orange-layer3 {      
      right: -100px;
      top: -112px;
      z-index: 0;
    }  
    .orange-layer1 {      
      right: 0;
      top: -20px;
      z-index: 0;
    }

    .orange-layer5 {    
      left: 120px;
      top: -10px;
    }

    .right-impact-testimonials {
      .testimonilas-outer {
        .item {
          p {
            font-size: 16px;
            line-height: 24px;
            span {
              font-size: 105px;              
              height: 39px;
              width: 56px;
              line-height: 75px;
            }
          }

          .user-name {
            .name {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
        .item.bg1 {
          height: 314px;
          width: 298px;
          p {
            width: 165px;
          }
          .user-name {
            left: 32px;
          }
        }
        .item.bg2 {
          left: 166px;
          top: 20px;
          height: 250px;
          width: 330px;
          p {
            width: 180px;
          }
        }
        .item.bg3 {
          left: 500px;
          top: 10px;
          height: 250px;
          width: 308px;
          p {
            width: 180px;
            .quote-right {
              right: -57px;
              bottom: -20px;
            }
          }
        }
        .item.bg4 {
          left: -70px;
          top: 30px;
          height: 211px;
          width: 280px;
          p {
            width: 170px;
            .quote-left {
              left: -42px;           
            }

            .quote-right {
              right: 6px;
              bottom: -19px;
            }
          }
          .user-name {
            bottom: 5px;
            left: 55px;
          }
        }
        .item.bg5 {
          left: 190px;
          top: 0px;
          height: 277px;
          width: 300px;
          p {
            width: 210px;
            .quote-right {
              right: 5px;
              bottom: -19px;
            }
          }
        }
        .item.bg6 {
          left: 510px;
          top: 0px;
          p {
            width: 200px;
            .quote-left {
              left: -55px;
              top: -18px;
            }
            .quote-right {
              right: -58px;
              bottom: -17px;
            }
          }
        }
        .item.bg7 {
          left: 20px;  
          width: 705px;
          height: 317px;
          p {
            width: 510px;
          }   
          
          .user-name {
            bottom: 40px;
            left: 185px;
          }
        }
        .item.bg8 {
          left: 80px;
          top: 10px;     
          height: 213px;
          width: 369px;
          p {
            width: 180px;
            .quote-left {
              left: -57px;            
            }
          }     
        }
        .item.bg9 {
          left: 450px;  
          height: 410px;
          width: 379px;
          p{
            width: 140px;
            .quote-left {
              left: -49px;              
            }
            .quote-right {
              right: -46px;
              bottom: -20px;
            }
          }         
        }
        .item.bg10{
          height: 232px;
          width: 285px;
          p {
            top: 5px;
            width: 170px;
            .quote-right {
              right: 2px;             
            }

            .quote-left {
              left: -50px;              
            }
          }
          .user-name {
            bottom: -8px;
            left: 50px;
          }
        }
        .item.bg11 {
          left: 270px;
          top: 140px;  
          height: 426px;
          width: 355px;
          p {
            width: 140px;
            .quote-left {
              left: -42px;              
            }
            .quote-right {
              right: -35px;
              bottom: -19px;
            }
          }   

          .user-name {          
            left: 75px;
          }
        }
      }

      .static-container {
        width: 940px;
        p {
          font-size: 16px;
          line-height: 24px;
        }
        .left-block {
          width: calc(50% - 20px);
          margin-right: 20px;
        }
        .right-block {
          width: calc(50% - 20px);
          margin-left: 20px;
        }
      }
    }
  }


  @media(max-width: 1199px) {
    .right-impact-testimonials.v2 {
      height: 640px;
    }
    .right-impact-testimonials {
      .static-container {
        margin: 0 auto;
      }
      .testimonilas-outer {
        .item.bg1 {
          left: 0;
        }
        .item.bg2 {
          left: 270px;
        }
        .item.bg3 {
          left: 612px;
        }
        .item.bg4 {
          left: 0px;
        }
        .item.bg5 {
          left: 260px;
        }
        .item.bg6 {
          left: 580px;
        }
      }
    }
  }

  @media(max-width: 992px) { 
    .orange-layer3 {
      right: -40px;
    }

    .right-impact-testimonials {
      display: none;
      &.square {
        display: block;
      }
      &.with-slider {
        display: block;
        .desktop-bg {
          display: none;
        }
  
        .testimonilas-outer {
          margin-bottom: 0px;
          height: auto;
          .slick-arrow {
            display: none !important;
          }
          .slick-track {
            display: flex;
            align-items: center;
          }
          .item {           
            position: relative;
            display: flex !important;
            left: 0;
            top: 0;
          }

          .item.bg2 {
            height: 260px;
          }
          .item.bg3 {
            height: 280px;
          }
          .item.bg4 {
            height: 263px;
          }
          .item.bg5 {
            height: 283px;
          }
          .item.bg6 {
            height: 310px;
          }
          .item.bg8 {
            height: 198px;
          }
          .item.bg9 {
            height: 300px;
          }
          .item.bg10 {
            height: 282px;
          }


         
        }
      }

      &.square {
        .testimonilas-outer {
          &.square1 {
            padding: 58px 140px 140px 140px;
            img {
              left: 50%;
              bottom: -103px;
              transform: translate(-50%);
            }
          }
         
          &.square2 {
            padding: 58px 140px 140px 140px;
            width: 100%;
            margin-bottom: 40px;
            img {
              left: 50%;
              bottom: -103px;
              transform: translate(-50%);
            }
          }

          
        }

        .static-container {
          width: 100%;
          display: block;
          .left-block {
            width: 100%;
            margin-right: 0px;
            margin-bottom: 55px;
          }

          .right-block {
            width: 100%;
            margin-left: 0px;
          }
        }
      }

      &.v2 {
        height: auto;
        .testimonilas-outer {
          margin-bottom: 40px;
          .item {
            .user-name {
              bottom: 5px;
              left: 55px;
            }
          }
        }
      }
      &.v3 {
        height: auto;
        .testimonilas-outer {
          margin-bottom: 40px;
          .item {
            p {
              top: auto;
            }
            .user-name {
              bottom: 5px;
              left: 55px;
            }
          }
        }
      }
    }
  }

@media(min-width: 768px) { 
  .impact-head.-testimonialv2 {
    h2 {
      display: none !important;
    }
   
  }
}
@media(max-width: 767px) { 
   
  .orange-layer1 {
    right: 200px;
    bottom: 270px;
    z-index: 1;
    top: auto;
  }   
  .orange-layer3 {
    right: 0;
  }
  .orange-layer2 {    
    left: 276px;
    top: -197px;
    z-index: 1;
  }

  .right-impact-testimonials.with-slider {
    .testimonilas-outer {
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }
 
  .right-impact-testimonials.with-slider {
    &.v2 {
      .slick-dots {
        bottom: -50px;
        z-index: 2;
      }
    }
    .slick-slider {
      width: 300px;
      margin: 0 auto;
    }
    .slick-list {
      overflow: visible;
    }

    .testimonilas-outer {
      .item.bg2 {
        height: 220px;
      }
      .item.bg3 {
        height: 240px;
      }
      .item.bg4 {
        height: 231px;
      }
      .item.bg6 {
        height: 271px;
        p {
          width: 180px;
        }
      }
      .item.bg7 {
        width: 100%;    
        height: 557px;               
        background: url(../images/teal-texture77.png) no-repeat;
        background-position: 65% center;
        .user-name {
          bottom: 100px;
          left: 55px;
        }
        p {
          width: 220px;
          font-size: 18px;
          top: 0;
          .quote-right {
            right: -35px;
            bottom: -18px;
          }

          .quote-left {
            left: -31px;
            top: -16px;
         }
        }
      }
      .item.bg10 {
        height: 244px;
      }
      .item.bg8 {
        height: 174px;
        .user-name {
          bottom: 0;
        }
      }
      .item.bg9 {
        height: 370px;
      }
      .item.bg11 {
        height: 386px;
      }
    }
  }

  .right-impact-testimonials {
    .impact-head {
      padding: 0 36px;
      &.text-center {
        text-align: left !important;
      }
    }
  }

  .right-impact-testimonials.v2 {
    .layer5 {
     display: none;
    }

    .layer4 {
      position: absolute;
      left: -40px;
      top: 60px;
    }
    .layer6 {
      position: absolute;
      right: -50px;
      top: -80px;
      z-index: 1;
      transform: rotate(347deg);
      width: 175px;
    }

    .below-slides{
      .layer4 {
        position: absolute;
        right: -40px;
        bottom: -50px;
        z-index: 1;
        left: auto;
        top: auto;
        transform: rotate(40deg);
      }
    }
  }
  .right-impact-testimonials.v3 {
    margin-top: -35px;
    .layer7 {
      bottom: 0;
    }

    .layer9 {
      display: none;
    }

    .layer8 {
      position: absolute;
      left: auto;
      z-index: 1;
      top: 0;
      right: -80px;
    }
  }
  
  .right-impact-testimonials.square {
    .static-container {
      p {
        font-size: 18px;
        line-height: 24px;
      }
    } 

    .testimonilas-outer{
      &.square1 {
        padding: 80px 45px 94px 45px;
        margin-bottom: 120px;

        .name {          
          display: inline-block;
          max-width: 250px;
          span {
            margin-bottom: 12px;
          }
        }
        p {
          margin-bottom: 6px;
          .quote-left {
            left: -40px;
            top: -40px;
          }

          .quote-right {
            right: -30px;
            bottom: -44px;
          }
        }
      }
     
      &.square2 {
        padding:  80px 45px 80px 45px;
        margin-bottom: 56px;
        margin-top: 60px;
        p {
          margin-bottom: 6px;
          .quote-left {
            left: -40px;
            top: -43px;
          }

          .quote-right {
            right: -30px;
            bottom: -44px;
          }
        }

        .name {
          display: inline-block;
          max-width: 210px;
        }

        img {
          height: 168px;
          width: 168px;
          bottom: -85px;
        }
      }
      &.square3 {
        padding:  80px 45px 42px 45px;
        margin-bottom: 88px;
        p {
          margin-bottom: 6px;
          .quote-left {
            left: -40px;
            top: -46px;
          }

          .quote-right {
            right: -30px;
            bottom: -50px;
          }
        }

        .name {
          display: inline-block;
          max-width: 200px;
        }

        img {
          height: 168px;
          width: 168px;
          bottom: -85px;
        }
      }
    
      
    }
  }

  .impact-head.-testimonialv1 {
    margin-bottom: 30px;
    p {
      margin-top: 44px;
      padding: 0 36px;

    }
  }
  .impact-head.-testimonialv2 {
    p {
      padding: 0 36px;
      text-align: left;
      margin-top: -30px;
      margin-bottom: 44px;
    }
   
  }

  .impact-head.-testimonialv3 {
    p {
      display: none;
    }
    h2 {
      margin-top: -8px;
    }
  }

  .with-slider.v1 {
    padding-top: 0;
  }
  .with-slider.v2 {
    .below-slides {
      margin-top: 120px;
      margin-bottom: 80px;
    }
  }
}

 
 