/*Global*/
$gotham-book-font: Gotham-Book;
$gotham-bold-font: gotham_bold;
$gotham-thin-font: gothamthin;
$gotham-md-font: gothammedium;
$gotham-book: Gotham Black;
$grey-color: #f0f0f0;
$body-color: #000;
$orange-color: #e1251b;
$primary-color: #004e7d;
$dark-brown: #7a120e;
$d-green: #007473;
$l-green: #97d6d7;
$skin: #e2c9af;
$green: #528b1a;
$timing-color: #da291c;
$c-green: #007672;
$donate-banner-bg: #173f35;
$d-green1: #84bd00;
$n-blue: #003b5c;
$d-blue2: #003a5d;
$d-blue3: #0077C8;
$card-clr1: #4a7729;
$card-clr2: #00b2a9;
$card-clr3: #943bba;
$card-clr4: #f2a900;
$card-clr5: #00837e;
$card-clr6: #e5417a;
$nab-tab-bg: #f0f0f0;

$cookie-button-color: $timing-color;

$mobile-navbar-bg: $orange-color;
$mobile-permotion-bar: $primary-color;

.clr-white {
  color: #fff;
}

.clr-nblue {
  color: $n-blue;
}

.clr-black {
  color: $body-color;
}

.clr-green {
  color: $green;
}

.clr-dgreen1 {
  color: $green;
}

.clr-dgreen2 {
  color: $donate-banner-bg;
}

.timing-clr {
  color: $timing-color;
}

.bg-grey {
  background: $grey-color;
}

.primary-bg {
  background: $primary-color;
}

.bg-dgreen {
  background: $d-green;
}

.bg-lgreen {
  background: $l-green;
}

.bg-cgreen {
  background: $c-green !important;
}

.green {
  color: $c-green;
}

.primary-bg1 {
  background: $orange-color;
}

.bg-skin {
  background: $skin;
}

.bg-dblue2 {
  background: $d-blue2 !important;
}

.banner-solid-bg {
  background: $donate-banner-bg;
}

.text-grey {
  color: #656565;
}

.bg-dgreen1 {
  background: $d-green1;
}

.card-bg1 {
  background: $card-clr1;
}

.card-bg2 {
  background: $card-clr2;
}

.card-bg3 {
  background: $card-clr3;
}

.card-bg4 {
  background: $card-clr4;
}

.card-bg5 {
  background: $card-clr5;
}

.card-bg6 {
  background: $card-clr6;
}
