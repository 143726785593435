@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	border-radius: $radius;
}

@mixin box-shadow($top, $left, $size, $blur, $color, $inset: false) {
	@if $inset {
		-webkit-box-shadow: inset $top $left $size $blur $color;
		-moz-box-shadow: inset $top $left $size $blur $color;
		box-shadow: inset $top $left $size $blur $color;
	} @else {
		-webkit-box-shadow: $top $left $size $blur $color;
		-moz-box-shadow: $top $left $size $blur $color;
		box-shadow: $top $left $size $blur $color;
	}
}

@mixin smooth($property, $duration, $ease) {
	-webkit-transition: $property $duration $ease;
	-moz-transition: $property $duration $ease;
	-o-transition: $property $duration $ease;
	transition: $property $duration $ease;
}


.title-20 {
    font-size: 20px;
	font-weight: 700;
}

p {
    font-size: 16px;
}


@media (min-width: 1250px) {
.container {
    max-width: 1215px;
}
}




.page-wrapper {
    min-height: 100vh;
}


.langauage-block {
	font-size:12px;
	select {
		border: none;
		background: transparent;
		text-transform: uppercase;
		font-size: 12px;
		width: 40px;
		font-weight: 700;
	}
	
	.active {
		color:#E1251B;
	}
	
	.globe-icon {
    max-width: 13px;
    top: 4px;
    position: relative;
    float: left;
    margin-right: 6px;
	}
}


.search-box {
	position: relative;	
	//width: 200px;
	::-webkit-input-placeholder { /* Edge */
		color: #000;  
		opacity: 1;
	}

	:-ms-input-placeholder { /* Internet Explorer 10-11 */
		color: #000;  
		opacity: 1;
	}

	::placeholder {		 
		color: #000;  
		opacity: 1;
	}  

	input:focus::-webkit-input-placeholder { /* Edge */
		color: #b7b5b5;
		text-transform: none;
	}

	input:focus:-ms-input-placeholder { /* Internet Explorer 10-11 */
		color: #b7b5b5;
		text-transform: none;
	}

	input:focus::placeholder {
		color: #b7b5b5;
		text-transform: none;
	}  
  input {
    height: 48px;
    background: #f0f0f0;
    border: none;
    border-radius: 50px;
    padding: 0 24px 0 48px;
    text-align: left;
    width: 100%;
    text-transform: uppercase;
	font-weight: 700;
	transition: 0.3s all;
	font-size: 13px;
	width:128px;
	transition: 0.3s all;
	&:focus {
		outline:none;
		 width: 200px;
		transition: 0.3s all;
		text-align:left;
		padding: 0 24px 0 24px;

	}
	&:hover {
		background:#cccccc;
	}
	
	&:focus+ .fa-search + span.arrow-right {opacity:1;}
	&:focus+ .fa-search {display:none;}
	&:focus{display:block;}
	 
	
  }
 

  .fa-search {
	position: absolute;
	left: 24px;
    top: 15px;
    color: #E1251B;
  }  
  
  span.arrow-right {
    position: absolute;
    right: 16px;
    top: 13px;
    background: #E1251B;
    color: #fff;
    border-radius: 50%;
    font-size: 14px;
    width: 22px;
    height: 22px;
    text-align: center;
		opacity: 0;
		i {
			position: relative;
			top: -1px;
		}
  }
}


.breadcrumbs-row {
  ul {
    padding: 0;
	padding-top:30px;
	li {
		list-style:none;
		text-transform: uppercase;
		font-size: 16px;
		display: inline-block;
		margin: 0 15px;
		font-weight: 700;
		position: relative;
		color:#656565;
		a {
			color:#E1251B;
		}
		&:first-child {
			margin-left:0;
		}
		&:after {
			content:">";
			position: absolute;
			right: -21px;
		}
		&:last-child {
			&::after {
				opacity:0;
			}
		}
	}  
  }	
}

.block-element {
  display: block;
}  

.pad-lr-24 {
	@media (min-width:1200px) {
		padding-left:24px;
		padding-right:24px;
	}
}

.mgn-rev-lr-24 {
   @media (min-width:1200px) {
		margin-left:-24px;
		margin-right:-24px;
	}
}

hr.hr-style {
    border-top: 2px solid #f0f0f0;
} 

.small-container {
  @media (min-width:768px) {
    max-width: 660px;
    margin: 0 auto;
 }
 @media (max-width:767px) {
	padding-left:20px !important;
	padding-right:20px !important;
 } 
}  

.ptb-80 {
	 @media (min-width:768px) {
		padding:80px 0px;
	 }
	 
	 @media (max-width:767px) {
		padding: 40px 0px;
	}
}  

.pt-80 {
	padding-top: 80px;
	@media (max-width:767px) {
		padding-top: 40px;
	}
}
.ptb-50 {
	 @media (min-width:768px) {
		padding:50px 0px;
	 }
	 
	 @media (max-width:767px) {
		padding:50px 0px;
	}
}

.ptb-20 {
	padding:20px 0px;
}

.pad-lr-48 {
  @media (min-width:992px) {
    padding: 0 48px;
  }	
}


.mobile-hide {
	@media (max-width:767px) {
		display:none !important;	
	}
}

.desktop-hide {
	@media (min-width:768px) {
		display:none !important;
	}
}


.pad-left-75 {
    padding-left: 75px;
}

.top-layer-none {
	&::before {
		opacity:0;
		height:0;
	}
}

.font-weight-mormal {
	font-weight:400;
}

.desktop-fixed-height {
	@media (min-width:1200px) {
		height:325px;
	}
}



.store-inner .pad-lr-22 {
	@media (min-width:1200px) {
		padding-left:22px;
		padding-right:22px;
	}
}

 .mgn-rev-lr-22 {
	@media (min-width:1200px) {
		margin-left:-22px;
		margin-right:-22px;
	}
}